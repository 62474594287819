import React from 'react'
import UserLayout from './UserLayout';
import { Link, useLocation } from 'react-router-dom';
import { userDetails } from '../../utils';
// import { hasPermission } from '../../utils';

const DocumentsLayout = ({children, sectionTitle}) => {
    const location = useLocation();
    const currentRoute = location.pathname;

    // const requiredPermissions = ['*', 'documents.*']
    return (
        <UserLayout pageTitle="Documents Management">

            <div className='w-full flex items-start gap-x-8'>
                {<div className='min-w-[200px] rounded'>
                    {(userDetails().accountPermissions.includes('*') || userDetails().accountPermissions.includes('documents.create') || userDetails().accountPermissions.includes('documents.*')) && <Link to={`/user/documents`}>
                        <button className={`w-full flex items-center gap-x-3 px-2 py-1 mb-1`}>
                            <p className={`text-sm ${(currentRoute === '/user/documents') && 'font-bold text-verovian-purple'}`}>Document Templates</p>
                        </button>
                    </Link>}
                    <Link to={`/user/documents/assigned`}>
                        <button className='w-full flex items-center gap-x-3 px-2 py-1 mb-1'>
                            <p className={`text-sm ${currentRoute.includes('/user/documents/assigned') && 'font-bold text-verovian-purple'}`}>Assigned Documents</p>
                        </button>
                    </Link>
                    {/* {hasPermission(requiredPermissions) && <Link to={`/user/reviews-management/review-forms`}>
                        <button className='w-full flex items-center gap-x-3 px-2 py-1 mb-1'>
                            <p className={`text-sm ${currentRoute.includes('/reviews-management/review-forms') && 'font-bold text-verovian-purple'}`}>Review Forms</p>
                        </button>
                    </Link>}
                    {hasPermission(requiredPermissions) && <Link to={`/user/reviews-management/review-questions`}>
                        <button className='w-full flex items-center gap-x-3 px-2 py-1 mb-1'>
                            <p className={`text-sm ${currentRoute.includes('/reviews-management/review-questions') && 'font-bold text-verovian-purple'}`}>Review questions</p>
                        </button>
                    </Link>} */}
                </div>}

                 <div className={`w-full max-w-[100%] px-12`}>
                    <h3 className='uppercase font-light text-[15px] tracking-[0.2em]'>{sectionTitle}</h3>
                    <main>{children}</main>
                </div>
            </div>
        </UserLayout>
    )
}

export default DocumentsLayout
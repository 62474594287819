import React, { useEffect, useState } from 'react'
import TextField from '../../elements/form/TextField'
import TextareaField from '../../elements/form/TextareaField'
// import AutocompleteSelect from '../../elements/form/AutocompleteSelect'
import FormButton from '../../elements/form/FormButton'
import SelectField from '../../elements/form/SelectField'
import { useDispatch, useSelector } from 'react-redux'
import { ERROR } from '../../../store/types'
import { fetchEmployees } from '../../../store/actions/employeeActions'
import { createTask } from '../../../store/actions/tasksActions'
import AutocompleteSelect from '../../elements/form/AutocompleteSelect'

const NewTask = () => {
    const dispatch = useDispatch()
    const trainingsSelector = useSelector(state => state.trainings)
    const reviewsSelector = useSelector(state => state.reviews)
    const tasksSelector = useSelector(state => state.tasks)
    useEffect(() => {
        dispatch(fetchEmployees())
        return () => {
            
        };
    }, [dispatch]);

    const [taskPayload, setTaskPayload] = useState({
        taskItem:{
            itemType: '',
            item: ''
        }
    });
    const [validationErrors, setValidationErrors] = useState({});

    const validateForm = () => {
        let errors = {}
        if(!taskPayload.title || taskPayload.title === ''){
            errors.title = true
        }
        if(!taskPayload.description || taskPayload.description === ''){
            errors.description = true
        }
        setValidationErrors(errors)
        return errors
    }

    const pushTask = () => {
        if (Object.values(validateForm()).includes(true)) {
            dispatch({
                type: ERROR,
                error: {response: {data: {
                    message: 'Please check the highlighted fields'
                }}}
            });
            return
        }

        const payload = taskPayload
        dispatch(createTask(payload))
    }
    return (
        <div className='w-full p-[20px'>
            <div className='my-1 w-full'>
                <TextField
                    inputLabel="Title" 
                    fieldId="task" 
                    inputType="text" 
                    preloadValue={''}
                    hasError={validationErrors && validationErrors.title} 
                    returnFieldValue={(value)=>{setTaskPayload({...taskPayload, ...{title: value}})}}
                />
            </div>

            <div className='my-1 w-full'>
                <TextareaField
                    inputLabel="Description" 
                    fieldId="task-description" 
                    inputType="text" 
                    height={`100px`}
                    preloadValue={''}
                    hasError={validationErrors && validationErrors.description} 
                    returnFieldValue={(value)=>{setTaskPayload({...taskPayload, ...{description: value}})}}
                />
            </div>
            
            <div className='mt-3 mb-1'>
                <label className={`cursor-text bg-transparent text-sm z-10 font-[550] transition duration-200  
                text-gray-800 block`}>
                    Task Item
                </label>
                <label className={`cursor-text bg-transparent text-xs z-10 transition duration-200  
                text-gray-800 block`}>
                    Choose what item this task will be linked to. Completing this item resolves the task for the employee as done.
                </label>
                <div className='my-1 w-full'>
                    <SelectField
                        inputPlaceholder={`Click to select an item type`}
                        selectOptions={[
                            {label: 'Training', value: 'training'},
                            {label: 'Review', value: 'review'},
                        ]}
                        inputLabel="Select item type"
                        titleField="label"
                        displayImage={false}
                        imageField=""
                        preSelected={taskPayload.taskItem.itemType}
                        fieldId="task-item-type"
                        hasError={false}
                        // return id of accounts of the selected option
                        returnFieldValue={(value) => {setTaskPayload({
                            ...taskPayload, 
                            ...{taskItem: {
                                ...taskPayload.taskItem, 
                                ...{itemType: value.value}
                            }}
                        })
                        }}
                    />
                </div>
            </div>

            {(!taskPayload?.taskItem?.itemType || taskPayload?.taskItem?.itemType === '') && <div className='mt-[60px]' />}

            {taskPayload?.taskItem?.itemType === 'training' && <div className='w-full py-1'>
                {trainingsSelector.trainings?.trainings?.length > 0 ?   
                    <AutocompleteSelect
                        selectOptions={trainingsSelector.trainings.trainings}
                        inputLabel="Select training"
                        titleField="title"
                        displayImage={false}
                        imageField=""
                        preSelected=''
                        fieldId="task-training"
                        hasError={false}
                        // return id of accounts of the selected option
                        returnFieldValue={(value) => {setTaskPayload({
                            ...taskPayload, 
                            ...{taskItem: {
                                ...taskPayload.taskItem, 
                                ...{item: value._id}
                            }}
                        })}}
                    />
                    :
                    <label className='block p-[8px] rounded text-red-600 border border-red-600 bg-red-50 text-xs my-[15px]'>No trainings created, create some first by navigating to "Trainings" on the sidebar</label> 
                }
            </div>}

            {taskPayload?.taskItem?.itemType === 'review' && <div className='w-full py-1'>
                {reviewsSelector?.reviewForms?.forms?.length > 0 ? 
                    <AutocompleteSelect
                        selectOptions={reviewsSelector?.reviewForms?.forms}
                        inputLabel="Select review form"
                        titleField="name"
                        displayImage={false}
                        imageField=""
                        preSelected=''
                        fieldId="task-review"
                        hasError={false}
                        // return id of accounts of the selected option
                        returnFieldValue={(value) => {setTaskPayload({
                            ...taskPayload, 
                            ...{taskItem: {
                                ...taskPayload.taskItem, 
                                ...{item: value._id}
                            }}
                        })}}
                    />
                    : 
                    <label className='block p-[8px] rounded text-red-600 border border-red-600 bg-red-50 text-xs my-[15px]'>No review forms created, create some first by navigating to "Reviews &gt; Review Forms" on the sidebar</label> 
                }
            </div>}

            <div className='mt-[60px]'>
                <FormButton buttonLabel="Create task" buttonAction={()=>{pushTask()}} processing={tasksSelector.creatingTask} />
            </div>
        </div>
    )
}

export default NewTask
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import ArrowIcon from '../../../elements/icons/ArrowIcon'
import TextIcon from '../../../elements/icons/TextIcon'
import VideoIcon from '../../../elements/icons/VideoIcon'
import AudioIcon from '../../../elements/icons/AudioIcon'
import PhotoIcon from '../../../elements/icons/PhotoIcon'
import ModalLayout from '../../../layout/ModalLayout'
import DownloadIcon from '../../../elements/icons/DownloadIcon'
import AudioPlayer from '../../../elements/AudoPlayer'
import VideoPlayer from '../../../elements/VideoPlayer'
import { Switch } from '@headlessui/react'
import { useDispatch } from 'react-redux'
import { updateEmployeeTraining } from '../../../../store/actions/trainingsActions'

const EmployeeTrainingCard = ({assignment}) => {
  // const trainingEstimatedTime = assignment.training ? 
  //   assignment.training.modules.reduce((accumulator, current) => accumulator+current.module.estimatedTime, 0) : 
  //   assignment.trainingMaterial.estimatedTime
  const dispatch = useDispatch()
  const progressPercentage = () => {
    let percentage = 0
    if(assignment.training){
      percentage = (assignment.completedModules.length/assignment.training.modules.length) * 100
    }
    if(assignment.trainingMaterial && assignment.completed){
      percentage = 100
    }
    return percentage.toString() + '%'
  }

  const description = () => {
    let desc = ''
    if(assignment.training){
      desc = `${assignment.training.description.substr(0, 200)}${assignment.training.description.length > 200 && '...'}` 
    }
    if(assignment.trainingMaterial){
      desc = `${assignment.trainingMaterial.description.substr(0, 200)}${assignment.trainingMaterial.description.length > 200 && '...'}` 
    }
    return desc
  }

  const [materialOpen, setMaterialOpen] = useState(false);
  const [material, setMaterial] = useState(null);
  const openMaterial = () => {
    setMaterial(assignment.trainingMaterial)
    setTimeout(() => {
      setMaterialOpen(true)
    }, 200);
  }

  const setAsComplete = () => {
    if(assignment.completed) {
      return
    }
    dispatch(updateEmployeeTraining(assignment._id, {completed: true}))
  }

  return (
    <>
      <div className={`cursor-pointer w-full`}>
          <div className='rounded relative flex items-center justify-center bg-gray-100 w-full h-[250px]' 
            style={{
                backgroundImage: assignment?.training?.coverImage && `url(${assignment.training.coverImage})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat'
            }} 
          >
            {assignment?.trainingMaterial?.content?.contentType === 'VIDEO' && <VideoIcon className={`w-5 h-5 text-gray-500 mt-[5px]`} />}
            {assignment?.trainingMaterial?.content?.contentType === 'AUDIO' && <AudioIcon className={`w-5 h-5 text-gray-500 mt-[5px]`} />}
            {assignment?.trainingMaterial?.content?.contentType === 'INFOGRAPHIC' && <PhotoIcon className={`w-5 h-5 text-gray-500 mt-[5px]`} />}
            {(assignment?.trainingMaterial?.content?.contentType === 'TEXT' || assignment?.trainingMaterial?.content?.contentType === 'DOCUMENT')  && <TextIcon className={`w-5 h-5 text-gray-500 mt-[5px]`} />}

            {assignment.onboardingPackage && 
              <span className='absolute right-[10px] top-[10px] text-xs px-[8px] py-[5px] rounded bg-gray-800 bg-opacity-50 text-gray-100'>Onboarding Training</span>
            }
          </div>
          <div className='w-full p-[20px] relative'>
              <p className='text-sm text-gray-700 font-medium'>{assignment.training?.title ||assignment.trainingMaterial?.title }</p>
              <div className='my-1'>
                  <p className='text-xs'>{description()}</p>
              </div>
              <p className='text-xs text-gray-500 mt-[10px]'>{assignment.training && `${assignment.training.modules.length} modules` }
              {assignment.trainingMaterial && <span className='text-xs text-gray-500'> {assignment.trainingMaterial?.estimatedTime} minutes</span>}
              </p>
              
              <div className='w-full p-[3px] rounded bg-verovian-light-purple bg-opacity-80 mt-[20px]'>
                <div className='h-[10px] rounded bg-verovian-purple' style={{width: progressPercentage()}} />
              </div>

              {assignment.training && <p className='text-xs mt-[10px]'>{assignment.completedModules.length} modules complete out of {assignment.training.modules.length} </p>}

              {assignment.trainingMaterial && 
                <div className='w-full my-4 flex gap-x-4 items-start justify-between'>
                  <div  className='w-full'>
                      <p className="text-sm text-gray-600">
                          Complete training
                      </p>
                      <p className='text-xs text-gray-400'>Flip this switch to mark this training assignment as completed</p>
                  </div>
                  <div className='w-[50px] flex flex-row-reverse pt-[10px]'>
                      <Switch
                          checked={assignment.completed}
                          onChange={()=>{setAsComplete()}}
                          className={`${
                              assignment.completed ? 'bg-verovian-purple' : 'bg-gray-200'
                          } relative inline-flex items-center h-5 rounded-full w-10`}
                          >
                          {/* <span className="sr-only">Display stock levels</span> */}
                          <span
                              className={`transform transition ease-in-out duration-200 ${
                              assignment.completed ? 'translate-x-6' : 'translate-x-1'
                              } inline-block w-3 h-3 transform bg-white rounded-full`}
                          />
                      </Switch>
                  </div>
              </div>
              }
              
              <div className='pt-[10px] mt-[20px] border-t'>
                {assignment.training && <Link to={`/user/trainings/training/${assignment.training._id}`}  className='text-sm text-gray-700 font-[500] flex items-center gap-x-2 hover:text-verovian-purple duration-200 transition mt-3'>Open training <ArrowIcon className={`w-4 h-4 -rotate-90`}/></Link>}
                {assignment.trainingMaterial && <button onClick={()=>{openMaterial()}} className='text-sm text-gray-700 font-[500] flex items-center gap-x-2 hover:text-verovian-purple duration-200 transition mt-3'>Open training <ArrowIcon className={`w-4 h-4 -rotate-90`}/></button>}
              </div>
          </div>
      </div>

      {material !== null && <ModalLayout
        isOpen={materialOpen} 
        closeModal={()=>{setMaterialOpen(false)}} 
        actionFunction={()=>{}} 
        actionFunctionLabel={``} 
        dialogTitle={material.title}
        dialogIntro={`By ${material.instructor.name}`}
        maxWidthClass='max-w-7xl'
        >
          {/* <NewInstructor /> */}
          {material.content.contentType === 'VIDEO' && <VideoPlayer videoSrc={material.content.content} /> }
          {material.content.contentType === 'AUDIO' && <AudioPlayer audioSrc={material.content.content} /> }
          {material.content.contentType === 'TEXT' && <div className='p-[40px]' dangerouslySetInnerHTML={{__html: material.content.content}} /> }
          {material?.content?.contentType === 'INFOGRAPHIC' && <img src={material?.content?.content} alt='' className='w-full' /> }
          
          {material?.content?.contentType === 'DOCUMENT' && 
            <>
                <p className='text-sm text-gray-400 mt-5 mb-3'>This lecture is a downloadable content. Please use the button below to download it.</p>
                <a href={material?.content?.content} target="_blank" className='mt-[20px] rounded p-2 border-[1.5px] flex w-max items-center justify-center gap-x-[5px] border-verovian-purple text-verovian-purple hover:border-gray-400 hover:text-gray-400 transition duration-200 font-[550] text-xs' rel="noreferrer"><DownloadIcon className={`w-5 h-5`} />Download Training Material</a>
            </>
          }
            
        </ModalLayout>}
    </>
  )
}

export default EmployeeTrainingCard
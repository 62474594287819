import React, { useEffect, useState } from 'react'
import UserIcon from '../../elements/icons/UserIcon'
import { authHeader, transactionTimeStamp, userDetails } from '../../../utils'
import ArrowIcon from '../../elements/icons/ArrowIcon'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { ERROR } from '../../../store/types'
import Preloader from '../../elements/Preloader'
import CircularGauge from '../../elements/CircularGauge'

const EmployeePreviewCard = ({employee}) => {
  const dispatch = useDispatch()
  const [onboardingProgress, setOnboardingProgress] = useState(0);
  const [loadingProgress, setLoadingProgress] = useState(true);
  useEffect(() => {
    const getOnboardingPackage = async () => {
      try {
          const headers = authHeader()
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/onboarding-packages/employee/${employee._id}/${employee.onboardingPackage}`, { headers })
          setOnboardingProgress(response.data.data.completion)
          setLoadingProgress(false)


      } catch (error) {
          dispatch({
              type: ERROR,
              error: error
          })
          setLoadingProgress(false) 
      }
    }

    if(employee.onboardingPackage){
      getOnboardingPackage()
    } else {
      setLoadingProgress(false)
    }
    return () => {
      
    };
  }, [dispatch, employee._id, employee.onboardingPackage]);
  return (
    <div className='w-full py-[20px] rounded-[8px] bg-white'>
      <div className='relative px-[20px] w-full pb-[10px] border-b'>
        <div className='flex items-center gap-x-3'>
          <div className='w-[50px]'>
            <div className='w-[50px] h-[50px] rounded-full border-gray-400 bg-gray-100 flex items-center justify-center' style={{
              backgroundImage: `url(${employee.avatar})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat'
            }}>
              {(!employee.avatar || employee.avatar === '') && <UserIcon className={`w-5 h-5 rounded-sm text-gray-400 `} />}
            </div>
          </div>
          
          <div className='w-full'>
              <p className='font-medium text-gray-600 text-sm mb-[5px]'>{employee.fullName} 
              {employee.userProfile && employee.userProfile._id === userDetails()._id && <span className='px-[7px] py-[2px] ml-[5px] rounded bg-gray-200 text-xs'>You</span>}
              </p>
              <p className='text-gray-6400 text-xs'>{employee.designation && employee.designation !== '' && <span className='text-gray-400 text-xs'>{employee.designation.name}</span>}, {employee?.department.name}</p>
          </div>
        </div>

        <span className={`absolute -top-[10px] right-[10px] bg-opacity-10 text-xs font-[500] px-[10px] py-[5px] rounded ${employee.active ? 'bg-green-500 text-green-600' : 'text-gray-600 bg-gray-500'}`}>
          {employee.active ? 'Active' : 'Inactive'}
        </span>
      </div>
      <div className='w-full p-[20px]'>
          <div className='w-full mb-[15px] flex items-center justify-between gap-x-[5px]'>
            <div className='w-[30%]'>
              <p className='text-xs text-gray-400'>Start date</p>
            </div>
            <div className='w-[70%]'>
              <p className='text-gray-600 text-sm'>{transactionTimeStamp(employee?.startDate).date}</p>
            </div>
          </div>
          <div className='w-full my-[15px] flex items-center justify-between gap-x-[5px]'>
            <div className='w-[30%]'>
              <p className='text-xs text-gray-400'>Email</p>
            </div>
            <div className='w-[70%]'>
              <p className='text-gray-600 text-sm'>{employee.email}</p>
            </div>
          </div>
          <div className='w-full my-[15px] flex items-center justify-between gap-x-[5px]'>
            <div className='w-[30%]'>
              <p className='text-xs text-gray-400'>Phone</p>
            </div>
            <div className='w-[70%]'>
              <p className='text-gray-600 text-sm'>{employee.phone}</p>
            </div>
          </div>
          <div className='w-full my-[15px] flex items-center justify-between gap-x-[5px]'>
            <div className='w-[30%]'>
              <p className='text-xs text-gray-400'>Leave Allowance</p>
            </div>
            <div className='w-[70%]'>
              <p className='text-gray-600 text-sm'>{employee.leaves.totalDaysAllowed} days</p>
            </div>
          </div>
          <div className='w-full my-[15px] flex items-center justify-between gap-x-[5px]'>
            <div className='w-[30%]'>
              <p className='text-xs text-gray-400'>Days Taken</p>
            </div>
            <div className='w-[70%]'>
              <p className='text-gray-600 text-sm'>{employee.leaves.daysUsed?.total || 0} days</p>
            </div>
          </div>
          {employee.onboardingPackage && loadingProgress && <div className='w-full my-[15px] flex items-center justify-between gap-x-[5px]'>
            <Preloader />
          </div>}
          {employee.onboardingPackage && !loadingProgress && <div className='w-full my-[15px] flex items-center justify-between gap-x-[5px]'>
            <div className='w-[30%]'>
              <p className='text-xs text-gray-400'>Onboarding</p>
            </div>
            <div className='w-[70%]'>
              <CircularGauge
                percentage={onboardingProgress}
                completeColor="#4caf50"
                incompleteColor="#e0e0e0"
                size={30}
                strokeWidth={2}
              />
            </div>
          </div>}
      </div>

      <div className='w-full px-[20px] border-t'>
        <Link className='text-xs font-[500] text-gray-600 flex items-center gap-x-2 hover:text-verovian-purple duration-200 transition mt-3' to={`/user/employees/profile/${employee._id}`}>See Employee Profile <ArrowIcon className={`w-4 h-4 -rotate-90`}/></Link>

      </div>
    </div>
  )
}

export default EmployeePreviewCard
import React, { useState } from 'react'
import VideoIcon from '../../../elements/icons/VideoIcon'
import AudioIcon from '../../../elements/icons/AudioIcon'
import TextIcon from '../../../elements/icons/TextIcon'
import ArrowIcon from '../../../elements/icons/ArrowIcon'
import ClockIcon from '../../../elements/icons/ClockIcon'
import PhotoIcon from '../../../elements/icons/PhotoIcon'
import ModalLayout from '../../../layout/ModalLayout'
import VideoPlayer from '../../../elements/VideoPlayer'
import AudioPlayer from '../../../elements/AudoPlayer'
import DownloadIcon from '../../../elements/icons/DownloadIcon'
import UserIcon from '../../../elements/icons/UserIcon'
import PencilSquareIcon from '../../../elements/icons/PencilSquareIcon'
import { Link } from 'react-router-dom'

const MaterialCardVertical = ({material}) => {
    const [materialOpen, setMaterialOpen] = useState(false);
    return (
        <>
            <div className='w-full relative'>
                <div className='w-full h-[150px] bg-gray-400 bg-opacity-20 flex items-center justify-center relative'>
                    {material.content.contentType === 'VIDEO' && <VideoIcon className={`w-8 h-8 text-gray-300`} />}
                    {material.content.contentType === 'AUDIO' && <AudioIcon className={`w-8 h-8 text-gray-300`} />}
                    {material.content.contentType === 'INFOGRAPHIC' && <PhotoIcon className={`w-8 h-8 text-gray-300`} />}
                    {(material.content.contentType === 'TEXT' ||material.content.contentType === 'DOCUMENT')  && <TextIcon className={`w-8 h-8 text-gray-300`} />}

                    <div className='absolute bottom-[10px] right-[10px] flex items-center gap-x-[5px] flex-row-reverse'>
                        {material.forOnboardingPackage && <span className=' px-[5px] py-[2px] rounded -mt-[5px] border- border-yellow-800 bg-yellow-100 text-yellow-950 bg-opacity-70 font-[500] text-[12px]'>Onboarding</span>}
                        {material.forTraining && <span className='px-[5px] py-[2px] rounded -mt-[5px] border- border-purple-800 bg-purple-100 text-purple-950 bg-opacity-90 font-[500] text-[12px]'>Training</span>}
                    </div>
                </div>
                <div className='w-full px-[20px] pb-[20px] pt-[5px] relative'>

                    <div className='flex items-center gap-x-[10px] my-[10px]'>
                        <div className='w-[45px]'>
                            <div className='w-[40px] h-[40px] rounded-full bg-gray-100 flex items-center justify-center' 
                                style={{
                                    backgroundImage: `url(${material.instructor.avatar})`,
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat'
                                }} 
                            >
                                {(!material.instructor.avatar || material.instructor.avatar === '' )&& <UserIcon className={`w-6 h-6 text-gray-600`} />}
                            </div>
                        </div>
                        <div className='w-full'>
                            <h3 className='text-black text-[14px]'>{material.instructor.name}</h3>
                            <p className='text-[12px] my-[2px]'>{material.instructor.email}</p>
                        </div>
                    </div>
                    <h3 className='text-[15px] mt-[15px] mb-[10px]'>{material.title}</h3>
                    <div className='flex items-start justify-between my-[10px]'>
                        <div className='flex items-center gap-x-[5px]'>
                            <ClockIcon classes={`w-5 h-5 text-gray-500`} />
                            <p className='text-xs text-gray-500'>{material.estimatedTime} minutes</p>
                        </div>
                        
                    </div>
                    <p className='text-[13px]'>{material.description}</p>

                    <div className='flex items-center justify-between'>
                        <button onClick={()=>{setMaterialOpen(true)}} className='text-sm text-gray-700 font-[500] flex items-center gap-x-2 hover:text-verovian-purple duration-200 transition mt-3'>See material <ArrowIcon className={`w-4 h-4 -rotate-90`}/></button>

                        <Link to={`/user/trainings/training-materials/edit/${material._id}`} className='text-sm text-gray-700 font-[500] flex items-center gap-x-2 hover:text-verovian-purple duration-200 transition mt-3'>Edit material <PencilSquareIcon className={`w-4 h-4`}/></Link>
                    </div>
                </div>
            </div>

            <ModalLayout
                isOpen={materialOpen} 
                closeModal={()=>{setMaterialOpen(false)}} 
                actionFunction={()=>{}} 
                actionFunctionLabel={``} 
                dialogTitle={material.title}
                dialogIntro={`By ${material.instructor.name}`}
                maxWidthClass='max-w-7xl'
            >
                {/* <NewInstructor /> */}
                {material.content.contentType === 'VIDEO' && <VideoPlayer videoSrc={material.content.content} /> }
                {material.content.contentType === 'AUDIO' && <AudioPlayer audioSrc={material.content.content} /> }
                {material.content.contentType === 'TEXT' && <div className='p-[40px]' dangerouslySetInnerHTML={{__html: material.content.content}} /> }
                {material?.content?.contentType === 'INFOGRAPHIC' && <img src={material?.content?.content} alt='' className='w-full' /> }
                {material?.content?.contentType === 'DOCUMENT' && 
                    <>
                        <p className='text-sm text-gray-400 mt-5 mb-3'>This lecture is a downloadable content. Please use the button below to download it.</p>
                        <a href={material?.content?.content} target="_blank" className='mt-[20px] rounded p-2 border-[1.5px] flex w-max items-center justify-center gap-x-[5px] border-verovian-purple text-verovian-purple hover:border-gray-400 hover:text-gray-400 transition duration-200 font-[550] text-xs' rel="noreferrer"><DownloadIcon className={`w-5 h-5`} />Download Training Material</a>
                    </>
                }
                
            </ModalLayout>
        </>
    )
}

export default MaterialCardVertical
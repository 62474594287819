import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchEmployees } from '../../../store/actions/employeeActions';
import Preloader from '../../elements/Preloader';
import EmployeeSnippet from '../employees/EmployeeSnippet';
import FormButton from '../../elements/form/FormButton';
import { ERROR } from '../../../store/types';
import { updateDepartment } from '../../../store/actions/departmentActions';
import CheckIconCircled from '../../elements/icons/CheckIconCircled';

const DepartmentHodSelector = ({department}) => {
    const dispatch = useDispatch()
    const departmentsSelector = useSelector(state => state.departments)
    const employeesSelector = useSelector(state => state.employees)
    const [hod, setHod] = useState('');
    const [validationErrors, setValidationErrors] = useState({});
    useEffect(() => {
        dispatch(fetchEmployees(0, 0, `department=${department._id}`))
        return () => {
            
        };
    }, [department._id, dispatch]);

    const validateForm = () => {
        let errors = {}
        if (!hod || hod === '') {
            errors.hod = true
        }

        setValidationErrors(errors)
        return errors
    }
    
    const triggerUpdateDepartment = () => {
        if (Object.values(validateForm()).includes(true)) {
            dispatch({
                type: ERROR,
                error: {response: {data: {
                    message: 'Please check the highlighted fields'
                }}}
            });
            return
        }
        const payload = {hod}
        dispatch(updateDepartment(department._id, payload))
    }
    return (
        <div className='w-full'>
            {employeesSelector.loadingEmployees ?
                <Preloader preloadingText={`Fetching department employees...`} />

                :

                <div className='w-full'>
                    <p className='text-sm text-gray-500 mb-[15px]'>
                        Select one of the employees of this department below to set them as the Department Head or Supervisor
                    </p>
                    {validationErrors.hod && <p className='my-[10px] text-xs text-red-500'>Please select a HOD for this department</p>}
                    {employeesSelector?.employees?.employees?.map((employee, employeeIndex)=>(<div key={employeeIndex} onClick={()=>{setHod(employee._id)}} className={`p-[10px] rounded-[8px] flex items-center cursor-pointer border gap-x-[10px] justify-between ${hod === employee._id ? 'border-verovian-purple bg-verovian-light-purple bg-opacity-50' : 'border-transparent bg-white'}`}>
                        <div className='w-full'>
                            <EmployeeSnippet showIcon={true} name={`${employee.firstName} ${employee.lastName}`} phone={employee.phone} email={employee.email} designation={employee.designation.name} avatar={employee.avatar} />
                        </div>
                        <div className='w-[50px]'>
                            {hod === employee._id && <CheckIconCircled className={`w-5 h-5 text-verovian-purple`} />} 
                        </div>
                    </div>))}
                </div>
            }

            <div className='my-8 flex flex-row-reverse items-center justify-between'>
                <div className='w-full'>
                    <FormButton 
                    buttonLabel={`Save HOD/Supervisor`} 
                    buttonAction={()=>{triggerUpdateDepartment()}} 
                    processing={departmentsSelector.updatingDepartment}
                    />
                </div>
            </div>
        </div>
    )
}

export default DepartmentHodSelector
import React, { useState } from 'react'
import Status from '../../elements/Status'
import { Link } from 'react-router-dom'
import VideoIcon from '../../elements/icons/VideoIcon'
import AudioIcon from '../../elements/icons/AudioIcon'
import PhotoIcon from '../../elements/icons/PhotoIcon'
import TextIcon from '../../elements/icons/TextIcon'
import ArrowIcon from '../../elements/icons/ArrowIcon'
import DocumentSvgIcon from '../../../assets/img/icons/document-icon.svg'
import { userDetails } from '../../../utils'
import TaskAssignmentDetailsDialogContent from '../tasks/TaskAssignmentDetailsDialogContent'
import ModalLayout from '../../layout/ModalLayout'
import VideoPlayer from '../../elements/VideoPlayer'
import AudioPlayer from '../../elements/AudoPlayer'
import DownloadIcon from '../../elements/icons/DownloadIcon'
import { Switch } from '@headlessui/react'
import { useDispatch } from 'react-redux'
import { updateEmployeeTraining } from '../../../store/actions/trainingsActions'

const OnboardingDayItemCard = ({item, hideLink}) => {
    const documentCompleted = (document, documentAssignment) => {
        const completed = (document?.providedBy === 'employee' && documentAssignment?.documentUrl && documentAssignment.documentUrl !== '') || (document?.requiresSigning && documentAssignment?.documentSigning && documentAssignment.documentSigning !== '') || (!document?.requiresSigning)
        return completed
    }
    const dispatch = useDispatch()

    const [taskOpen, setTaskOpen] = useState(false);
    const [activeOpenTask, setActiveOpenTask] = useState(null);

    const openTask = (task) => {
        console.log('task opening: ', task)
        setActiveOpenTask(task)
        setTimeout(() => {
            setTaskOpen(true)
        }, 50);
    }

    const closeTask = () => {
        setTaskOpen(false)
        setTimeout(() => {
            setActiveOpenTask(null)
        }, 50);
    }

    const [activeMaterial, setActiveMaterial] = useState(null);
    const [materialOpen, setMaterialOpen] = useState(false);

    const openTrainingMaterial = (material) => {
        setActiveMaterial(material)
        setTimeout(() => {
            setMaterialOpen(true)
        }, 200);
    }

    const setAsComplete = (assignment) => {
        if(assignment.completed) {
          return
        }
        dispatch(updateEmployeeTraining(assignment._id, {completed: true}))
    }
        

    return (
        <>
            <div>
                <div className='w-[350px] rounded p-[10px] my-[20px] bg-white shadow-xl shadow-verovian-purple/5'>
                    {item.type === 'training' && <div>
                        <div className={`flex items-start gap-x-2 mb-4 cursor-pointer`}>
                            <div className='w-[75px] h-[60px] rounded-[8px] bg-gray-100' style={{
                                backgroundImage: `url(${item.item?.coverImage})`,
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat'
                            }}  />
                            <div className='w-full'>
                                <p className='text-sm text-gray-700 font-medium'>{item.item?.title}</p>
                                <div className='my-1'>
                                    <p className='text-xs'>{item?.item?.description?.length > 150 ? item?.item?.description?.slice(0, 150) + '...' : item.item?.description}</p>
                                </div>
                            </div>
                        </div>
                        <div className='pt-[5px] mt-[20px] border-t flex items-center justify-between'>
                            <div className='flex flex-row-reverse mt-[10px]'>
                                <Status status={item?.item?.assignment?.completed ? `complete` : `pending`} />
                            </div>
                            {!hideLink && <Link to={`/user/trainings/training/${item.item._id}`}  className='text-xs text-gray-700 font-[500] flex items-center gap-x-2 hover:text-verovian-purple duration-200 transition mt-3'>Go to training <ArrowIcon className={`w-4 h-4 -rotate-90`}/></Link>}
                        </div>
                    </div>}
                    {item.type === 'training-material' && <div>
                        <div className={`flex items-start gap-x-2 mb-4 cursor-pointer`}>
                            <div className='w-[75px] h-[60px] rounded-[8px] bg-gray-100'>
                                <div className='rounded flex items-center justify-center bg-gray-100 w-[70px] h-[70px]'>
                                    {item?.item?.content?.contentType === 'VIDEO' && <VideoIcon className={`w-5 h-5 text-gray-500 mt-[5px]`} />}
                                    {item?.item?.content?.contentType === 'AUDIO' && <AudioIcon className={`w-5 h-5 text-gray-500 mt-[5px]`} />}
                                    {item?.item?.content?.contentType === 'INFOGRAPHIC' && <PhotoIcon className={`w-5 h-5 text-gray-500 mt-[5px]`} />}
                                    {(item?.item?.content?.contentType === 'TEXT' || item?.content?.contentType === 'DOCUMENT')  && <TextIcon className={`w-5 h-5 text-gray-500 mt-[5px]`} />}
                                </div>
                            </div>
                            <div className='w-full'>
                                <p className='text-sm text-gray-700 font-medium'>{item.item?.title}</p>
                                <div className='my-1'>
                                    <p className='text-xs'>{item?.item?.description?.length > 150 ? item?.item?.description?.slice(0, 150) + '...' : item.item?.description}</p>
                                    {item?.item?.instructor?.name && <p className='text-xs text-gray-500 mt-[5px]'>By {item?.item?.instructor?.name}</p>}
                                </div>
                                
                            </div>
                        </div>

                        <div className='pt-[5px] mt-[20px] border-t flex items-center justify-between'>
                            <div className='flex flex-row-reverse mt-[10px]'>
                                <Status status={item.item?.assignment?.completed === true ? `complete` : `pending`} />
                            </div>
                            {!hideLink && <button onClick={()=>{openTrainingMaterial(item.item)}} className='text-xs text-gray-700 font-[500] flex items-center gap-x-2 hover:text-verovian-purple duration-200 transition mt-3'>Open training <ArrowIcon className={`w-4 h-4 -rotate-90`}/></button>}
                        </div>
                    </div>}
                    {item.type === 'document' && <div>
                        <div className={`flex items-start gap-x-2 mb-4 cursor-pointer`}>
                            <div className='w-[80px] rounded-[8px]'>
                                <img src={DocumentSvgIcon} alt="" className='w-full' />
                            </div>
                            <div className='w-full'>
                                <p className='text-sm text-gray-700 font-medium'>{item.item.name}</p>
                                <div className='my-1'>
                                    <p className='text-xs'>{item.item.description.length > 150 ? item.item.description.slice(0, 150) + '...' : item.item.description}</p>
                                </div>
                                
                            </div>
                        </div>
                        <div className='pt-[5px] mt-[20px] border-t  flex items-center justify-between'>
                            <div className='flex flex-row-reverse mt-[10px]'>
                                <Status status={documentCompleted(item.item, item.item.assignment) ? `complete` : 'pending'} />
                            </div>
                            {!hideLink && <Link to={`/user/documents/assigned/read/${item?.item?.assignment?._id}`}  className='text-xs text-gray-700 font-[500] flex items-center gap-x-2 hover:text-verovian-purple duration-200 transition mt-3'>Go to document <ArrowIcon className={`w-4 h-4 -rotate-90`}/></Link>}
                        </div>
                    </div>}
                    {item.type === 'task' && <div>
                        <div className={`flex items-start gap-x-2 mb-4 cursor-pointer`}>
                            <div className='w-full'>
                                <p className='text-sm text-gray-700 font-medium'>{item.item.title}</p>
                                <div className='my-1'>
                                    <p className='text-xs'>{item.item.description.length > 150 ? item.item.description.slice(0, 150) + '...' : item.item.description}</p>
                                </div>
                            </div>
                        </div>
                        <div className='pt-[5px] mt-[20px] border-t flex items-center justify-between'>
                            <div className='flex flex-row-reverse mt-[10px]'>
                                <Status status={item?.item?.assignment?.status === 'done' ? `complete` : `pending`} />
                            </div>
                            {!hideLink && <button onClick={()=>{openTask(item?.item?.assignment)}} className='text-xs text-gray-700 font-[500] flex items-center gap-x-2 hover:text-verovian-purple duration-200 transition mt-3'>Open task <ArrowIcon className={`w-4 h-4 -rotate-90`}/></button>}
                        </div>
                    </div>}
                    {item.type === 'review' && <div>
                        <div className={`flex items-start gap-x-2 mb-4 cursor-pointer`}>
                            <div className='w-full'>
                                <p className='text-sm text-gray-700 font-medium'>{item.item.name}</p>
                                <div className='my-1'>
                                    <p className='text-xs'>{item.item.description.length > 150 ? item.item.description.slice(0, 150) + '...' : item.item.description}</p>
                                </div>
                                
                            </div>
                        </div>
                        <div className='pt-[5px] mt-[20px] border-t flex items-center justify-between'>
                            <div className='flex flex-row-reverse mt-[10px]'>
                                <Status status={item?.item?.assignment?.submitted ? `complete` : `pending`} />
                            </div>
                            {!hideLink && item?.item?.assignment?.reviewer === item?.item?.assignment?.employee && <Link to={`/user/new-review/${item?.item?._id}/${item?.item?.assignment?._id}`} className='text-xs text-gray-700 font-[500] flex items-center gap-x-2 hover:text-verovian-purple duration-200 transition mt-3'>Submit review <ArrowIcon className={`w-4 h-4 -rotate-90`}/></Link>}
                        </div>
                    </div>}
                </div>
            </div>
            {activeOpenTask && userDetails().userType === 'USER' && <ModalLayout
                isOpen={taskOpen} 
                closeModal={()=>{closeTask()}} 
                actionFunction={()=>{}} 
                actionFunctionLabel='' 
                dialogTitle='Task Details'
                maxWidthClass='max-w-2xl'
            >
                <TaskAssignmentDetailsDialogContent assignment={activeOpenTask} />
            </ModalLayout>}

            {activeMaterial !== null && 
                <ModalLayout
                    isOpen={materialOpen} 
                    closeModal={()=>{setMaterialOpen(false)}} 
                    actionFunction={()=>{}} 
                    actionFunctionLabel={``} 
                    dialogTitle={activeMaterial.title}
                    dialogIntro={`By ${activeMaterial.instructor.name}`}
                    maxWidthClass='max-w-7xl'
                >
                    {/* <NewInstructor /> */}
                    {activeMaterial.content.contentType === 'VIDEO' && <VideoPlayer videoSrc={activeMaterial.content.content} /> }
                    {activeMaterial.content.contentType === 'AUDIO' && <AudioPlayer audioSrc={activeMaterial.content.content} /> }
                    {activeMaterial.content.contentType === 'TEXT' && <div className='p-[40px]' dangerouslySetInnerHTML={{__html: activeMaterial.content.content}} /> }
                    {activeMaterial?.content?.contentType === 'INFOGRAPHIC' && <img src={activeMaterial?.content?.content} alt='' className='w-full' /> }
                    
                    {activeMaterial?.content?.contentType === 'DOCUMENT' && 
                        <>
                            <p className='text-sm text-gray-400 mt-5 mb-3'>This lecture is a downloadable content. Please use the button below to download it.</p>
                            <a href={activeMaterial?.content?.content} target="_blank" className='mt-[20px] rounded p-2 border-[1.5px] flex w-max items-center justify-center gap-x-[5px] border-verovian-purple text-verovian-purple hover:border-gray-400 hover:text-gray-400 transition duration-200 font-[550] text-xs' rel="noreferrer"><DownloadIcon className={`w-5 h-5`} />Download Training Material</a>
                        </>
                    }

                    {item.item.assignment && !hideLink && 
                        <div className='w-1/2 my-4 flex gap-x-4 items-start justify-between'>
                            <div  className='w-full'>
                                <p className="text-sm text-gray-600">
                                    Complete training
                                </p>
                                <p className='text-xs text-gray-400'>Flip this switch to mark this training assignment as completed</p>
                            </div>
                            <div className='w-[50px] flex flex-row-reverse pt-[10px]'>
                                <Switch
                                    checked={item.item.assignment.completed}
                                    onChange={()=>{setAsComplete(item.item.assignment)}}
                                    className={`${
                                        item.item.assignment.completed ? 'bg-verovian-purple' : 'bg-gray-200'
                                    } relative inline-flex items-center h-5 rounded-full w-10`}
                                    >
                                    {/* <span className="sr-only">Display stock levels</span> */}
                                    <span
                                        className={`transform transition ease-in-out duration-200 ${
                                        item.item.assignment.completed ? 'translate-x-6' : 'translate-x-1'
                                        } inline-block w-3 h-3 transform bg-white rounded-full`}
                                    />
                                </Switch>
                            </div>
                        </div>
                    }
                    
                </ModalLayout>}
        </>
    )
}

export default OnboardingDayItemCard
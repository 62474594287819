import React, { useEffect, useState } from 'react'
import UserLayout from '../../../components/layout/UserLayout'
import EmployeeProfile from '../../../components/partials/employees/EmployeeProfile';
import EmployeeDocuments from '../../../components/partials/employees/EmployeeDocuments';
import EmployeeReviews from '../../../components/partials/employees/EmployeeReviews';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ERROR } from '../../../store/types';
import axios from 'axios';
import { authHeader } from '../../../utils';
import Preloader from '../../../components/elements/Preloader';

const EmployeeDetails = () => {
  const {employeeId} = useParams()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true);
  const [employeeDetails, setEmployeeDetails] = useState(null);
  const [activeTab, setActiveTab] = useState('profile');
  const tabs = [
    {
      label: "Profile Information",
      value: 'profile'
    },
    {
      label: "Performance",
      value: 'performance'
    },
    {
      label: "Employee Reviews",
      value: 'reviews'
    },
  ]

  useEffect(() => {
    const fetchEmployeeDetails = async () => {    
      try{
        const headers = authHeader()
        let requestUrl = `employees/${employeeId}`
        setLoading(true)
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/${requestUrl}`, { headers })

        setEmployeeDetails(response.data.data)
        setLoading(false)
      }
      catch(error){
        dispatch( {
            type: ERROR,
            error
        })
      }
    }  
    fetchEmployeeDetails()
    return () => {
      
    };
  }, [employeeId, dispatch]);

  return (
    <UserLayout pageTitle={`Employee Profile`}>
     {loading ? <Preloader preloadingText={`Loading article`} /> :
      <div className='w-full flex items-start justify-between gap-x-[20px]'>
        <div className='w-8/12 bg-white p-[20px] min-h-[100vh] mb-[20px]'>
          <div className='w-full flex items-center border-b border-gray-400'>
            {tabs.map((tab, tabIndex)=>(
              <button key={tabIndex} onClick={()=>{setActiveTab(tab.value)}} className={`${tab.value === activeTab ? 'bg-gray-100 font-[500]' : ''} px-[16px] py-[8px] border-b text-sm`}>
                {tab.label}
              </button>
            ))}
          </div>
          <div className='w-full py-[20px] px-[10px]'>
            {activeTab === 'profile' && <EmployeeProfile employeeDetails={employeeDetails} />}
            {activeTab === 'documents' && <EmployeeDocuments />}
            {activeTab === 'reviews' && <EmployeeReviews />}
          </div>
        </div>
        <div className='w-4/12'>
            {/* designation & department */}
            {/* Documents */}

        </div>
      </div>
     }
    </UserLayout>
  )
}

export default EmployeeDetails
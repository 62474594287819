import React, { useEffect, useState } from 'react'
import TrainingsLayout from '../../../components/layout/TrainingsLayout'
import TextField from '../../../components/elements/form/TextField'
import TextareaField from '../../../components/elements/form/TextareaField';
import RadioGroup from '../../../components/elements/form/RadioGroup';
import Wysiwyg from '../../../components/elements/form/Wysiwyg';
import FileUpload from '../../../components/elements/form/FileUpload';
import FormButton from '../../../components/elements/form/FormButton';
import { useDispatch, useSelector } from 'react-redux';
import { ERROR, SET_SUCCESS_MESSAGE } from '../../../store/types';
import { clearUpdatedTrainingMaterial, updateTrainingMaterial } from '../../../store/actions/trainingsActions';
import SelectField from '../../../components/elements/form/SelectField';
import { fetchDepartments } from '../../../store/actions/departmentActions';
import { getInstructors } from '../../../store/actions/instructorsActions';
import { useNavigate, useParams } from 'react-router-dom';
// import TrashIcon from '../../../components/elements/icons/TrashIcon';
import Checkbox from '../../../components/elements/form/Checkbox';
import { Switch } from '@headlessui/react';
import { authHeader } from '../../../utils';
import axios from 'axios';
import Preloader from '../../../components/elements/Preloader';
import VideoPlayer from '../../../components/elements/VideoPlayer';
import AudioPlayer from '../../../components/elements/AudoPlayer';
import ArrowPathIcon from '../../../components/elements/icons/ArrowPathIcon';
// import TimeInputField from '../../../components/elements/form/TimeInputField';

const EditTrainingMaterial = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { trainingMaterialId } = useParams()
    const trainingsSelector = useSelector((state => state.trainings))
    const departmentsSelector = useSelector(state => state.departments)
    const instructorsSelector = useSelector(state => state.instructors)
  
    const [materialPayload, setMaterialPayload] = useState({
      forTraining: true
    });
    const [validationErrors, setValidationErrors] = useState({});
    const [content, setContent] = useState({});
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(true);
  
    const [departments, setDepartments] = useState(['']);
  
    const formats = {
      'INFOGRAPHIC': ['jpeg', 'jpg', 'png'],
      'VIDEO': ['mp4'],
      'AUDIO': ['mp3', 'wav'],
      'DOCUMENT': ['pdf', 'doc', 'docx']
    }
  
    useEffect(() => {
      dispatch(fetchDepartments())
      dispatch(getInstructors(0, 0))

      const getTrainingMaterial = async () => {
        try {
          const headers = authHeader()
          
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/training/materials/${trainingMaterialId}`, { headers })
          setMaterialPayload(response.data.data)
          console.log('===> ',response.data.data.content)
          setContent(response.data.data.content)
          setDepartments(response.data.data.departments)
          setShowContent(true)
        //   setActiveModule(response.data.data.modules[0].module)
        //   getTrainingModule(response.data.data.modules[0].module._id)
          setLoading(false)
        } catch (error) {
          dispatch({
            type: ERROR,
            error: error
          })
          setLoading(false)
        }
      }
      getTrainingMaterial()
  
      if(trainingsSelector.updatedTrainingMaterial !== null){
        dispatch({
          type: SET_SUCCESS_MESSAGE,
          payload: {successMessage: "Training material updated successfully"}
        })
        dispatch(clearUpdatedTrainingMaterial())

      }
      return () => {
        
      };
    }, [dispatch, navigate, trainingMaterialId, trainingsSelector.updatedTrainingMaterial]);
  
    const [showContent, setShowContent] = useState(false);
    const selectContentType = (type) => {
      setShowContent(false)
      setContent({...content, ...{contentType: type.value}})
      setTimeout(() => {
        setShowContent(true)
      }, 200);
    }
  
    const validateForm = () => {
      let errors = {}
        if (!materialPayload.title || materialPayload.title === '') {
          errors.title = true
        }
        if (!materialPayload.description || materialPayload.description === '' || materialPayload.description.length > 400) {
          errors.description = true
        }
        if (!content.contentType || content.contentType === '') {
          errors.contentType = true
        }
        if ((!content.content || content.content === '') && !file) {
          errors.content = true
        }
        if (!materialPayload.instructor || materialPayload.instructor === '') {
          errors.instructor = true
        }
        if (!materialPayload.estimatedTime || materialPayload.estimatedTime === '') {
          errors.estTime = true
        }
        if(departments && departments.length > 0 && departments[0] !== '') {
          departments.forEach((dept, deptIndex)=>{
            if(dept === '') {
              errors[`departments-${deptIndex}`] = true
            }
          })
        }
  
        setValidationErrors(errors)
        return errors
    }
  
    const handleUpload = async (file) => {
        console.log(file)
  
        var formData = new FormData()
        formData.append('file', file.file )
        const headers = new Headers();
  
        try {
            const doUpload = await fetch(`${process.env.REACT_APP_API_URL}/files/new`, {
                method: "POST",
                headers,
                body: formData,
            });
            const response = await doUpload.json();
    
           return response
        } catch (error) {
        console.log(error)
            dispatch({
                type: ERROR,
                error,
            });
        }
    }
  
    const pushMaterial = async () => {
      if (Object.values(validateForm()).includes(true)) {
        dispatch({
            type: ERROR,
            error: {response: {data: {
                message: 'Please check the highlighted fields'
            }}}
        });
        return
      }
  
      const payload = {
        ...materialPayload,
        ...{
          content: content,
        }
      }
  
      if(departments && departments.length > 0 && departments[0] !== '') {
        payload.departments = departments
      }
  
      if(file && fileSource === "UPLOAD") {
        const uploaded = await handleUpload(file)
        payload.content.content = uploaded.data.file
      }
  
      dispatch(updateTrainingMaterial(materialPayload._id, payload))
    }
  
    const [fileSource, setFileSource] = useState('UPLOAd');
  
    // const addDepartment = () => {
    //   let tempDepartments = [...departments]
    //   tempDepartments.push('')
    //   setDepartments(tempDepartments)
    // }
  
    // const deleteDepartment = (index) => {
    //   let tempDepartments = [...departments]
    //   setDepartments(tempDepartments.filter((dept, deptIndex) => {
    //     return deptIndex !== index
    //   }))
    // }
  
    // const updateDepartment = (index, value) => {
    //   let tempDepartments = [...departments]
    //   tempDepartments[index] = value
    //   setDepartments(tempDepartments)
    // }
  
    const toggleDepartment = (departmentId) => {
      let temp = [...departments]
      if(temp.includes(departmentId)){
        temp = temp.filter(dept => dept !== departmentId)
      } else {
        temp.push(departmentId)
      }
      setDepartments(temp)
    }

    const contentTypes = [
        {label: 'Text', value: 'TEXT'},
        {label: 'Audio', value: 'AUDIO'},
        {label: 'Video', value: 'VIDEO'},
        {label: 'Infographic', value: 'INFOGRAPHIC'},
        {label: 'Document', value: 'DOCUMENT'},
    ]

    const [changingContentContent, setChangingContentContent] = useState(false);
  
    return (
        <TrainingsLayout sectionTitle={`New Training Material`}>
            {loading ? 
                <Preloader />
            :
                <div className='w-8/12 mb-[100px] px-[20px] pb-[20px] pt-[10px]  bg-white mt-[20px]'>
                    <p className='mt-[20px] font-medium text-[15px]'>Material Details</p>
                    <p className='text-[14px] '>Please provide training material details below to create a new training material</p>
            
                    <div>
                        <div className='w-full'>
                            <div className='mb-4 mt-2 w-full'>
                                <TextField
                                    inputLabel="Title" 
                                    fieldId="material-name" 
                                    inputType="text" 
                                    preloadValue={materialPayload.title || ''}
                                    hasError={validationErrors.title } 
                                    returnFieldValue={(value)=>{setMaterialPayload({...materialPayload, ...{title: value}})}}
                                />
                            </div>
                
                            <div className='mb-4 mt-2 w-full'>
                                <TextareaField
                                    inputLabel="Description" 
                                    fieldId="material-description" 
                                    inputType="text" 
                                    preloadValue={materialPayload.description || ''}
                                    hasError={validationErrors.description} 
                                    returnFieldValue={(value)=>{setMaterialPayload({...materialPayload, ...{description: value}})}}
                                />
                                {materialPayload?.description?.length > 400 && <p className='text-xs text-red-500 mt-[10px]'>Description text is too long, please make it 400 characters or less</p>}
                            </div>
            
            
                            {/* <div key={deptIndex} className='my-4 w-full'>
                            {deptIndex > 0 && <div className='flex flex-row-reverse'>
                            <button onClick={()=>{deleteDepartment(deptIndex)}} className='p-3 rounded text-red-500 text-xs'>
                                <TrashIcon className={`w-4 h-4`} />
                            </button>
                            </div>}
                            <SelectField
                                selectOptions={departmentsSelector.departments}
                                inputLabel="Select Department"
                                titleField="name"
                                displayImage={false}
                                imageField=""
                                preSelected=''
                                fieldId={`${deptIndex}-department`}
                                hasError={validationErrors[`department-${deptIndex}`]}
                                // return id of accounts of the selected option
                                returnFieldValue={(value) => {updateDepartment(deptIndex, value._id)}}
                            /> */}
                            {!departmentsSelector.loadingDepartments && departmentsSelector.departments && departmentsSelector.departments.length > 0 && departmentsSelector.departments.map((dept, deptIndex)=>(
                                <div className='flex items-center justify-between' key={deptIndex}>
                                    <div className='flex flex-row gap-x-4 justify-between items-start my-1 hover:bg-gray-100 transition duration-200 py-2 rounded px-6'>
                                        <span className='mt-[2px]'>
                                        <Checkbox 
                                            CheckboxLabel=""
                                            checkboxToggleFunction={()=>{toggleDepartment(dept._id)}} 
                                            isChecked={departments.includes(dept._id)} 
                                            hasError={false}
                                        />
                                        </span>
                                        <div className='w-full flex gap-x-2 items-start'>
                                        <div>
                                            <p className='font-medium text-sm'>{dept.name}</p>
                                            {/* <p className='font-thin text-gray-600 text-xs'>{item.description}</p> */}
                                        </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            ))}
            
                        <div className='w-full'>
                            <div className='w-full my-4 flex gap-x-4 items-start font-[500] justify-between'>
                                <div className='w-full'>
                                    <p className="text-[15px] text-gray-800">
                                    Is this material for Onboarding packages?
                                    </p>
                                    <p className='text-xs text-gray-400'>Training materials created for onboarding packages will be visible to employees inside of onboarding packages. If it is not for onboarding packages, Employees will be only able to see them when they are part of a training assigned to them</p>
                                </div>
                                <div className='w-24 flex flex-row-reverse'>
                                    <Switch
                                    checked={materialPayload?.forOnboardingPackage === true}
                                    onChange={()=>{setMaterialPayload({...materialPayload, 
                                        ...{forOnboardingPackage : !materialPayload.forOnboardingPackage}})}}
                                    className={`${
                                        materialPayload?.forOnboardingPackage === true ? 'bg-verovian-purple' : 'bg-gray-200'
                                    } relative inline-flex items-center h-5 rounded-full w-10`}
                                    >
                                    <span
                                        className={`transform transition ease-in-out duration-200 ${
                                        materialPayload?.forOnboardingPackage === true ? 'translate-x-6' : 'translate-x-1'
                                        } inline-block w-3 h-3 transform bg-white rounded-full`}
                                    />
                                    </Switch>
                                </div>
                            </div>
                        </div>
            
                        <div className='w-full'>
                            <div className='w-full my-4 flex gap-x-4 items-start font-[500] justify-between'>
                                <div  className='w-full'>
                                    <p className="text-[15px] text-gray-800">
                                    Is this material for Trainings?
                                    </p>
                                    <p className='text-xs text-gray-400'>Training materials created for trainings will be visible to employees inside training modules.</p>
                                </div>
                                <div className='w-24 flex flex-row-reverse'>
                                    <Switch
                                    checked={materialPayload?.forTraining === true}
                                    onChange={()=>{setMaterialPayload({...materialPayload, 
                                        ...{forTraining : !materialPayload.forTraining}})}}
                                    className={`${
                                        materialPayload?.forTraining === true ? 'bg-verovian-purple' : 'bg-gray-200'
                                    } relative inline-flex items-center h-5 rounded-full w-10`}
                                    >
                                    <span
                                        className={`transform transition ease-in-out duration-200 ${
                                        materialPayload?.forTraining === true ? 'translate-x-6' : 'translate-x-1'
                                        } inline-block w-3 h-3 transform bg-white rounded-full`}
                                    />
                                    </Switch>
                                </div>
                            </div>
                        </div>
            
                        {/* <div className='flex flex-row-reverse'>
                            <button onClick={()=>{addDepartment()}} className='p-3 bg-gray-200 text-gray-500 text-xs'>Add department</button>
                        </div> */}
            
                        <p className='mt-[20px] font-medium text-[15px]'>Instructor and Content</p>
                        <p className='text-xs mt-[10px]'>Description text is too long, please make it 240 characters or less</p>
            
                        {!instructorsSelector.loadingInstructors && instructorsSelector?.instructors?.instructors && instructorsSelector?.instructors?.instructors?.length > 0 && <div className='my-4 w-full'>
                            <SelectField
                                selectOptions={instructorsSelector?.instructors?.instructors}
                                inputLabel="Select Instructor"
                                titleField="name"
                                displayImage={false}
                                imageField=""
                                preSelected={materialPayload.instructor._id}
                                preSelectedLabel={`_id`}
                                fieldId="designation-department"
                                hasError={validationErrors.instructor}
                                // return id of accounts of the selected option
                                returnFieldValue={(value) => {setMaterialPayload({...materialPayload, ...{instructor: value._id}})}}
                            />
                            <p className='text-xs mt-[10px]'>Select the instructor for this training material above</p>
                        </div>}
            
                        <div className='my-4 w-full'>
                            {/* <TimeInputField
                            inputLabel="Estimated completion time (in minutes)" 
                            fieldId="monday-opening-time" 
                            preloadValue={''}
                            hasError={validationErrors.estTime} 
                            returnFieldValue={(value)=>{
                                setMaterialPayload({...materialPayload, ...{estimatedTime: value}})}}
                            /> */}
                            <TextField
                                inputLabel="Estimated completion time (in minutes)" 
                                fieldId="instructor-name" 
                                inputType="text" 
                                preloadValue={materialPayload.estimatedTime || ''}
                                hasError={validationErrors.estTime } 
                                returnFieldValue={(value)=>{setMaterialPayload({...materialPayload, ...{estimatedTime: value}})}}
                            />
                        </div>
            
                        <div className='my-4 w-full'>
                            <RadioGroup
                                inputLabel="Content type"
                                inline={true}
                                items={contentTypes} 
                                hasError={validationErrors.contentType} 
                                preSelectedIndex={contentTypes.findIndex(type => type.value === materialPayload.content.contentType)}
                                returnSelected={(value)=>{selectContentType(value)}}
                            />
                        </div>

                        <button onClick={()=>{setChangingContentContent(!changingContentContent)}} className='flex items-center justify-center gap-x-[8px] font-[500] text-[14px] mb-[15px] bg-gray-100 rounded-[8px] px-[15px] py-[8px]'>
                            <ArrowPathIcon className={`w-4 h-4`} />
                            Change Content
                        </button>
            
                        {content.contentType && content.contentType !== '' && showContent && <div className='w-full'>
                            {content?.contentType === 'TEXT' && <div className='w-full my-4'>
                                <p className='mt-[50px] font-medium text-[15px]'>Text Content</p>
                                <p className='text-xs mt-[10px]'>Please provide the text content for this material below</p>
                                <div className='borderless long-text'>
                                    <Wysiwyg 
                                        fieldTitle=""
                                        initialValue={content.content || ''}
                                        updateValue={(value)=>{setContent({...content, ...{content: value}})}}
                                    />
                                </div>
                            </div>}
            
                            {content?.contentType !== 'TEXT' && changingContentContent && 
                            <div className='w-full my-4'>
                                <p className='mt-[50px] font-medium text-[15px]'><span className='capitalize'>{content.contentType.toLowerCase()}</span> Content</p>
                                {content.contentType !== "TEXT" && 
                                    <>
                                    <p className='text-xs mt-[10px] mb-[20px]'>Please drop your <span className=''>{content.contentType.toLowerCase()}</span> You can add your content from an external url or upload it directly. Please choose an option below.</p>
                
                                    <div className='my-4 w-full'>
                                        <RadioGroup
                                        inputLabel="File Source"
                                        inline={true}
                                        items={[
                                            {label: 'Upload video', value: 'UPLOAD'},
                                            {label: 'Use External link', value: 'URL'},
                                        ]} 
                                        hasError={false} 
                                        returnSelected={(value)=>{setFileSource(value.value)}}
                                        />
                                    </div>
                                    </>
                                
                                }
                            
                                {changingContentContent && content.contentType !== 'TEXT' && fileSource === 'UPLOAD' &&
                                    <>
                                    <p className='text-xs mt-[10px] mb-[20px]'>Please drop your <span className=''>{content.contentType.toLowerCase()}</span> content in the box below to upload.</p>
                                    <FileUpload
                                        hasError={validationErrors.content}
                                        noSizeRestrict={true}
                                        fieldLabel={`Material ${content.contentType.toLowerCase()}`}
                                        returnFileDetails={(details)=>{
                                        setFile(details)
                                        }}
                                        acceptedFormats={formats[content.contentType]}
                                    />
                                    <label className='block mt-3 text-xs text-gray-400'>Upload the file by clicking above or dropping the file</label>
                                    </>
                                }

                                {changingContentContent && content.contentType !== 'TEXT' && fileSource === 'URL' &&
                                    <>
                                        <p className='text-xs mt-[10px] mb-[20px]'>Paste the source url of your video/audio file here</p>
                                        <TextField
                                            inputLabel="File URL" 
                                            fieldId="file-url" 
                                            inputType="text" 
                                            preloadValue={''}
                                            hasError={validationErrors.content } 
                                            returnFieldValue={(value)=>{setContent({...content, ...{content: value}})}}
                                        />
                                    </>
                                }


                            </div>
                        }

                        {!changingContentContent && content.contentType === 'VIDEO' && 
                            <VideoPlayer videoSrc={content?.content} autoPlay={false} />
                        }

                        {!changingContentContent && content.contentType === 'AUDIO' && 
                            <AudioPlayer audioSrc={content?.content} />
                        }

                        {/* {activeMaterial?.content?.contentType === 'TEXT' && <div className='pb-12' dangerouslySetInnerHTML={{__html: activeMaterial?.content?.content}} />}
                        {activeMaterial?.content?.contentType === 'INFOGRAPHIC' && <img src={activeMaterial?.content?.content} alt='' className='w-full' /> } */}

                        {!changingContentContent && content.contentType === 'INFOGRAPHIC' && 
                            <img src={content?.content} alt='' className='w-full' /> 
                        }
                        </div>
                        }
            
            
                        </div>
            
                        <div className='w-max mt-[40px]'>
                            <FormButton buttonLabel={`Update Training Material`} processing={trainingsSelector.updatingTrainingMaterial} buttonAction={()=>{pushMaterial()}} />
                        </div>
                    </div>
                </div>
            }
        </TrainingsLayout>
    )
}
export default EditTrainingMaterial
import React, { useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Logo from '../../assets/img/ss-tt.png'
import Preloader from '../../components/elements/Preloader'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { ERROR, SET_SUCCESS_MESSAGE } from '../../store/types'

const EmailConfirmation = () => {
    const { confirmationCode } = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        const confirmEmail = async () => {    
            try{
                const headers = {}
                const requestUrl = `auth/confirm-email/${confirmationCode}`
    
                await axios.get(`${process.env.REACT_APP_API_URL}/${requestUrl}`, { headers })
                dispatch({
                    type: SET_SUCCESS_MESSAGE,
                    payload: {
                        successMessage: 'Email confirmed. Redirecting to login...'
                    }
                })
                setTimeout(() => {
                    navigate('/')
                }, 3000);
            }
            catch(error){
              dispatch( {
                  type: ERROR,
                  error
              })
            }
        }

        confirmEmail()

        return () => {
            
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [confirmationCode, dispatch]);
    
    return (
        <div className="w-full flex items-start justify-center h-screen">
            <div className="w-10/12 xl:w-8/12 2xl:w-4/12 p-6 rounded border mt-[50px]">
                <Link to="/" className='w-max inline'>
                    <span className="flex items-center gap-x-2">
                        <img src={Logo} width={100} alt="logo" />
                    </span>
                </Link>

                <Preloader preloadingText={`Please wait, we're confirming your email address...`} />

            </div>
        </div>
    )
}

export default EmailConfirmation
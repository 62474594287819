import React from 'react'
import EmployeeSnippet from '../employees/EmployeeSnippet'
import { transactionTimeStamp } from '../../../utils'
import { Link } from 'react-router-dom'
import ArrowIcon from '../../elements/icons/ArrowIcon'

const OwnReviewScheduleCard = ({schedule}) => {
  return (
    <div className='w-full p-[20px] border'>
        <p className='text-sm font-[500] mb-[10px]'>{schedule?.form?.name}</p>
        <p className="text-xs mb-[10px]">{schedule?.form?.description.length > 120 ? `${schedule.form.description.substr(0, 120)}...` : schedule?.form?.description}</p>

        <EmployeeSnippet showIcon={true} name={schedule?.employee?.fullName} email={schedule?.employee?.email} />
        {schedule.dueDateRange && <p className='text-xs mt-[10px]'>Open from {transactionTimeStamp(schedule.dueDateRange?.from).date} to {transactionTimeStamp(schedule?.dueDateRange?.to).date}</p>}
        {schedule.dueDate && !schedule.dueDateRange && <p className='text-xs mt-[10px]'>Due on {transactionTimeStamp(schedule?.dueDate).date}</p>}
        <Link to={`/user/new-review/${schedule?.form?._id}/${schedule?._id}`} className='text-xs text-gray-600 flex items-center gap-x-2 hover:text-verovian-purple duration-200 transition mt-3 font-[550]' >Submit Review <ArrowIcon className={`w-4 h-4 -rotate-90`}/></Link>

    </div>
  )
}

export default OwnReviewScheduleCard
import React, { useEffect, useState } from 'react'
import FormButton from '../../elements/form/FormButton'
import TextField from '../../elements/form/TextField'
import { useDispatch, useSelector } from 'react-redux';
import { ERROR } from '../../../store/types';
import { createHoliday } from '../../../store/actions/holidayActions';
import RadioGroup from '../../elements/form/RadioGroup';
import TextareaField from '../../elements/form/TextareaField';
import { Switch } from '@headlessui/react';
import DateField from '../../elements/form/DateField';
import { fetchDepartments } from '../../../store/actions/departmentActions';
import Checkbox from '../../elements/form/Checkbox';

const NewHoliday = () => {
    const [validationErrors, setValidationErrors] = useState({});
    const [holidayPayload, setHolidayPayload] = useState({
        name: "",
        type: "",
        description: "",
        dates: [""],
        daysOffAllowed: false,
        daysOff: [""]
    });

    const dispatch = useDispatch()
    const holidaysState = useSelector(state => state.holidays)
    const departmentsSelector = useSelector(state => state.departments)
    const [departments, setDepartments] = useState([]);
    
    useEffect(() => {
        dispatch(fetchDepartments(0, 0))
        return () => {
            
        };
    }, [dispatch]);

    const toggleDepartment = (departmentId) => {
        let temp = [...departments]
        if(temp.includes(departmentId)){
          temp = temp.filter(dept => dept !== departmentId)
        } else {
          temp.push(departmentId)
        }
        setDepartments(temp)
      }

    const validateForm = () => {
        let errors = {}
        if (!holidayPayload.name || holidayPayload.name === '') {
            errors.name = true
        }

        setValidationErrors(errors)
        return errors
    }

    const triggerCreateHoliday = () => {
        if (Object.values(validateForm()).includes(true)) {
            dispatch({
                type: ERROR,
                error: {response: {data: {
                    message: 'Please check the highlighted fields'
                }}}
            });
            return
        }
        const payload = {...holidayPayload}
        if(departments.length > 0) {
            payload.departments = departments
        }
        dispatch(createHoliday(payload))
    }

    const toggleDaysOffAllowed = () => {
        setHolidayPayload({...holidayPayload, daysOffAllowed: !holidayPayload.daysOffAllowed})
    }

    const updateDate = (index, value) => {
        let tempData = {...holidayPayload}
        tempData.dates[index] = value
        setHolidayPayload(tempData)
    }

    const updateDayOff = (index, value) => {
        let tempData = {...holidayPayload}
        tempData.daysOff[index] = value
        setHolidayPayload(tempData)
    }

    return (
        <>
            <div className='flex items-start justify-between gap-x-[10px]'>
                <div className='w-full'>
                    <div className='mb-4 w-full'>
                        <TextField
                            inputLabel="Holiday name" 
                            fieldId="dept-name" 
                            inputType="text" 
                            preloadValue={''}
                            hasError={validationErrors.name} 
                            returnFieldValue={(value)=>{setHolidayPayload({...holidayPayload, ...{name: value}})}}
                        />
                    </div>
                    <div className='mb-4 w-full'>
                        <RadioGroup
                            inputLabel="Holiday type"
                            items={[
                                {label: 'Religious', value: 'RELIGIOUS'},
                                {label: 'National', value: 'NATIONAL'},
                                {label: 'Company', value: 'COMPANY'},
                            ]} 
                            inline={true}
                            hasError={validationErrors.gender} 
                            returnSelected={(value)=>{setHolidayPayload({...holidayPayload, ...{type: value.value}})}}
                        />
                    </div>
                    <div className='w-full mb-4 flex gap-x-4 items-center justify-between'>
                        <div  className='w-full'>
                            <p className="text-sm text-gray-600">
                                Allow days off for this holiday?
                            </p>
                        </div>
                        <div className='w-24'>
                            <Switch
                                checked={holidayPayload.daysOffAllowed}
                                onChange={()=>{toggleDaysOffAllowed()}}
                                className={`${
                                    holidayPayload.daysOffAllowed ? 'bg-verovian-purple' : 'bg-gray-200'
                                } relative inline-flex items-center h-5 rounded-full w-10`}
                                >
                                {/* <span className="sr-only">Display stock levels</span> */}
                                <span
                                    className={`transform transition ease-in-out duration-200 ${
                                        holidayPayload.daysOffAllowed ? 'translate-x-6' : 'translate-x-1'
                                    } inline-block w-3 h-3 transform bg-white rounded-full`}
                                />
                            </Switch>
                        </div>
                    </div>
                    <div className='mb-4 w-full'>
                        <TextareaField
                            inputLabel="Description" 
                            fieldId="dept-description" 
                            inputType="text" 
                            preloadValue={''}
                            hasError={false} 
                            returnFieldValue={(value)=>{setHolidayPayload({...holidayPayload, ...{description: value}})}}
                        />
                    </div>

                    {holidayPayload.dates.map((date, dateIndex) => (
                        <div className='w-full mb-4' key={dateIndex}>
                            <DateField
                                inputLabel="Date" 
                                inputPlaceholder="" 
                                fieldId={`holiday-date-${dateIndex}`} 
                                inputType="date" 
                                hasError={validationErrors.course} 
                                returnFieldValue={(value) => {updateDate(dateIndex, value)}}
                            />
                        </div>
                    ))}
                    <button onClick={()=>{setHolidayPayload({...holidayPayload, ...{dates: [...holidayPayload.dates, ...[""]]}})}} className="w-max rounded p-2 border border-verovian-purple text-verovian-purple bg-verovian-light-purple text-xs font-medium">Add date</button>

                    {holidayPayload.daysOffAllowed && <>
                        {holidayPayload.daysOff.map((date, dateIndex) => (
                            <div className='w-full mb-4' key={dateIndex}>
                                <DateField
                                    inputLabel="Days off" 
                                    inputPlaceholder="" 
                                    fieldId={`holiday-day-off-${dateIndex}`} 
                                    inputType="date" 
                                    hasError={validationErrors[`dayOff-${dateIndex}`]} 
                                    returnFieldValue={(value) => {updateDayOff(dateIndex, value)}}
                                />
                            </div>
                        ))}
                        <button onClick={()=>{setHolidayPayload({...holidayPayload, ...{dates: [...holidayPayload.dates, ...[""]]}})}} className="w-max rounded p-2 border border-verovian-purple text-verovian-purple bg-verovian-light-purple text-xs font-medium">Add day off</button>
                    </>}

                </div>
                <div className='w-full'>
                  <label className={`block cursor-text bg-transparent text-sm z-10 font-outfit transition duration-200 text-gray-800 mt-[25px]`}>
                    Departments
                  </label>
                  <label className={`block cursor-text bg-transparent text-xs mt-[5px] z-10 font-outfit transition duration-200 text-gray-500`}>
                    Select departments below to restrict this holiday to them.
                  </label>
                  {!departmentsSelector?.loadingDepartments && departmentsSelector?.departments && departmentsSelector?.departments?.departments?.length > 0 && departmentsSelector?.departments?.departments?.map((dept, deptIndex)=>(
                  <div className='flex items-center justify-between' key={deptIndex}>
                    <div className='flex flex-row gap-x-4 justify-between items-start my-1 hover:bg-gray-100 transition duration-200 py-2 rounded px-6'>
                      <span className='mt-[2px]'>
                        <Checkbox 
                          CheckboxLabel=""
                          checkboxToggleFunction={()=>{toggleDepartment(dept._id)}} 
                          isChecked={departments.includes(dept._id)} 
                          hasError={false}
                        />
                      </span>
                      <div className='w-full flex gap-x-2 items-start'>
                        <div>
                          <p className='font-medium text-sm'>{dept.name}</p>
                          {/* <p className='font-thin text-gray-600 text-xs'>{item.description}</p> */}
                        </div>
                      </div>
                      
                    </div>
                  </div>
                  ))}
                </div>
            </div>
            <div className='my-8 flex flex-row-reverse items-center justify-between'>
                <div className='w-4/12'>
                <FormButton 
                    buttonLabel={`Create Holiday`} 
                    buttonAction={()=>{triggerCreateHoliday()}} 
                    processing={holidaysState.creatingHoliday}
                />
                </div>
            </div>
        </>
    )
}

export default NewHoliday
import React from 'react'
import { Link } from 'react-router-dom'
import ArrowIcon from '../../elements/icons/ArrowIcon'
import Status from '../../elements/Status'
import { transactionTimeStamp } from '../../../utils'

const EmployeeReviewFormCard = ({form, review, progress}) => {
    const totalQuestions =  form?.responses?.length // form.sections.reduce((accumulator, current) => accumulator+current.questions.length, 0) 
    return (
      <div className='bg-white w-full relative'>
        <div className='px-[25px] py-[25px]'>
          <p className='text-sm'>{form?.name}</p>
          <div className='flex items-center justify-between my-[10px]'>
            <p className='text-xs'>
            {form?.sections?.length} section(s), {totalQuestions} question(s) </p>
            {/* <span className='inline-block capitalize rounded border border-gray-400 text-gray-600 px-[7px] py-[5px] text-xs'>{form.frequency}</span> */}
            
          </div>
          
          <p className="text-xs">{form?.description?.length > 120 ? `${form?.description.substr(0, 120)}...` : form?.description}</p>
          
          {/* <p className='text-xs font-montserrat mt-[10px]'><span className='font-space-grotesk text-sm'>{progress?.done}</span> responses submitted out of <span className='font-space-grotesk text-sm'>{progress?.done + progress?.pending}</span></p> */}

          <p className='text-xs flex items-center gap-x-1 mt-[10px]'>Review for <span className='font-[500]'>{review?.employee?.fullName}</span> by <span className='font-[500]'>{review?.createdBy?.firstName} {review?.createdBy?.lastName}</span></p>
          
          <p className='text-xs flex items-center gap-x-1 mt-[10px]'>Overall Sentiment: <span className='w-max'><Status status={review?.sentiment} /></span></p>

          <p className='text-xs flex items-center gap-x-1 mt-[10px]'>Submitted {transactionTimeStamp(review.createdAt).date} - {transactionTimeStamp(review.createdAt).time}</p>


        </div>

        <div className='px-[25px] pt-[15px] pb-[15px] border-t'>
          <Link to={`/user/reviews-management/reviews/detail/${review?._id}`} className='text-xs text-gray-600 flex items-center gap-x-2 hover:text-verovian-purple duration-200 transition font-[550]' >
            See review details & responses <ArrowIcon className={`w-4 h-4 -rotate-90`}/>
          </Link>
        </div>
      </div>
    )
}

export default EmployeeReviewFormCard
import React, { useEffect, useState } from 'react'
import TrainingsLayout from '../../../components/layout/TrainingsLayout'
import { useDispatch, useSelector } from 'react-redux'
import { clearUpdatedTraining, getEmployeeTrainings, getTrainings } from '../../../store/actions/trainingsActions'
import Preloader from '../../../components/elements/Preloader'
import EmptyState from '../../../components/elements/icons/EmptyState'
import PlusIcon from '../../../components/elements/icons/PlusIcon'
import { Link } from 'react-router-dom'
import TrainingCard from '../../../components/partials/trainings/trainings/TrainingCard'
import Pagination from '../../../components/elements/Pagination'
import { employeeProfile, hasPermission, userDetails } from '../../../utils'
import { SET_SUCCESS_MESSAGE } from '../../../store/types'
import EmployeeTrainingCard from '../../../components/partials/trainings/trainings/EmployeeTrainingCard'

const Trainings = () => {
    const trainingsSelector = useSelector(state => state.trainings)
    const dispatch = useDispatch()

    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(25);
    

    useEffect(() => {
        dispatch(getEmployeeTrainings(page, perPage))
        if(userDetails().userType !== 'USER') {
            setActiveTab('all')
            dispatch(getTrainings(page, perPage))
        } 

        if(userDetails().userType === 'USER' && employeeProfile().department) {
            dispatch(getTrainings(page, perPage, `department=${employeeProfile().department._id}`))
        } 

        if(trainingsSelector.updatedTraining !== null){
            dispatch(clearUpdatedTraining())
            dispatch({
                type: SET_SUCCESS_MESSAGE,
                payload: {
					successMessage: "Updated successfully"
                }
            })
        }
        return () => {
            
        };
    }, [dispatch, page, perPage, trainingsSelector.updatedTraining]);

    const [activeTab, setActiveTab] = useState('assigned');


    return (
        <TrainingsLayout sectionTitle={`Trainings`}>
            {trainingsSelector.loadingTrainings ? <Preloader preloadingText={`Loading trainings`} /> :
            <div className=''>
                <div className='w-full lg:flex flex-row gap-x-8'>
                    <div className='w-full pb-6 mx-auto mt-6'>
                        <div className='flex justify-between items-center mt-4 mb-4'>
                            <div>
                                {userDetails().userType !== 'USER' && 
                                    <div className='flex items-center gap-x-[5px]'>
                                        <button onClick={()=>{setActiveTab('all')}} className={`text-xs font-[500] text-gray-600 rounded px-[16px] py-[10px] ${activeTab === 'all' ? 'bg-gradient-to-b from-gray-100 via-transparent to-transparent' : 'bg-gray-200'}`}>
                                            All Trainings
                                        </button>
                                        <button onClick={()=>{setActiveTab('assigned')}} className={`text-xs font-[500] text-gray-600 rounded px-[16px] py-[10px] ${activeTab === 'assigned' ? 'bg-gradient-to-b from-gray-100 via-transparent to-transparent' : 'bg-gray-200'}`}>
                                            Trainings Assigned to You
                                        </button>
                                    </div>
                                }
                            </div>

                            {hasPermission(['*', 'trainings.*', 'trainings.create']) && <Link to={`new-training`}>
                            <button className='flex gap-x-2 items-center rounded bg-verovian-purple px-3 py-3 text-verovian-light-purple text-sm transition duration-200 hover:bg-gray-600'>
                                <PlusIcon className={`h-5 w-5`} />
                                Create training
                            </button>
                            </Link>}

                        </div>

                        {activeTab === 'all' && <>
                            {trainingsSelector?.trainings?.trainings?.length > 0 ?
                                <>                                    
                                    <div className='grid grid-cols-2 2xl:grid-cols-3 gap-6'>
                                        {trainingsSelector?.trainings?.trainings?.map((training, trainingIndex)=>(
                                        <div key={trainingIndex} className="bg-white">
                                            <TrainingCard training={training} />
                                        </div>
                                        ))}
                                    </div>

                                    <Pagination 
                                        pagination={{
                                            currentPage: page,
                                            perPage: perPage,
                                            totalItems: trainingsSelector?.trainings?.total
                                        }}
                                        changePage={(value)=>{setPage(value)}}
                                        updatePerPage={(value)=>{setPerPage(value)}}
                                    />
                                </>
                                :
                                <EmptyState emptyStateText={`No trainings created yet, use the "create training module" button above to create a new one.`} />
                            }
                        </>}

                        {activeTab === 'assigned' && <>
                            <div className='w-1/2'>
                                <h3 className='text-md font-[500] text-verovian-purple'>Your Trainings</h3>
                                <p className='text-sm mt-[5px]'>Below that you are currently taking part in or are trainings assigned to you. Click on "Open Training" on any training to get started or continue.</p>
                            </div>
                            {trainingsSelector?.employeeTrainings?.employeeTrainings?.length > 0 ?
                                <div className='mt-[20px]'>                                    
                                    <div className='grid grid-cols-2 2xl:grid-cols-3 gap-6'>
                                        {trainingsSelector?.employeeTrainings?.employeeTrainings?.map((assignment, assignmentIndex)=>(
                                        <div key={assignmentIndex} className="bg-white">
                                            <EmployeeTrainingCard assignment={assignment} />
                                        </div>
                                        ))}
                                    </div>

                                    <Pagination 
                                        pagination={{
                                            currentPage: page,
                                            perPage: perPage,
                                            totalItems: trainingsSelector?.employeeTrainings?.total
                                        }}
                                        changePage={(value)=>{setPage(value)}}
                                        updatePerPage={(value)=>{setPerPage(value)}}
                                    />
                                </div>
                                :
                                <EmptyState emptyStateText={`No trainings have been assigned to you yet`} />
                            }

                            {trainingsSelector?.trainings?.trainings?.length > 0 &&
                                <div className='pt-[20px] mt-[20px] border-t border-gray-300'> 
                                    <div className='w-1/2 mb-[20px]'>
                                        <h3 className='text-md font-[500] text-verovian-purple'> Trainings for your Department ({employeeProfile()?.department?.name})</h3>
                                        <p className='text-sm mt-[5px]'>Below are trainings created for your department. You can open any of the trainings below and enrol/get started</p>                                   
                                    </div>  
                                    <div className='grid grid-cols-2 2xl:grid-cols-3 gap-6'>
                                        {trainingsSelector?.trainings?.trainings?.map((training, trainingIndex)=>(
                                        <div key={trainingIndex} className="bg-white">
                                            <TrainingCard training={training} />
                                        </div>
                                        ))}
                                    </div>

                                    <Pagination 
                                        pagination={{
                                            currentPage: page,
                                            perPage: perPage,
                                            totalItems: trainingsSelector?.trainings?.total
                                        }}
                                        changePage={(value)=>{setPage(value)}}
                                        updatePerPage={(value)=>{setPerPage(value)}}
                                    />
                                </div>
                            }
                        </>}

                    </div>
                </div>
            </div>}
        </TrainingsLayout>
    )
}

export default Trainings
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchEmployeeTasks } from '../../../store/actions/tasksActions';
import EmptyState from '../../elements/icons/EmptyState';
import PlusIcon from '../../elements/icons/PlusIcon';
import { Link } from 'react-router-dom';
import { userDetails } from '../../../utils';
import ModalLayout from '../../layout/ModalLayout';
import TaskAssignmentDetailsDialogContent from '../tasks/TaskAssignmentDetailsDialogContent';
import AssignedTaskCard from '../tasks/AssignedTaskCard';

const EmployeeTasks = () => {
    const tasksSelector = useSelector(state => state.tasks)
    const dispatch = useDispatch()
  
    useEffect(() => {
        dispatch(fetchEmployeeTasks())
        return () => {
            
        };
    }, [dispatch]);

    const [taskOpen, setTaskOpen] = useState(false);
    const [activeOpenTask, setActiveOpenTask] = useState(null);

    const openTask = (task) => {
      setActiveOpenTask(task)
      setTimeout(() => {
          setTaskOpen(true)
      }, 50);
  }

  const closeTask = () => {
      setTaskOpen(false)
      setTimeout(() => {
          setActiveOpenTask(null)
      }, 50);
  }
  
    // const trainingProgresses = ['20%', '17%', '79%', '92%']
    return (
      <>
        <div>
          <div className='flex items-start justify-between mt-5 mb-12'>
              <div>
                  <p className="text-md mb-1 font-[500]">Employee Tasks</p>
                  <p className='text-sm'>Please see tasks assigned to this employee below.</p>
              </div>
              <Link to={`new-article`}>
                  <button className='flex gap-x-2 items-center rounded bg-verovian-purple px-[16px] py-[10px] text-verovian-light-purple text-sm transition duration-200 hover:bg-gray-600'>
                  <PlusIcon className={`h-5 w-5`} />
                  Assign new Task
                  </button>
              </Link>
          </div>
    
          {tasksSelector?.assignedTasks?.assignments?.length > 0 ?
            <>                                    
            <div className='grid lg:grid-cols-2 xl:grid-cols-3 gap-2'>  
              {tasksSelector?.assignedTasks?.assignments?.map((assignment, assignmentIndex)=>(<div key={assignmentIndex} className='w-full'>
                  <AssignedTaskCard assignment={assignment} openTask={()=>{openTask(assignment)}} />
              </div>))}
              </div>
            </>
            :
            <EmptyState emptyStateText={`No tasks assigned to this employee yet.`} />
          }
        </div>

        {activeOpenTask && userDetails().userType === 'USER' && <ModalLayout
                isOpen={taskOpen} 
                closeModal={()=>{closeTask()}} 
                actionFunction={()=>{}} 
                actionFunctionLabel='' 
                dialogTitle='Task Details'
                maxWidthClass='max-w-2xl'
            >
                <TaskAssignmentDetailsDialogContent assignment={activeOpenTask} />
            </ModalLayout>}
      </>
    )
}

export default EmployeeTasks
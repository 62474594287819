import React, { useEffect } from 'react'
import EmptyState from '../../elements/icons/EmptyState'
import { useDispatch, useSelector } from 'react-redux';
import { getReviewSchedules } from '../../../store/actions/reviewsActions';
import { userDetails } from '../../../utils';
import OwnReviewScheduleCard from '../reviews-management/OwnReviewScheduleCard';
import Preloader from '../../elements/Preloader';

const PendingReviews = () => {
    const dispatch = useDispatch()
    const reviewsSelector = useSelector(state => state.reviews)
    const user = userDetails()
    useEffect(() => {
        dispatch(getReviewSchedules(1, 2, `reviewer=${user.employeeProfile || ''}&submitted=false`))
        return () => {
            
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);
    return (
        <div>
            <div className='w-full p-[10px] rounded bg-gray-200 mt-[20px] mb-[10px]'>
                <h3 className='text-verovian-purple text-[16px] font-[500]'>Pending Reviews</h3>
            </div>
            <div className='w-full bg-white p-[20px]'>
                {reviewsSelector.loadingReviewSchedules ? 
                    <Preloader />
                    :
                    <>
                        {reviewsSelector.reviewSchedules.schedules.length > 0 ? reviewsSelector.reviewSchedules.schedules.map((schedule, scheduleIndex) => (
                            <div key={scheduleIndex} className='w-full mb-[20px]'>
                                <OwnReviewScheduleCard schedule={schedule} />
                            </div>
                        ))
                        :
                            <EmptyState emptyStateText={`No reviews assigned to you yet`} />
                        }

                    </>
                }

            </div>
        </div>
    )
}

export default PendingReviews
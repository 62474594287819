import React, { useEffect, useState } from 'react'
// import DataTable from '../../../components/elements/DataTable'
import PlusIcon from '../../../components/elements/icons/PlusIcon'
// import ListIcon from '../../../components/elements/icons/ListIcon'
import UserLayout from '../../../components/layout/UserLayout'
// import { debounce, unSlugify } from '../../../utils'
// import TaskExpansion from '../../../components/partials/tasks/TaskExpansion'
// import Filters from '../../../components/elements/Filters'
// import CustomerSnippet from '../../../components/elements/CustomerSnippet'
// import OrderStatus from '../../../components/elements/OrderStatus'
// import GridIcon from '../../../components/elements/GridIcon'
import TaskCard from '../../../components/partials/tasks/TaskCard'
import EmptyState from '../../../components/elements/icons/EmptyState'
import ModalLayout from '../../../components/layout/ModalLayout'
import NewTask from '../../../components/partials/tasks/NewTask'
import { useDispatch, useSelector } from 'react-redux'
import { clearCreatedTask, fetchTasks } from '../../../store/actions/tasksActions'
import { SET_SUCCESS_MESSAGE } from '../../../store/types'
import TaskDetails from '../../../components/partials/tasks/TaskDetails'
import { getTrainings } from '../../../store/actions/trainingsActions'
import { getReviewForms } from '../../../store/actions/reviewsActions'

const Tasks = () => {
    const tasksSelector = useSelector(state => state.tasks)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchTasks())
        dispatch(getTrainings(0, 0))
        dispatch(getReviewForms(0, 0))
        
        if(tasksSelector.createdTask !== null){
            dispatch(clearCreatedTask())
            dispatch({
                type: SET_SUCCESS_MESSAGE,
                payload: {
					successMessage: "New task created successfully"
                }
            })
            setAddingTask(false)
        }
        // if(tasksSelector.updatedTask !== null){
        //     dispatch(clearUpdatedTask())
            // dispatch({
            //     type: SET_SUCCESS_MESSAGE,
            //     payload: {
			// 		successMessage: "New task created successfully"
            //     }
            // })
            // setAddingTask(false)
        // }
        return () => {
            
        };
    }, [
        dispatch, 
        tasksSelector.createdTask, 
        // tasksSelector.updatedTask
    ]);
    
    // const filters = [
    //     // {
    //     //     field: 'Custodian',
    //     //     value: 'custodian',
    //     //     operators: [{
    //     //         name: 'is',
    //     //         value: 'is',
    //     //         selected: true
    //     //     }],
    //     //     termOptions: members,
    //     //     type: 'select',
    //     //     selectedTerm: ''
    //     // },
    //     {
    //         field: 'Status',
    //         value: 'currency',
    //         operators: [{
    //             name: 'is equal to',
    //             value: 'is',
    //             selected: true
    //         }],
    //         termOptions: ['BACKLOG', 'IN_PROGRESS', 'IN_REVIEW', 'DONE'],
    //         type: 'select',
    //         selectedTerm: ''
    //     },
    //     {
    //         field: 'Status',
    //         value: 'status',
    //         operators: [{
    //             name: 'is equal to',
    //             value: 'is',
    //             selected: true
    //         }],
    //         termOptions: ['FROZEN', 'RETIRED', 'ACTIVE'],
    //         type: 'select',
    //         selectedTerm: ''
    //     },
    //     {
    //         field: 'Assignee',
    //         value: 'assignee',
    //         operators: [{
    //             name: 'is equal to',
    //             value: 'is',
    //             selected: true
    //         }],
    //         type: 'search',
    //         termOptions: [],
    //         selectedTerm: ''
    //     },
    //     {
    //         field: 'Date Created',
    //         value: 'createdAt',
    //         operators: [
    //             {
    //                 name: 'is before',
    //                 value: 'gt',
    //                 selected: true
    //             },
    //             {
    //                 name: 'is after',
    //                 value: 'lt',
    //                 selected: false
    //             },
    //         ],
    //         type: 'date',
    //         termOptions: [],
    //         selectedTerm: ''
    //     },
    // ]
    
    // const columnWidths = {
    //     id: "w-full lg:w-1/12",
    //     assignee: "w-full lg:w-3/12",
    //     project: "w-full lg:w-2/12",
    //     subtasks: "w-full lg:w-1/12",
    //     status: "w-full lg:w-1/12",
    //     dateCreated: "w-full lg:w-2/12",
    //     dueDate: "w-full lg:w-2/12",
    // }
    
    // const cleanupData = (dataSet) => {
    //     const data = []
    
    //     dataSet.forEach((item, itemIndex) => {
    //     data.push(
    //         {
    //             id: item.id,
    //             assignee: <CustomerSnippet showIcon={true} customerName={item.assignee} customerPhone="Headed by James Cameron" />,
    //             project: item.project,
    //             subtasks: `${item.subtasks.length} subtasks`,
    //             status: <OrderStatus status={item.status} />,
    //             dateCreated: item.dateCreated,
    //             dueDate: item.dueDate,
    //         },
    //     )
    //     })
    
    //     return data
    // }
    
    // const [rowOpen, setRowOpen] = useState(null)
    
    // const toggleRowOpen = (rowIndex) => {
    //     if(rowOpen === null) {
    //         setRowOpen(rowIndex)
    //     } else {
    //         setRowOpen(null)
    //     }
    // } 
    
    // const tableOptions = {
    //     selectable: false,
    //     expandable: true,
    //     clickableRows: true,
    //     rowAction: (value)=>{toggleRowOpen(value)}
    // }
    
    // const initialFilters = [
    //     {
    //       field: 'description',
    //       operator: 'like',
    //       term: ''
    //     },
    //     {
    //       field: 'name',
    //       operator: 'like',
    //       term: ''
    //     },
    // ]
    
    // const [searchFilter, setSearchFilter] = useState(initialFilters)
    
    // const captureFilter = (filter) => {
    //     let tempFilters = [...searchFilter] 
    //     tempFilters.push(filter)     
    //     setSearchFilter(tempFilters)
    // }

    // const [listView] = useState(false)
    const [taskOpen, setTaskOpen] = useState(false);
    const [activeOpenTask, setActiveOpenTask] = useState(null);

    const [addingTask, setAddingTask] = useState(false);

    const openTask = (task) => {
        setActiveOpenTask(task)
        setTimeout(() => {
            setTaskOpen(true)
        }, 50);
    }

    const closeTask = () => {
        setTaskOpen(false)
        setTimeout(() => {
            setActiveOpenTask(null)
        }, 50);
    }

    // const columns = [
    //     {
    //         title: "backlog"
    //     },
    //     {
    //         title: "todo"
    //     },
    //     {
    //         title: "in_progress"
    //     },
    //     {
    //         title: "blocked"
    //     },
    //     {
    //         title: "cancelled"
    //     },
    //     {
    //         title: "done"
    //     },
    // ]

    // const startDrag = (e) => {
    //     console.log('dragging...')
    //     e.dataTransfer.setData('text/plain', e.target.id);
    //     setTimeout(() => {
    //         e.target.classList.add('hidden');
    //         e.target.classList.add('cursor-grabbing');
    //     }, 0);
    // }

    // const dragEnter = (e) => {
    //     console.log('dragging into...')
    //     e.preventDefault();
    //     setTimeout(() => {
    //         // e.target.classList.add('border-dashed');
    //         e.target.classList.add('border-purple-400');
    //     }, 0);
    // }

    // const dragOver = (e) => {
    //     console.log('dragging over...')
    //     e.preventDefault();
    //     setTimeout(() => {
    //         // e.target.classList.add('border-dashed');
    //         e.target.classList.add('border-purple-400');
    //     }, 0);
    // }

    // const dragLeave = (e) => {
    //     console.log('dragging out of...')
    //     e.preventDefault();
    //     setTimeout(() => {
    //         // e.target.classList.remove('border-dashed');
    //         e.target.classList.remove('border-purple-400');
    //     }, 0);
    // }

    // const drop = (e, status) => {
    //     e.preventDefault();
        
    //     // Remove any border class
    //     e.target.classList.remove('border-purple-400');
    
    //     // Get the draggable element's id
    //     const id = e.dataTransfer.getData('text/plain');
    //     const draggable = document.getElementById(id);
    
    //     // Ensure the draggable exists and is not already in the target
    //     if (!draggable || e.target === draggable || draggable.parentNode === e.target) {
    //         console.error('Draggable element not found or already in the target');
    //         return;
    //     }
    
    //     // Ensure that the drop target is the intended container
    //     if (!e.target.classList.contains('drop-zone')) {
    //         console.error('Not a valid drop target');
    //         return;
    //     }
    
    //     // Append the draggable to the drop target
    //     e.target.appendChild(draggable);
    
    //     // Remove hidden class to display it again
    //     draggable.classList.remove('hidden');
    
    //     // Update the task status
    //     // setTimeout(() => {
    //     //     dispatch(updateTask(id, { status }));
    //     // }, 200);

    //     debounce(dispatch(updateTask(id, { status })))
    // };
    
    

    // const [activeStatus, setActiveStatus] = useState('');
    const openNewTask = () => {

        setTimeout(() => {
            setAddingTask(true)
        }, 50);
    }

    const closeNewTask = () => {
        setAddingTask(false)
    }

    return (
        <>
            <UserLayout pageTitle={`Task Management`}>
                <div className=''>
                    <div className='w-full lg:flex flex-row gap-x-8'>
                        <div className='w-full  mx-auto pb-6 px-0'>
                            <div className='flex justify-between items-center mt-4 mb-4'>
                                <h3 className='font-medium text-lg text-gray-400'>Tasks</h3>
                                {/* <div className='flex items-center'>
                                    <button onClick={()=>{setListView(true)}} className={`border p-2 border-gray-800 flex items-center gap-x-2 text-sm rounded-l ${listView ? 'bg-gray-800 text-verovian-light-purple' : 'bg-transparent text-gray-800'}`}>
                                        <ListIcon className={`w-5 h-5`} />
                                    </button>
                                    <button onClick={()=>{setListView(false)}} className={`border border-l-0 p-2 border-gray-800 flex items-center gap-x-2 text-sm rounded-r ${!listView ? 'bg-gray-800 text-verovian-light-purple' : 'bg-transparent text-gray-800'}`}>
                                        <GridIcon className={`w-5 h-5`} />
                                    </button>
                                </div> */}
                                <button onClick={()=>{openNewTask('')}} className='flex gap-x-2 items-center rounded bg-verovian-purple px-3 py-3 text-verovian-light-purple text-sm transition duration-200 hover:bg-gray-600'>
                                    <PlusIcon className={`h-5 w-5`} />
                                    Create a new task
                                </button>
                            </div>

                                <>
                                    {/* <div className='w-full pt-5 flex items-center justify-between'>
                                        <div className='w-10/12'>
                                            <Filters filterLabel="Filter Tasks" filterOptions={filters} returnSelected={(filter)=>{captureFilter(filter)}} resetFilters={()=>{setSearchFilter(initialFilters)}} />
                                        </div>
                                        <div className='2/12'>
                                            <button onClick={()=>{openNewTask('')}} className='flex gap-x-2 items-center rounded bg-verovian-purple px-3 py-3 text-verovian-light-purple text-sm transition duration-200 hover:bg-gray-600'>
                                                <PlusIcon className={`h-5 w-5`} />
                                                Create a new task
                                            </button>
                                        </div>
                                    </div> */}

                                    <div className='w-full'>
                                        {tasksSelector.tasks?.tasks?.length === 0 ? 
                                            <EmptyState emptyStateText={`No tasks yet. Use the 'create a new task' button to create a new task`} />
                                        :
                                        <div className='grid lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4'>
                                            {tasksSelector?.tasks?.tasks?.map((task, taskIndex)=>(<div key={taskIndex} className='w-full'>
                                                <TaskCard task={task} openTask={()=>{openTask(task)}} />
                                            </div>))}
                                        </div>
                                        }
                                    </div>
                                </>


                        </div>
                    </div>

                </div>
            </UserLayout>

            {activeOpenTask && <ModalLayout
                isOpen={taskOpen} 
                closeModal={()=>{closeTask()}} 
                actionFunction={()=>{}} 
                actionFunctionLabel='Admit Guest' 
                dialogTitle='Task Details'
                maxWidthClass='max-w-2xl'
            >
                <TaskDetails task={activeOpenTask} />
            </ModalLayout>}

            <ModalLayout
                isOpen={addingTask} 
                closeModal={()=>{closeNewTask()}} 
                actionFunction={()=>{}} 
                actionFunctionLabel='Create task' 
                dialogTitle='Create a new task'
                maxWidthClass='max-w-2xl'
            >
                <NewTask />
            </ModalLayout>
        </>
    )
}


export default Tasks
import React, { useEffect, useState } from 'react'
// import DataTable from '../../../components/elements/DataTable'
import PlusIcon from '../../../components/elements/icons/PlusIcon'
// import ListIcon from '../../../components/elements/icons/ListIcon'
import UserLayout from '../../../components/layout/UserLayout'
// import { debounce, unSlugify } from '../../../utils'
// import TaskExpansion from '../../../components/partials/tasks/TaskExpansion'
// import Filters from '../../../components/elements/Filters'
// import CustomerSnippet from '../../../components/elements/CustomerSnippet'
// import OrderStatus from '../../../components/elements/OrderStatus'
// import GridIcon from '../../../components/elements/GridIcon'
import TaskCard from '../../../components/partials/tasks/TaskCard'
import EmptyState from '../../../components/elements/icons/EmptyState'
import ModalLayout from '../../../components/layout/ModalLayout'
import NewTask from '../../../components/partials/tasks/NewTask'
import { useDispatch, useSelector } from 'react-redux'
import { clearCreatedTask, clearUpdatedTask, fetchEmployeeTasks, fetchTasks } from '../../../store/actions/tasksActions'
import { SET_SUCCESS_MESSAGE } from '../../../store/types'
import TaskDetails from '../../../components/partials/tasks/TaskDetails'
import { getTrainings } from '../../../store/actions/trainingsActions'
import { getReviewForms } from '../../../store/actions/reviewsActions'
import { hasPermission, userDetails } from '../../../utils'
import AssignedTaskCard from '../../../components/partials/tasks/AssignedTaskCard'
import TaskAssignmentDetailsDialogContent from '../../../components/partials/tasks/TaskAssignmentDetailsDialogContent'
import Preloader from '../../../components/elements/Preloader'

const Tasks = () => {
    const tasksSelector = useSelector(state => state.tasks)
    const dispatch = useDispatch()

    useEffect(() => {
        
        dispatch(fetchEmployeeTasks())

        if(userDetails().userType !== 'USER') {
            setActiveTab('tasks')
            dispatch(fetchTasks())
        }

        dispatch(getTrainings(0, 0))
        dispatch(getReviewForms(0, 0))
        
        if(tasksSelector.createdTask !== null){
            dispatch(clearCreatedTask())
            dispatch({
                type: SET_SUCCESS_MESSAGE,
                payload: {
					successMessage: "New task created successfully"
                }
            })
            setAddingTask(false)
        }
        if(tasksSelector.updatedTask !== null){
            dispatch(clearUpdatedTask())
            dispatch({
                type: SET_SUCCESS_MESSAGE,
                payload: {
					successMessage: "Task updated successfully"
                }
            })
            setTaskOpen(false)
            setTimeout(() => {
                setActiveOpenTask(null)
            }, 50)
        }
        return () => {
            
        };
    }, [
        dispatch, 
        tasksSelector.createdTask, 
        tasksSelector.updatedTask, 
    ]);
    
    const [taskOpen, setTaskOpen] = useState(false);
    const [activeOpenTask, setActiveOpenTask] = useState(null);

    const [addingTask, setAddingTask] = useState(false);

    const openTask = (task) => {
        setActiveOpenTask(task)
        setTimeout(() => {
            setTaskOpen(true)
        }, 50);
    }

    const closeTask = () => {
        setTaskOpen(false)
        setTimeout(() => {
            setActiveOpenTask(null)
        }, 50);
    }
    
    // const [activeStatus, setActiveStatus] = useState('');
    const openNewTask = () => {

        setTimeout(() => {
            setAddingTask(true)
        }, 50);
    }

    const closeNewTask = () => {
        setAddingTask(false)
    }

    const [activeTab, setActiveTab] = useState('assigned');

    return (
        <>
            <UserLayout pageTitle={`Task Management`}>
            {tasksSelector.loadingTasks ? 

                <Preloader preloadingText={`loading tasks`} />
                :
                <div className='px-12'>
                    <div className='w-full lg:flex flex-row gap-x-8'>
                        <div className='w-full mx-auto pb-6 px-0'>
                            <div className='flex justify-between items-center mt-4 mb-4'>
                                <h3 className='font-medium text-lg text-gray-400'>Tasks</h3>
                                {hasPermission(['*', 'tasks.*', 'tasks.create']) && <button onClick={()=>{openNewTask('')}} className='flex gap-x-2 items-center rounded bg-verovian-purple px-3 py-3 text-verovian-light-purple text-sm transition duration-200 hover:bg-gray-600'>
                                    <PlusIcon className={`h-5 w-5`} />
                                    Create a new task
                                </button>}
                            </div>

                            {userDetails().userType !== 'USER' && 
                                <div className='flex items-center gap-x-[5px]'>
                                    <button onClick={()=>{setActiveTab('tasks')}} className={`text-xs font-[500] text-gray-600 rounded px-[16px] py-[10px] ${activeTab === 'tasks' ? 'bg-gradient-to-b from-gray-100 via-transparent to-transparent' : 'bg-gray-200'}`}>
                                        All Tasks
                                    </button>
                                    <button onClick={()=>{setActiveTab('assigned')}} className={`text-xs font-[500] text-gray-600 rounded px-[16px] py-[10px] ${activeTab === 'assigned' ? 'bg-gradient-to-b from-gray-100 via-transparent to-transparent' : 'bg-gray-200'}`}>
                                        Tasks Assigned to You
                                    </button>
                                </div>
                            }
                            <>
                                {activeTab === 'tasks' && <div className='w-full mt-[40px]'>
                                    {(tasksSelector?.tasks?.tasks?.length === 0) ? 
                                        <EmptyState 
                                            emptyStateText={`No tasks yet. Use the 'create a new task' button to create a new task`} 
                                        />
                                    :
                                    <div className='grid lg:grid-cols-2 xl:grid-cols-3 gap-2'>
                                        {tasksSelector?.tasks?.tasks?.map((task, taskIndex)=>(<div key={taskIndex} className='w-full'>
                                            <TaskCard task={task} openTask={()=>{openTask(task)}} />
                                        </div>))}
                                    </div>
                                    }
                                </div>}
                                {activeTab === 'assigned' && <div className='w-full mt-[40px]'>
                                    {(tasksSelector?.assignedTasks?.assignments?.length === 0) ? 
                                        <EmptyState 
                                            emptyStateText={`There are no tasks assigned to you at the moment.' `} 
                                        />
                                    :
                                    <>
                                        <p className='text-sm mt-[15px] mb-[20px]'>Below are tasks assigned to you.</p>
                                        <div className='grid lg:grid-cols-2 xl:grid-cols-3 gap-2'>
                                            
                                            {tasksSelector?.assignedTasks?.assignments?.map((assignment, assignmentIndex)=>(<div key={assignmentIndex} className='w-full'>
                                                <AssignedTaskCard assignment={assignment} openTask={()=>{openTask(assignment)}} />
                                            </div>))}
                                        </div>
                                    </>
                                    }
                                </div>}
                            </>
                        </div>
                    </div>

                </div>}
            </UserLayout>

            {activeOpenTask && userDetails().userType !== 'USER' && <ModalLayout
                isOpen={taskOpen} 
                closeModal={()=>{closeTask()}} 
                actionFunction={()=>{}} 
                actionFunctionLabel='' 
                dialogTitle='Task Details'
                maxWidthClass='max-w-2xl'
            >
                <TaskDetails task={activeOpenTask} />
            </ModalLayout>}

            {activeOpenTask && userDetails().userType === 'USER' && <ModalLayout
                isOpen={taskOpen} 
                closeModal={()=>{closeTask()}} 
                actionFunction={()=>{}} 
                actionFunctionLabel='' 
                dialogTitle='Task Details'
                maxWidthClass='max-w-2xl'
            >
                <TaskAssignmentDetailsDialogContent assignment={activeOpenTask} />
            </ModalLayout>}

            <ModalLayout
                isOpen={addingTask} 
                closeModal={()=>{closeNewTask()}} 
                actionFunction={()=>{}} 
                actionFunctionLabel='Create task' 
                dialogTitle='Create a new task'
                maxWidthClass='max-w-2xl'
            >
                <NewTask />
            </ModalLayout>
        </>
    )
}


export default Tasks
import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { ERROR, SET_SUCCESS_MESSAGE } from '../../store/types';
import Logo from '../../assets/img/ss-tt.png'
import TextField from '../../components/elements/form/TextField';
import FormButton from '../../components/elements/form/FormButton';
import FileUpload from '../../components/elements/form/FileUpload';
import axios from 'axios';
import InlinePreloader from '../../components/elements/InlinePreloader';
import Countdown from '../../components/elements/Countdown';

const CreateNewUser = () => {
    const [validationErrors, setValidationErrors] = useState({});
    const dispatch = useDispatch()

    const handleUpload = async (file) => {
        console.log(file)

        var formData = new FormData()
        formData.append('file', file.file )
        const headers = new Headers();

        try {
            const doUpload = await fetch(`${process.env.REACT_APP_API_URL}/files/new`, {
                method: "POST",
                headers,
                body: formData,
            });
            const response = await doUpload.json();

            return response
        } catch (error) {
            dispatch({
                type: ERROR,
                error,
            });
        }
    }

    const [userPayload, setUserPayload] = useState({});
    const [organizationPayload, setOrganizationPayload] = useState({});
    const [showPasswordLengthWarning, setShowPasswordLengthWarning] = useState(false);
    const [showPasswordMatchWarning, setShowPasswordMatchWarning] = useState(false);

    const validateForm = () => {
        let errors = {}

        if (!userPayload.firstName || userPayload.firstName === '') {
            errors.firstName = true
        }

        if (!userPayload.lastName || userPayload.lastName === '') {
            errors.lastName = true
        }

        if (!userPayload.email || userPayload.email === '') {
            errors.email = true
        }

        if (!userPayload.phone || userPayload.phone === '') {
            errors.phone = true
        }

        if (!userPayload.password || userPayload.password === '') {
            errors.password = true
        }

		if (userPayload.password && userPayload.password.length < 8) {
			errors.password = true
			setShowPasswordLengthWarning(true)
		}

		if (userPayload.password !== userPayload.password2) {
			errors.password = true
			setShowPasswordMatchWarning(true)
		}

        if (!organizationPayload.name || userPayload.name === '') {
            errors.orgName = true
        }

        setValidationErrors(errors)
        return errors
    }

    const [signupSuccessful, setSignupSuccessful] = useState(false);

    const [logo, setLogo] = useState(null);
    const [processing, setProcessing] = useState(false);
    const createUser = async () => {
        try {
            if (Object.values(validateForm()).includes(true)) {
                dispatch({
                    type: ERROR,
                    error: {response: {data: {
                        message: 'Please check the highlighted fields'
                    }}}
                });
                return
            }
            setProcessing(true)
            const payload = {
                user: {
                    ...userPayload, 
                    ...{
                        userType: 'USER',
                        accountPermissions: ['*']
                    }},
                organization: {...organizationPayload}
            }

            if(logo) {
                const uploaded = await handleUpload(logo)
                payload.organization.logo = uploaded.file
            }

            await axios.post(`${process.env.REACT_APP_API_URL}/auth/signup`, payload)
            setSignupSuccessful(true)

            dispatch({
                type: SET_SUCCESS_MESSAGE,
                payload: {
                    successMessage: 'Onboarding is complete. You can now log in to the user area.'
                }
            })
            setProcessing(false)
            
        } catch (error) {
            dispatch({
                type: ERROR,
                error: error
            })
            setProcessing(false)
            
        }
    }

    const [counted, setCounted] = useState(false);

    const resendConfirmationEmail = async () => {
        const headers = {
            'Content-Type': 'application/json'
        }

        setProcessing(true)

        const requestPayload = {
            email: userPayload.email
        }

        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/auth/email-confirmation/resend`, requestPayload, { headers })            

            setProcessing(false)
            dispatch({
                type: SET_SUCCESS_MESSAGE,
                payload: {successMessage: 'Email confirmation resent successfully!'}
            })
        } catch (error) {
            console.log(error)
            dispatch({
                type: ERROR,
                error
            })
            setProcessing(false)
        }
    }

    return (
        <div className="w-full flex items-start justify-center h-screen">
            <div className="w-10/12 xl:w-8/12 2xl:w-4/12 p-8 rounded border mt-[50px]">
                <span className="flex items-center gap-x-2">
                    <Link to="/" className='w-max inline'>
                        <img src={Logo} width={100} alt="logo" />
                        {/* <p className="tracking-[0.2em] font-medium mt-[10px]">HRMS</p> */}
                    </Link>
                </span>

                {signupSuccessful && <div className='w-full'>
                    <h3 className='font-montserrat font-medium mb-2 mt-6'>Welcome Aboard!</h3>
                    
                    <p className='mt-1 text-gray-500 text-sm'>Your account has been created successfully. Please check your email <span className='font-[550] text-gray-700'>{userPayload.email}</span> (spam too) for an email from us and follow the instructions in the email to continue.</p>

                    <div className=''>
                        {counted 
                            ? 
                                <button 
                                    className='bg-verovian-purple p-3 rounded-md mt-3 font-medium w-8/12 text-white transition duration-200 hover:bg-opacity-20 text-sm mx-auto flex items-center justify-center' 
                                    onClick={()=>{resendConfirmationEmail()}}
                                >
                                    {processing ? <InlinePreloader />  : 'Resend confirmation email'}
                                </button>
                            :
                            <>
                                <p className='py-2 mt-2 text-xs'>Didn&apos;t get the link? please wait <Countdown seconds={60} className='inline text-red-800 font-medium' countdownComplete={()=>{setCounted(true)}} /> seconds
                                </p>
                            </>
                        }
                    </div>
                </div>}

                {!signupSuccessful && <>
                    <p className='mt-1 text-gray-500 text-sm'>Please provide your account details and details of your organization to below to get started</p>
                    <p className='text-gray-400 text-xs mt-1'>Already have an account? <Link to="/" className='text-blue-500 font-medium'>Click here to log in.</Link></p>

                    <h3 className='font-montserrat text-sm font-medium mt-4'>Your Account Details </h3>
                    <p className='mt-1 text-gray-600 text-xs'>Personal Bio-data and log in information</p>

                    <div className='mt-2 w-full'>
                        <TextField
                            inputLabel="First name" 
                            inputPlaceholder="Your first name" 
                            fieldId="first-name" 
                            inputType="email" 
                            hasError={validationErrors.firstName} 
                            returnFieldValue={(value)=>{setUserPayload({...userPayload, ...{firstName: value}})}}
                        />
                    </div>

                    <div className='mt-1 w-full'>
                        <TextField
                            inputLabel="Last name" 
                            inputPlaceholder="Your last name" 
                            fieldId="last-name" 
                            inputType="email" 
                            hasError={validationErrors.lastName} 
                            returnFieldValue={(value)=>{setUserPayload({...userPayload, ...{lastName: value}})}}
                        />
                    </div>

                    <div className='mt-1 w-full'>
                        <TextField
                            inputLabel="Phone number" 
                            inputPlaceholder="Your phone number" 
                            fieldId="phone" 
                            inputType="email" 
                            hasError={validationErrors.phone} 
                            returnFieldValue={(value)=>{setUserPayload({...userPayload, ...{phone: value}})}}
                        />
                    </div>
                    <div className='mt-1 w-full'>
                        <TextField
                            inputLabel="Email Address" 
                            inputPlaceholder="Your active email address" 
                            fieldId="email" 
                            inputType="email" 
                            hasError={validationErrors.email} 
                            returnFieldValue={(value)=>{setUserPayload({...userPayload, ...{email: value}})}}
                        />
                    </div>

                    <div className='mt-1 w-full'>
                        <TextField
                            inputLabel="Password" 
                            inputPlaceholder="Your password" 
                            fieldId="password" 
                            inputType="password" 
                            hasError={validationErrors.password} 
                            returnFieldValue={(value)=>{setUserPayload({...userPayload, ...{password: value}})}}
                        />
                    </div>
                    {showPasswordLengthWarning && 
                        <p className="text-red-500 text-xs">Password should not be less than 8 characters.</p>
                    }

                    <div className='mt-1 w-full'>
                        <TextField
                            inputLabel="Password" 
                            inputPlaceholder="Re-type your password" 
                            fieldId="password" 
                            inputType="password" 
                            hasError={validationErrors.password} 
                            returnFieldValue={(value)=>{setUserPayload({...userPayload, ...{password2: value}})}}
                        />
                    </div>
                    {showPasswordMatchWarning && 
                        <p className="text-red-500 text-xs">Both password fields above must match.</p>
                    }

                    <hr className='mb-5 mt-8 w-full bg-gray-200' />

                    <h3 className='font-montserrat text-sm font-medium mt-4'>Organization </h3>
                    <p className='mt-1 text-gray-600 text-xs'>Details of your Organization. Your user account will be created as part of this organization and you will be an administrator for the organization.</p>

                    <div className='mt-1 w-full'>
                        <TextField
                            inputLabel="Name" 
                            inputPlaceholder="Name of the organization" 
                            fieldId="organization-name" 
                            inputType="text" 
                            hasError={validationErrors.orgName} 
                            returnFieldValue={(value)=>{setOrganizationPayload({...organizationPayload, ...{name: value}})}}
                        />
                    </div>

                    <div className='mt-1 w-full'>
                        <FileUpload
                            hasError={false}
                            fieldLabel={`Organization logo`}
                            returnFileDetails={(details)=>{
                            setLogo(details)
                            }}
                            acceptedFormats={['jpeg', 'jpg', 'png']}
                        />
                        <label className='block mt-3 text-xs text-gray-400'>Upload a logo for your organization if available. You can skip this and do it later in your settings</label>
                    </div>


                    <div className='animate__animated animate__fadeIn mb-4 mt-8 w-full'>
                        <FormButton buttonLabel="Create your account" buttonAction={()=>{createUser()}} processing={processing} />
                    </div>
                </>}
            </div>
        </div>
    )
}

export default CreateNewUser
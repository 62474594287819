import React, { useState } from 'react'
import FormButton from '../../elements/form/FormButton';
import Checkbox from '../../elements/form/Checkbox';
import DocumentSvgIcon from '../../../assets/img/icons/document-icon.svg'

const DocumentsPicker = ({documents, selectedDocuments, saveNewList, closeModal}) => {
    const [documentsList, setDocumentsList] = useState(selectedDocuments || []);
    
    const toggleDocumentSelection = (documentToToggle) => {
        const tempList = [...documentsList]
        const index = tempList.findIndex((doc) => 
            doc.document?._id === documentToToggle._id
        )

        if(index > -1) {
            tempList.splice(index, 1)
        } else {
            tempList.push({
                order: documentsList.length,
                type: 'document', 
                document: documentToToggle
            })
        }
        // console.log(tempList)
        setDocumentsList(tempList)
    }

    const documentSelected = (documentToCheck) => {
        const index = documentsList.findIndex(document => document.document?._id === documentToCheck._id)
        return index > -1
    }

    const updateAndClose = () => {
        saveNewList(documentsList)
        closeModal()
    }

    return (
        <div>
            <div className='max-h-[500px] overflow-y-scroll scrollbar-hidden'>
                {documents?.length > 0 ? 
                    <div className='w-full'>
                        {documents?.map((document, documentIndex) => (
                        <div key={documentIndex} className={`flex items-start gap-x-2 mb-4 cursor-pointer`} onClick={(()=>{toggleDocumentSelection(document)})}>
                            <div className='w-[25px] pt-[5px]'>
                                <Checkbox 
                                    isChecked={documentSelected(document)}
                                    checkboxToggleFunction={()=>{toggleDocumentSelection(document)}}
                                    checkboxLabel=''
                                />
                            </div>
                            <div className='w-[50px] rounded-[8px]'>
                                <img src={DocumentSvgIcon} alt="" className='w-full' />
                            </div>
                            <div className='w-full'>
                                <p className='text-xs text-gray-700 font-medium'>{document.name}</p>
                                <div className='my-1'>
                                    <p className='text-xs'>{document.description.length > 100 ? document.description.slice(0, 100) + '...' : document.description}</p>
                                </div>
                                {/* <div className='flex flex-row mt-[10px]'>
                                    <span className='capitalize rounded border border-gray-400 text-gray-600 px-[5px] py-[3px] text-xs'>

                                    </span>
                                </div> */}
                            </div>
                        </div>
                        ))}
                    </div> 
                    :
                    <div className='p-5 bg-gray-100 rounded-[8px] tex-center'>
                        <p className='text-sm text-gray-700 font-[550]'>No documents found</p>
                        <p className='mt-1 text-xs text-gray-500'>Navigate to the documents page and create some documents first.</p>
                    </div>  
                    } 
                </div>
                <div className='flex flex-row-reverse mt-[20px]'>
                    <div className='w-max'>
                        <FormButton buttonLabel={`Save Selection`} buttonAction={()=>{updateAndClose()}} />
                    </div>
                </div>
        </div>
    )
}

export default DocumentsPicker
import React from 'react'
import ArrowIcon from '../../elements/icons/ArrowIcon'
import Status from '../../elements/Status'
import { transactionTimeStamp } from '../../../utils'

const AssignedTaskCard = ({assignment, openTask}) => {
  return (
    <div className='w-full p-[20px] bg-white relative border border-verovian-light-purple rounded-[8px] shadow-lg'>
        {assignment.onboardingPackage && 
            <span className='absolute right-[20px] top-[15px] text-xs px-[8px] py-[5px] rounded bg-gray-100 text-gray-400'>Onboarding Task</span>
        }

        <p className='text-sm font-550 mt-[15px] text-black font-[500]'>{assignment.task.title}</p>
        <p className='text-xs mt-[10px] font400 truncate'>{assignment.task.description}</p>

        <div className='flex items-center justify-between mt-[20px]'>
            {assignment.task.taskItem.itemType === 'training' && <div>
                <div className={`flex items-start gap-x-2 mb-4 cursor-pointer`}>
                    <div className='w-[75px] h-[60px] rounded-[8px] bg-gray-100' style={{
                        backgroundImage: `url(${assignment.task.itemDetails?.coverImage})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat'
                    }}  />
                    <div className='w-full'>
                        <p className='text-sm text-gray-700 font-medium'>{assignment.task.itemDetails?.title}</p>
                        <div className='my-1'>
                            <p className='text-xs'>{assignment.task.itemDetails?.description?.length > 150 ? assignment.task.itemDetails?.description?.slice(0, 150) + '...' : assignment.task.itemDetails?.description}</p>
                        </div>
                    </div>
                </div>
            </div>}
            {/* {assignment.task.taskItem.itemType === 'document' && <div>
                <div className={`flex items-start gap-x-2 mb-4 cursor-pointer`}>
                    <div className='w-[50px] rounded-[8px]'>
                        <img src={DocumentSvgIcon} alt="" className='w-full' />
                    </div>
                    <div className='w-full'>
                        <p className='text-sm text-gray-700 font-medium'>{assignment.task.itemDetails.name}</p>
                        <div className='my-1'>
                            <p className='text-xs'>{item.item.description.length > 150 ? item.item.description.slice(0, 150) + '...' : item.item.description}</p>
                        </div>
                        <div className='flex flex-row-reverse mt-[10px]'>
                            <Status status={`complete`} />
                        </div>
                    </div>
                </div>
            </div>}
            {assignment.task.taskItem.itemType === 'task' && <div>
                <div className={`flex items-start gap-x-2 mb-4 cursor-pointer`}>
                    <div className='w-full'>
                        <p className='text-sm text-gray-700 font-medium'>{item.item.title}</p>
                        <div className='my-1'>
                            <p className='text-xs'>{item.item.description.length > 150 ? item.item.description.slice(0, 150) + '...' : item.item.description}</p>
                        </div>
                        <div className='flex flex-row-reverse mt-[10px]'>
                            <Status status={`complete`} />
                        </div>
                    </div>
                </div>
            </div>} */}
            {assignment.task.taskItem.itemType === 'review' && <div>
                <div className={`flex items-start gap-x-2 mb-4 cursor-pointer`}>
                    <div className='w-full'>
                        <p className='text-sm text-gray-700 font-medium'>{assignment.task.itemDetails.name}</p>
                        <div className='my-1'>
                            <p className='text-xs'>{assignment.task.itemDetails.description.length > 150 ? assignment.task.itemDetails.description.slice(0, 150) + '...' : assignment.task.itemDetails.description}</p>
                        </div>
                        <div className='flex flex-row-reverse mt-[10px]'>
                            <Status status={`pending`} />
                        </div>
                    </div>
                </div>
            </div>}
        </div>

        <div className='flex flex-row-reverse mt-[10px] justify-between'>
            <p className='text-xs'>Due Date: {transactionTimeStamp(assignment.dueDate).date}</p>
            <Status status={assignment.status} />
        </div>
        <button onClick={()=>{openTask()}} className='mt-[20px] text-xs text-gray-600 flex items-center gap-x-2 hover:text-verovian-purple duration-200 transition'>View Task <ArrowIcon className={`w-4 h-4 -rotate-90`}/></button> 
    </div>
  )
}

export default AssignedTaskCard
import React from 'react'
import { Link } from 'react-router-dom'
import ArrowIcon from '../../elements/icons/ArrowIcon'
import Status from '../../elements/Status'

const EmployeeReviewFormCard = ({form, progress}) => {
    const totalQuestions = form.sections.reduce((accumulator, current) => accumulator+current.questions.length, 0) 
    return (
      <div className='bg-white w-full p-[25px] relative'>
        {/* <button onClick={()=>{doDelete()}} className='w-[30px] h-[30px] absolute top-[10px] right-[10px] text-gray-500 hover:text-red-600 flex items-center justify-center bg-transparent hover:bg-gray-200 transition duration-200'>
          <TrashIcon className={`w-5 h-5`} />                
        </button> */}
  
        <p className='text-sm mt-[20px]'>{form.name}</p>
        <div className='flex items-center justify-between my-[10px]'>
          <p className='text-xs'>{form.sections.length} section(s), {totalQuestions} question(s) </p>
          {/* <span className='inline-block capitalize rounded border border-gray-400 text-gray-600 px-[7px] py-[5px] text-xs'>{form.frequency}</span> */}
          
        </div>
        
        <p className="text-xs">{form.description.length > 120 ? `${form.description.substr(0, 120)}...` : form.description}</p>
        
        <p className='text-xs font-montserrat mt-[10px]'><span className='font-space-grotesk text-sm'>{progress.done}</span> responses submitted out of <span className='font-space-grotesk text-sm'>{progress.done + progress.pending}</span></p>
        <p className='text-xs flex items-center gap-x-1 mt-[10px]'>Overall Sentiment: <span className='w-max'><Status status={progress.sentiment} /></span></p>

  
        <Link to={`/user/reviews-management/review-forms/detail/${form._id}`} className='text-xs text-gray-600 flex items-center gap-x-2 hover:text-verovian-purple duration-200 transition mt-3 font-[550]' >See review details & responses <ArrowIcon className={`w-4 h-4 -rotate-90`}/></Link>
      </div>
    )
}

export default EmployeeReviewFormCard
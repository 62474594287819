import axios from "axios"
import { authHeader } from "../../utils"
import { CREATE_TASK, CREATING_TASK, DELETE_TASK, DELETING_TASK, GET_ASSIGNED_TASKS, GET_TASKS, GETTING_TASKS, TASKS_ERROR, UPDATE_TASK, UPDATING_TASK } from "../types"

export const createTask = (taskPayload) => async (dispatch) => {    
    try{
        const headers = authHeader()

        dispatch({
            type: CREATING_TASK,
            payload: true
        })
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/tasks`, taskPayload, { headers })
        
        dispatch({
            type: CREATE_TASK,
            payload: response.data.data
        })
        
    }
    catch(error){
        // console.log(error)
        dispatch({
            type: TASKS_ERROR,
            // payload: error.response.data
            error
        })
    }
}

export const updateTask = (id, taskPayload) => async (dispatch) => {    
    try{
        const headers = authHeader()

        dispatch({
            type: UPDATING_TASK,
            payload: true
        })
        const response = await axios.patch(`${process.env.REACT_APP_API_URL}/tasks/${id}`, taskPayload, { headers })
        
        dispatch({
            type: UPDATE_TASK,
            payload: response.data.data
        })
        
    }
    catch(error){
        // console.log(error)
        dispatch({
            type: TASKS_ERROR,
            // payload: error.response.data
            error
        })
    }
}

export const deleteTask = (id) => async (dispatch) => {    
    try{
        const headers = authHeader()

        dispatch({
            type: DELETING_TASK,
            payload: true
        })
        const response = await axios.delete(`${process.env.REACT_APP_API_URL}/tasks/${id}`, { headers })
        
        dispatch({
            type: DELETE_TASK,
            payload: response.data.data
        })
        
    }
    catch(error){
        // console.log(error)
        dispatch({
            type: TASKS_ERROR,
            // payload: error.response.data
            error
        })
    }
}

export const fetchEmployeeTasks = (pagination, filters, sort, action) => async dispatch => {    
    try{
        const headers = authHeader()
        let requestUrl = 'task-assignments?expand=assignee,task&perPage=0&page=0'
        // if(userPermissions().includes('wallets.list.self') && !userPermissions().includes('wallets.*')) {
        //     requestUrl = 'wallets/self'
        // }

        dispatch( {
            type: GETTING_TASKS,
            payload: true
        })

        // let appliedFilters =''
        // if (filters && filters!==null && filters.length > 0) {
        //     appliedFilters = parseFilters(filters, action, 'WALLETS')
        // }


        const response = await axios.get(`${process.env.REACT_APP_API_URL}/${requestUrl}`, { headers })

        dispatch( {
            type: GET_ASSIGNED_TASKS,
            payload: response.data.data
        })
        
    }
    catch(error){
        dispatch( {
            type: TASKS_ERROR,
            // payload: error.response.data,
            error
        })
    }
}

export const updateEmployeeTaskAssignment = (assignmentId, payload) => async dispatch => {    
    try{
        const headers = authHeader()
        let requestUrl = `task-assignments/update/${assignmentId}`

        dispatch( {
            type: UPDATING_TASK,
            payload: true
        })

        const response = await axios.patch(`${process.env.REACT_APP_API_URL}/${requestUrl}`, payload, { headers })

        dispatch( {
            type: UPDATE_TASK,
            payload: response.data.data
        })
        
    }
    catch(error){
        dispatch( {
            type: TASKS_ERROR,
            error
        })
    }
}

export const fetchTasks = (pagination, filters, sort, action) => async dispatch => {    
    try{
        const headers = authHeader()
        let requestUrl = 'tasks?expand=assignee&perPage=0&page=0'
        // if(userPermissions().includes('wallets.list.self') && !userPermissions().includes('wallets.*')) {
        //     requestUrl = 'wallets/self'
        // }

        dispatch( {
            type: GETTING_TASKS,
            payload: true
        })

        // let appliedFilters =''
        // if (filters && filters!==null && filters.length > 0) {
        //     appliedFilters = parseFilters(filters, action, 'WALLETS')
        // }


        const response = await axios.get(`${process.env.REACT_APP_API_URL}/${requestUrl}`, { headers })

        dispatch( {
            type: GET_TASKS,
            payload: response.data.data
        })
        
    }
    catch(error){
        dispatch( {
            type: TASKS_ERROR,
            // payload: error.response.data,
            error
        })
    }
}

export const clearCreatedTask = () => async dispatch => {    
    dispatch( {
        type: CREATE_TASK,
        payload: null
    })
}

export const clearUpdatedTask = () => async dispatch => {    
    dispatch( {
        type: UPDATE_TASK,
        payload: null
    })
}

export const clearDeletedTask = () => async dispatch => {    
    dispatch( {
        type: DELETE_TASK,
        payload: null
    })
}


// export const getWalletDetails = (walletId) => async dispatch => {    
//     try{
//         const headers = authHeader()
//         dispatch({
//             type: GETTING_WALLET_DETAILS,
//             payload: true
//         })
//         const response = await axios.get(`${process.env.REACT_APP_API_URL}/wallets/${walletId}?expand=custodian`, { headers })
//         dispatch( {
//             type: GET_WALLET_DETAILS,
//             payload: response.data.data
//         })
//     }
//     catch(error){
//         dispatch( {
//             type: WALLET_DETAILS_ERROR,
//             error
//             // payload: error.response.data,
//         })
//     }
// }
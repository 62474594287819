import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Preloader from '../../elements/Preloader';
import { fetchDepartments } from '../../../store/actions/departmentActions';
import { ERROR } from '../../../store/types';
import { updateEmployee } from '../../../store/actions/employeeActions';
import CheckIconCircled from '../../elements/icons/CheckIconCircled';
import FormButton from '../../elements/form/FormButton';

const EmployeeDepartmentSelector = ({employee}) => {
    const dispatch = useDispatch()
    const employeesSelector = useSelector(state => state.employees)
    const departmentsSelector = useSelector(state => state.departments)
    const [department, setDepartment] = useState('');
    const [validationErrors, setValidationErrors] = useState({});
    useEffect(() => {
        dispatch(fetchDepartments(0, 0))
        return () => {
            
        };
    }, [dispatch]);

    const validateForm = () => {
        let errors = {}
        if (!department || department === '') {
            errors.department = true
        }

        setValidationErrors(errors)
        return errors
    }
    
    const pushUpdate = () => {
        if (Object.values(validateForm()).includes(true)) {
            dispatch({
                type: ERROR,
                error: {response: {data: {
                    message: 'Please check the highlighted fields'
                }}}
            });
            return
        }
        const payload = {department}
        dispatch(updateEmployee(employee._id, payload))
    }

    return (
        <div className='w-full'>
            {departmentsSelector.loadingDepartments ?
                <Preloader preloadingText={`Fetching departments...`} />

                :

                <div className='w-full'>
                    <p className='text-sm text-gray-500 mb-[15px]'>
                        Select one of the employees of this department below to set them as the Department Head or Supervisor
                    </p>
                    {validationErrors.hod && <p className='my-[10px] text-xs text-red-500'>Please select a HOD for this department</p>}
                    {departmentsSelector?.departments?.departments?.map((dept, deptIndex)=>(<div key={deptIndex} onClick={()=>{setDepartment(dept._id)}} className={`p-[10px] rounded-[8px] flex items-center cursor-pointer border gap-x-[10px] justify-between ${department === dept._id ? 'border-verovian-purple bg-verovian-light-purple bg-opacity-50' : 'border-transparent bg-white'}`}>
                        <div className='w-full'>
                            <h3 className='text-sm font-[550] text-black'>{dept.name}</h3>
                            <p className='mb-1 mt-1 text-xs'>Headed by: {dept.hod ? dept.hod.fullName : <span className='h-[15px] w-[100px] bg-gray-200 animate-pulse inline-block ml-2'></span>}</p>
                            <p className="text-xs">{dept.employees || 0} personnel</p>

                        </div>
                        <div className='w-[50px]'>
                            {department === dept._id && <CheckIconCircled className={`w-5 h-5 text-verovian-purple`} />} 
                        </div>
                    </div>))}
                </div>
            }

            <div className='my-8 flex flex-row-reverse items-center justify-between'>
                <div className='w-full'>
                    <FormButton 
                    buttonLabel={`Update Employee Department`} 
                    buttonAction={()=>{pushUpdate()}} 
                    processing={employeesSelector.updatingEmployee}
                    />
                </div>
            </div>
        </div>
    )
}

export default EmployeeDepartmentSelector
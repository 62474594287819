import React, { useState } from 'react'
import Checkbox from '../../elements/form/Checkbox';
import FormButton from '../../elements/form/FormButton';

const ReviewFormPicker = ({forms, selectedForms, saveNewList, closeModal}) => {
    const [formsList, setFormsList] = useState(selectedForms || []);
    
    const toggleFormSelection = (formToToggle) => {
        const tempList = [...formsList]
        const index = tempList.findIndex((form) => 
            form.form?._id === formToToggle._id
        )

        if(index > -1) {
            tempList.splice(index, 1)
        } else {
            tempList.push({
                order: formsList.length, 
                type: 'review',
                form: formToToggle
            })
        }
        // console.log(tempList)
        setFormsList(tempList)
    }

    const formSelected = (formToCheck) => {
        const index = formsList.findIndex(form => form?.form?._id === formToCheck._id)
        return index > -1
    }

    const updateAndClose = () => {
        saveNewList(formsList)
        closeModal()
    }

    return (
        <div>
            <div className='max-h-[500px] overflow-y-scroll scrollbar-hidden'>
                {forms?.length > 0 ? 
                    <div className='w-full'>
                        {forms?.map((form, formIndex) => (
                        <div key={formIndex} className={`flex items-start gap-x-2 mb-4 cursor-pointer`} onClick={(()=>{toggleFormSelection(form)})}>
                            <div className='w-[25px] pt-[5px]'>
                                <Checkbox
                                    isChecked={formSelected(form)}
                                    checkboxToggleFunction={()=>{toggleFormSelection(form)}}
                                    checkboxLabel=''
                                />
                            </div>
                            {/* <div className='w-[50px] rounded-[8px]'>
                                <img src={DocumentSvgIcon} alt="" className='w-full' />
                            </div> */}
                            <div className='w-full'>
                                <p className='text-xs text-gray-700 font-medium'>{form.name}</p>
                                <div className='my-1'>
                                    <p className='text-xs'>{form.description.length > 100 ? form.description.slice(0, 100) + '...' : form.description}</p>
                                </div>
                                {/* <div className='flex flex-row mt-[10px]'>
                                    <span className='capitalize rounded border border-gray-400 text-gray-600 px-[5px] py-[3px] text-xs'>

                                    </span>
                                </div> */}
                            </div>
                        </div>
                        ))}
                    </div> 
                    :
                    <div className='p-5 bg-gray-100 rounded-[8px] tex-center'>
                        <p className='text-sm text-gray-700 font-[550]'>No review forms found</p>
                        <p className='mt-1 text-xs text-gray-500'>Navigate to the reviews page and create some review forms first.</p>
                    </div>  
                    } 
                </div>
                <div className='flex flex-row-reverse mt-[20px]'>
                    <div className='w-max'>
                        <FormButton buttonLabel={`Save Selection`} buttonAction={()=>{updateAndClose()}} />
                    </div>
                </div>
        </div>
    )
}

export default ReviewFormPicker
import React, { useEffect, useState } from 'react'
import PlayerLayout from '../../../../components/layout/PlayerLayout'
import Preloader from '../../../../components/elements/Preloader'
import { ERROR, SET_SUCCESS_MESSAGE } from '../../../../store/types'
import axios from 'axios'
import { authHeader } from '../../../../utils'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import ReviewQuestion from '../../../../components/partials/reviews-management/ReviewQuestion'
import FormButton from '../../../../components/elements/form/FormButton'
import { clearCreatedReview, createReview } from '../../../../store/actions/reviewsActions'

const NewReview = () => {
    const {formId, scheduleId} = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const reviewsSelector = useSelector(state => state.reviews)
    const [loading, setLoading] = useState(true);
    const [reviewForm, setReviewForm] = useState({});
    const [reviewSchedule, setReviewSchedule] = useState({});
    const [responses, setResponses] = useState([]);
  
    useEffect(() => {
      const fetchReviewForm = async () => {    
        try{
          const headers = authHeader()
          let requestUrl = `reviews/review-forms/${formId}?expand=sections.questions.question`
          setLoading(true)
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/${requestUrl}`, { headers })
  
          setReviewForm(response.data.data)
            fetchReviewSchedule()
            const questions = []
            response.data.data.sections.forEach(section => {
              section.questions.forEach(question => {
                questions.push({
                  question: question.question._id,
                  responseRequired: question.responseRequired,
                  response: ''
                })
              })
            })

            setResponses(questions)
        }
        catch(error){
          dispatch( {
              type: ERROR,
              error
          })
        }
      }

      const fetchReviewSchedule = async () => {    
        try{
          const headers = authHeader()
          let requestUrl = `reviews/schedules/${scheduleId}?expand=employee`
          setLoading(true)
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/${requestUrl}`, { headers })
  
          setReviewSchedule(response.data.data)
          setLoading(false)
        }
        catch(error){
          dispatch( {
              type: ERROR,
              error
          })
        }
      }
  
      fetchReviewForm()

      if(reviewsSelector.createdReview !== null) {
        dispatch(clearCreatedReview())
        dispatch({
          type: SET_SUCCESS_MESSAGE,
          payload: {successMessage: 'Review Submitted Successfully!'}
        })
        navigate(-1)
      }
      
      return () => {
        
      };

    }, [formId, scheduleId, dispatch, reviewsSelector.createdReview, navigate]);

    const updateResponse = (questionId, response) => {
      let temp = [...responses]

      const index = temp.findIndex(res => res.question === questionId)
      console.log('question index: ', index)
      if(Array.isArray(response)) {
        responses[index].response = response
      } else {
        responses[index].response = {
          responseText: response?.label || response,
          responseId: response?._id || undefined
        }
      }
      setResponses(temp)
    }

    const [validationErrors, setValidationErrors] = useState({});

    const validateForm = () => {
      let errors = {}

      responses.forEach((response, responseIndex) => {
        if(response.responseRequired && (response.responseText || response.responseText === '')) {
          errors[`responses-${responseIndex}`] = true
        }
      })

      setValidationErrors(errors)
      return errors
    }

    const pushReview = () => {
      if (Object.values(validateForm()).includes(true)) {
        dispatch({
          type: ERROR,
          error: {response: {data: {
            message: 'Please check the highlighted fields'
          }}}
        });
        return
      }

      const payload = {
        schedule: scheduleId,
        responses
      }

      dispatch(createReview(payload))
    }

    return (
        <PlayerLayout pageTitle={`Submit Review`} >
        {loading ? <Preloader preloadingText={`Loading article`} /> :
        <div className='bg-white p-5 w-full mx-auto xl:w-10/12 mt-[25px] relative'>

            <h3 className='font-thin mb-[25px] uppercase tracking-[0.5em] text-sm'>Creating review for {reviewSchedule.employee.fullName}</h3>
            
            <div className='w-full h-[1px] bg-gray-400 mb-[25px]' />
            
            <h3 className='font-[550] mb-[10px]'>{reviewForm.name}</h3>
            <p className='text-sm mb-[10px]'>{reviewForm.description}</p>

            {reviewForm.sections.map((section, sectionIndex) => (
            <div key={sectionIndex} className='mt-[25px]'>
                <h3 className='font-[550] text-sm mb-[10px] uppercase'>{section.title}</h3>
                <p className='text-sm mb-[10px]'>{section.description}</p>

                {section.questions.map((question, questionIndex) => (
                <div key={questionIndex} className='w-full my-[15px] pb-[20px] border-b border-gray-300'>
                    <ReviewQuestion 
                      hasError={validationErrors[`responses-${questionIndex}`]} 
                      responseRequired={question.responseRequired} 
                      question={question.question} 
                      returnResponse={(response)=>{updateResponse(question.question._id, response)}} 
                    />
                </div>
                ))}
            </div>
            ))}

            <div className='mt-[50px] w-max'>
                <FormButton 
                  buttonLabel={`Submit Review for ${reviewSchedule.employee.firstName} ${reviewSchedule.employee.lastName}`} 
                  buttonAction={()=>{pushReview()}}
                />
            </div>
        </div>
        }</PlayerLayout>
    )
}

export default NewReview
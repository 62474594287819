import React, { useState } from 'react'
import Checkbox from '../../elements/form/Checkbox';
import FormButton from '../../elements/form/FormButton';

const TasksPicker = ({tasks, selectedTasks, saveNewList, closeModal}) => {
    const [tasksList, setTasksList] = useState(selectedTasks || []);
    
    const toggleTaskSelection = (taskToToggle) => {
        const tempList = [...tasksList]
        const index = tempList.findIndex((task) => 
            task.task?._id === taskToToggle._id
        )

        if(index > -1) {
            tempList.splice(index, 1)
        } else {
            tempList.push({
                order: tasksList.length, 
                type: 'task',
                task: taskToToggle
            })
        }
        // console.log(tempList)
        setTasksList(tempList)
    }

    const formSelected = (taskToCheck) => {
        const index = tasksList.findIndex(task => task.task?._id === taskToCheck._id)
        return index > -1
    }

    const updateAndClose = () => {
        saveNewList(tasksList)
        closeModal()
    }

    return (
        <div>
            <div className='max-h-[500px] overflow-y-scroll scrollbar-hidden'>
                {tasks?.length > 0 ? 
                    <div className='w-full'>
                        {tasks?.map((task, taskIndex) => (
                        <div key={taskIndex} className={`flex items-start gap-x-2 mb-4 cursor-pointer`} onClick={(()=>{toggleTaskSelection(task)})}>
                            <div className='w-[25px] pt-[5px]'>
                                <Checkbox
                                    isChecked={formSelected(task)}
                                    checkboxToggleFunction={()=>{toggleTaskSelection(task)}}
                                    checkboxLabel=''
                                />
                            </div>
                            {/* <div className='w-[50px] rounded-[8px]'>
                                <img src={DocumentSvgIcon} alt="" className='w-full' />
                            </div> */}
                            <div className='w-full'>
                                <p className='text-xs text-gray-700 font-medium'>{task.title}</p>
                                <div className='my-1'>
                                    <p className='text-xs'>{task.description.length > 100 ? task.description.slice(0, 100) + '...' : task.description}</p>
                                </div>
                                {/* <div className='flex flex-row mt-[10px]'>
                                    <span className='capitalize rounded border border-gray-400 text-gray-600 px-[5px] py-[3px] text-xs'>

                                    </span>
                                </div> */}
                            </div>
                        </div>
                        ))}
                    </div> 
                    :
                    <div className='p-5 bg-gray-100 rounded-[8px] tex-center'>
                        <p className='text-sm text-gray-700 font-[550]'>No tasks found</p>
                        <p className='mt-1 text-xs text-gray-500'>Navigate to the tasks management page and create some tasks first.</p>
                    </div>  
                    } 
                </div>
                <div className='flex flex-row-reverse mt-[20px]'>
                    <div className='w-max'>
                        <FormButton buttonLabel={`Save Selection`} buttonAction={()=>{updateAndClose()}} />
                    </div>
                </div>
        </div>
    )
}

export default TasksPicker
import React from 'react'
import UserIcon from '../../elements/icons/UserIcon'

const EmployeeProfile = ({employeeDetails}) => {
  return (
    <div className='w-full'>
        <div className='w-full flex items-start gap-x-[10px]'>
            <div className='w-[200px] h-[250px] bg-gray-100 flex items-center justify-center rounded-[8px]'>
                {(!employeeDetails.avatar || employeeDetails.avatar === '') && <UserIcon className={`w-8 h-8 text-gray-300`} />}
            </div>
        </div>
    </div>
  )
}

export default EmployeeProfile
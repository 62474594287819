import React, { useState } from 'react'
import Checkbox from '../../../elements/form/Checkbox'
import FormButton from '../../../elements/form/FormButton';
// import { unSlugify } from '../../../../utils';

const TrainingsPicker = ({trainings, selectedTrainings, saveNewList, closeModal}) => {
    const [trainingsList, setTrainingsList] = useState(selectedTrainings || []);
    
    const toggleTrainingSelection = (trainingToToggle) => {
        const tempList = [...trainingsList]
        const index = tempList.findIndex((training) => 
            training.training?._id === trainingToToggle._id
        )

        if(index > -1) {
            tempList.splice(index, 1)
        } else {
            tempList.push({
                order: trainingsList.length, 
                type: 'training',
                training: trainingToToggle
            })
        }
        // console.log(tempList)
        setTrainingsList(tempList)
    }

    const trainingSelected = (trainingToCheck) => {
        const index = trainingsList.findIndex(training => training?.training?._id === trainingToCheck._id)
        return index > -1
    }

    const updateAndClose = () => {
        saveNewList(trainingsList)
        closeModal()
    }

    return (
        <div>
            <div className='max-h-[500px] overflow-y-scroll scrollbar-hidden'>
                {trainings?.length > 0 ? 
                    <div className='w-full'>
                        {trainings?.map((training, trainingIndex) => (
                        <div key={trainingIndex} className={`flex items-start gap-x-2 mb-4 cursor-pointer`} onClick={(()=>{toggleTrainingSelection(training)})}>
                            <div className='w-[25px] pt-[5px]'>
                                <Checkbox 
                                    isChecked={trainingSelected(training)}
                                    checkboxToggleFunction={()=>{toggleTrainingSelection(training)}}
                                    checkboxLabel=''
                                />
                            </div>
                            <div className='w-[75px] h-[60px] rounded-[8px] bg-gray-100' style={{
                                backgroundImage: `url(${training.coverImage})`,
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat'
                            }}  />
                            <div className='w-full'>
                                <p className='text-xs text-gray-700 font-medium'>{training.title}</p>
                                <div className='my-1'>
                                    <p className='text-xs'>{training.description.length > 100 ? training.description.slice(0, 100) + '...' : training.description}</p>
                                </div>
                                {/* <div className='flex flex-row mt-[10px]'>
                                    <span className='capitalize rounded border border-gray-400 text-gray-600 px-[5px] py-[3px] text-xs'>

                                    </span>
                                </div> */}
                            </div>
                        </div>
                        ))}
                    </div> 
                    :
                    <div className='p-5 bg-gray-100 rounded-[8px] tex-center'>
                        <p className='text-sm text-gray-700 font-[550]'>No Trainings found</p>
                        <p className='mt-1 text-xs text-gray-500'>Navigate to the trainings page and create some trainings first.</p>
                    </div>  
                    } 
                </div>
                <div className='flex flex-row-reverse mt-[20px]'>
                    <div className='w-max'>
                        <FormButton buttonLabel={`Save Selection`} buttonAction={()=>{updateAndClose()}} />
                    </div>
                </div>
        </div>
    )
}

export default TrainingsPicker
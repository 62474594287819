import React, { useEffect, useState } from 'react'
import UserLayout from '../../../components/layout/UserLayout'
import InventoryLayout from '../../../components/layout/InventoryLayout'
import { useDispatch, useSelector } from 'react-redux'
import Preloader from '../../../components/elements/Preloader'
import PlusIcon from '../../../components/elements/icons/PlusIcon'
import { Link } from 'react-router-dom'
import { userDetails } from '../../../utils'
import Pagination from '../../../components/elements/Pagination'
import EmptyState from '../../../components/elements/icons/EmptyState'
import { getInventoryItems } from '../../../store/actions/inventoryActions'

const Inventory = () => {
  const inventorySelector = useSelector(state => state.inventory)
  const dispatch = useDispatch()
  const accountPermissions = userDetails().accountPermissions
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState();
  // const [activeFilters, setActiveFilters] = useState('');

  useEffect(() => {
    dispatch(getInventoryItems(page, perPage, ''))
    return () => {
      
    };
  }, [dispatch, page, perPage]);
  
  return (
    <UserLayout pageTitle={`Inventory`}>
      <InventoryLayout sectionTitle={`Inventory Items`}>
        <div className='w-full mx-auto'>
          <div className='w-full pt-5 flex items-center justify-between mb-4'>
              <div className='w-9/12'>
                  <h3 className='text-sm font-[500] mb-1'>Inventory Items</h3>
                  <p className='text-[13px] text-gray-500'>You can use categories to group inventory items. Create a new category by clicking on "Create new category".</p>
              </div>
              <div className='3/12'>
              {(accountPermissions?.includes('*') || accountPermissions?.includes('inventory.*') || accountPermissions?.includes('inventory.create')) && 
                <Link to={`new-item`} className='flex gap-x-2 items-center rounded bg-verovian-purple px-3 py-3 text-verovian-light-purple text-sm transition duration-200 hover:bg-gray-700'>
                  <PlusIcon className={`h-5 w-5`} />
                  Create inventory item
                </Link>}
              </div>
          </div>

          {inventorySelector?.loadingItems && inventorySelector.loadingItems === true ? 
            <Preloader preloadingText={'Loading inventory items... '} />
            : 
            <>
                {inventorySelector?.items?.items?.length > 0 ? <>
                <div className='w-full grid grid-cols-3 gap-2'>
                    {inventorySelector?.items.items.map((item, itemIndex) => ( 
                        <div key={itemIndex} className='w-full p-6 bg-white bg-opacity-50 relative'>

                        </div>
                    ))}
                </div> 

                <Pagination 
                    pagination={{
                        currentPage: page,
                        perPage: perPage,
                        totalItems: inventorySelector?.items?.total
                    }}
                    changePage={(value)=>{setPage(value)}}
                    updatePerPage={(value)=>{setPerPage(value)}}
                />
                </>
                :
                <EmptyState emptyStateText={`No inventory items created yet. Click on the "Create new item" button above to create one`} />
                }
            </>
          }
      </div>
      </InventoryLayout>
    </UserLayout>
  )
}

export default Inventory
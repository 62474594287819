import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getEmployeeReviews } from '../../../store/actions/reviewsActions';
// import { Link } from 'react-router-dom';
// import PlusIcon from '../../elements/icons/PlusIcon';
import EmployeeReviewFormCard from './EmployeeReviewFormCard';
import EmptyState from '../../elements/icons/EmptyState';

const EmployeeReviews = ({employeeDetails}) => {
  const reviewsSelector = useSelector(state => state.reviews)
  const dispatch = useDispatch()

  useEffect(() => {
      dispatch(getEmployeeReviews(employeeDetails._id, 0, 0))
      return () => {
          
      };
  }, [dispatch, employeeDetails._id]);

  const reviewProgresses = [
    {done: 10, pending: 2, sentiment: 'positive'}, 
    {done: 24, pending: 9, sentiment: 'neutral'}, 
    {done: 1, pending: 0, sentiment: 'negative'}, 
    {done: 1, pending: 1}, 
  ]

  return (
    <div>
      <div className='flex items-start justify-between mt-55 mb-12'>
        <div>
          <p className="text-md mb-1 font-[500]">Employee Reviews</p>
          <p className='text-sm'>Please see trainings assigned to this employee below with their progress on each training</p>
        </div>
          {/* <Link to={`new-article`}>
              <button className='flex gap-x-2 items-center rounded bg-verovian-purple px-[16px] py-[10px] text-verovian-light-purple text-sm transition duration-200 hover:bg-gray-600'>
                <PlusIcon className={`h-5 w-5`} />
                Assign Review
              </button>
          </Link> */}
      </div>

      {reviewsSelector?.reviewForms?.forms?.length > 0 ?
        <>                                    
          <div className='grid grid-cols-2 gap-6'>
              {reviewsSelector?.reviewForms?.forms?.map((form, formIndex)=>(
              <div key={formIndex} className="bg-white">
                  <EmployeeReviewFormCard form={form} progress={reviewProgresses[formIndex]} />
              </div>
              ))}
          </div>
        </>
        :
        <EmptyState emptyStateText={`No reviews submitted for this employee yet.`} />
      }
    </div>
  )
}

export default EmployeeReviews
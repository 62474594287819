import React, { useEffect, useState } from 'react'
import UserLayout from '../../../../components/layout/UserLayout'
import InventoryLayout from '../../../../components/layout/InventoryLayout'
import { useDispatch, useSelector } from 'react-redux'
import { clearCreatedInventoryItemCategory, createInventoryItemCategory } from '../../../../store/actions/inventoryActions'
import { ERROR, SET_SUCCESS_MESSAGE } from '../../../../store/types'
import TextField from '../../../../components/elements/form/TextField'
import TextareaField from '../../../../components/elements/form/TextareaField'
import FormButton from '../../../../components/elements/form/FormButton'
import Checkbox from '../../../../components/elements/form/Checkbox'
import RadioGroup from '../../../../components/elements/form/RadioGroup'
import PlusIcon from '../../../../components/elements/icons/PlusIcon'
import TrashIcon from '../../../../components/elements/icons/TrashIcon'
import { useNavigate } from 'react-router-dom'

const NewItemCategory = () => {
    const dispatch = useDispatch()
    const inventorySelector = useSelector(state => state.inventory)
    const navigate = useNavigate()
    const [categoryPayload, setCategoryPayload] = useState({});
    const [validationErrors, setValidationErrors] = useState({});

    const categoryField = {
        label: '',
        description: '',
        valueType: '',
        optional: true
    }

    const [categoryFields, setCategoryFields] = useState([categoryField]);

    useEffect(() => {
        if(inventorySelector.createdCategory && inventorySelector.createdCategory !== null) {
            dispatch(clearCreatedInventoryItemCategory())
            dispatch({
                type: SET_SUCCESS_MESSAGE,
                payload: { 
                    successMessage: 'New inventory item category created successfully!'
                }
            })
            navigate('/user/inventory/categories')
        }
        return () => {
            
        };
    }, [dispatch, inventorySelector.createdCategory, navigate]);

    const [creatingCategoryFields, setCreatingCategoryFields] = useState(false);

    const addCategoryField = () => {
        const temp = [...categoryFields]
        temp.push(categoryField)
        setCategoryFields(temp)
    }

    const updateCategoryField = (index, field, value) => {
        const temp = [...categoryFields]
        temp[index][field] = value
        setCategoryFields(temp)
    }

    const removeCategoryField = (index) => {
        const temp = [...categoryFields]
        temp.splice(index, 1)
        setCategoryFields(temp)
    }
    

    const fieldTypes = [
        {
          label: 'Textfield',
          value: 'text',
          description: 'Use this for short, single line responses'
        },
        {
          label: 'Textarea',
          value: 'textarea',
          description: 'For long descriptive text'
        },
        {
          label: 'Number',
          value: 'number',
          description: 'For numeric and calculable responses'
        },
        {
          label: 'Date',
          value: 'date',
          description: 'For dates that need to be tracked'
        },
      ]

    const validateForm = () => {
        let errors = {}
        if(!categoryPayload.name || categoryPayload.name === ''){
            errors.name = true
        }
        if(!categoryPayload.description || categoryPayload.description === ''){
            errors.description = true
        }
        if(creatingCategoryFields){
            categoryFields.forEach((field, fieldIndex) => {
                if(!field.label || field.label === ''){
                    errors[`field-${fieldIndex}-label`] = true
                }
                if(!field.valueType || field.valueType === ''){
                    errors[`field-${fieldIndex}-value-type`] = true
                }
            })
        }

        setValidationErrors(errors)
        console.log('form errors: ', errors)
        return errors
    }
    
    const pushCategory = () => {
        if (Object.values(validateForm()).includes(true)) {
            dispatch({
                type: ERROR,
                error: {response: {data: {
                    message: 'Please check the highlighted fields'
                }}}
            });
            return
        }
        const payload = {...categoryPayload}
        if(creatingCategoryFields){
            payload.additionalVariantFields = categoryFields
        }
        dispatch(createInventoryItemCategory(payload))
    }

    return (
        <UserLayout pageTitle={`Inventory`}>
            <InventoryLayout sectionTitle={`New Item Category`}>
                <div className='w-2/3 p-[20px] mt-[20px] bg-white rounded-[8px]'>
                    <div className='w-full'>
                        <div  className='w-full'>
                            <p className="text-sm font-[500] text-gray-600">
                                Category Details
                            </p>
                            <p className='text-xs text-gray-700 mt-2'>Please provide details of the inventory item category below to create the category.</p>
                        </div>
                        <div className='w-full'>
                            <div className='mb-4 w-full'>
                                <TextField
                                    inputLabel="Category name" 
                                    fieldId="document-name" 
                                    inputType="text" 
                                    preloadValue={categoryPayload.name || ''}
                                    hasError={validationErrors.name} 
                                    returnFieldValue={(value)=>{setCategoryPayload({...categoryPayload, ...{name: value}})}}
                                />
                            </div>          
                        
                            <div className='mb-4 w-full'>
                                <TextareaField
                                    inputLabel="Description" 
                                    fieldId="dept-description" 
                                    inputType="text" 
                                    preloadValue={categoryPayload.description || ''}
                                    hasError={validationErrors.description} 
                                    returnFieldValue={(value)=>{setCategoryPayload({...categoryPayload, ...{description: value}})}}
                                />
                            </div>   

                            <div  className='w-full'>
                                <p className="text-sm font-medium text-gray-600">
                                    Additional Category Fields
                                </p>
                                <p className='text-xs text-gray-700 mt-2'>These fields are a way to add more descriptors to items in this category apart from the default fields (name, description, cost, serial number, condition, age, quantity). If they are provided, whenever an item is created for this category, these fields would be required.</p>
                            </div>  

                            <div onClick={()=>{setCreatingCategoryFields(!creatingCategoryFields)}} className='cursor-pointer flex flex-row gap-x-s justify-between items-start my-3 hover:bg-gray-100 transition duration-200 py-2 px-2 rounded'>
                                <span className='mt-[2px]'>
                                    <Checkbox
                                        CheckboxLabel=""
                                        checkboxToggleFunction={()=>{setCreatingCategoryFields(!creatingCategoryFields)}} 
                                        isChecked={creatingCategoryFields} 
                                        hasError={false}
                                    />
                                </span>
                                <div className='w-full'>
                                    <p className='font-medium text-[13px]'>Do you want to create additional fields now?</p>
                                </div>
                            </div>           
                        </div>
                    </div>

                    {creatingCategoryFields && <div className='w-full'>
                        {categoryFields.map((field, fieldIndex)=>(<div key={fieldIndex} className='w-full'>
                            <div className='w-full flex items-center justify-between'>
                                <p className='uppercase tracking-[0.1em] text-xs font-[500]'>field {fieldIndex + 1}</p>
                                {fieldIndex > 0 && <button onClick={()=>{removeCategoryField(fieldIndex)}} className='w-[30px] h-[30px] text-gray-500 hover:text-red-600 flex items-center justify-center bg-transparent hover:bg-gray-200 rounded transition duration-200'>
                                    <TrashIcon className={`w-5 h-5`} />                
                                </button>}
                            </div>
                            <div className='mb-4 w-full'>
                                <TextField
                                    inputLabel="Label" 
                                    fieldId={`field-${fieldIndex}-label`} 
                                    inputType="text" 
                                    preloadValue={field.label || ''}
                                    hasError={validationErrors[`field-${fieldIndex}-label`]} 
                                    returnFieldValue={(value)=>{updateCategoryField(fieldIndex, 'label', value)}}
                                />
                            </div>
                            <div className='mb-4 w-full'>
                                <TextField
                                    inputLabel="Description" 
                                    fieldId={`field-${fieldIndex}-description`} 
                                    inputType="text" 
                                    preloadValue={field.description || ''}
                                    hasError={false} 
                                    returnFieldValue={(value)=>{updateCategoryField(fieldIndex, 'description', value)}}
                                />
                                <label className='block text-xs mt-1'>This description will be displayed to users when creating an inventory item of this category</label>
                            </div>
                            <div className='mb-4 w-full'>
                                <RadioGroup
                                    inputLabel="Field type (What type of field should be presented to the reviewer?)"
                                    inline={false}
                                    items={fieldTypes} 
                                    hasError={validationErrors[`field-${fieldIndex}-value-type`]} 
                                    returnSelected={(value)=>{updateCategoryField(fieldIndex, 'valueType', value.value)}}
                                />
                            </div>
                            <div onClick={()=>{setCreatingCategoryFields(!creatingCategoryFields)}} className='cursor-pointer flex flex-row gap-x-s justify-between items-start my-3 hover:bg-gray-100 transition duration-200 py-2 px-2 rounded'>
                                <span className='mt-[2px]'>
                                    <Checkbox
                                        CheckboxLabel=""
                                        checkboxToggleFunction={()=>{updateCategoryField(fieldIndex, 'optional', !field.optional)}} 
                                        isChecked={!field.optional} 
                                        hasError={false}
                                    />
                                </span>
                                <div className='w-full'>
                                    <p className='font-medium text-[13px]'>Check this box if this field must be provided for each variant added.</p>
                                </div>
                            </div>
                        </div>))}
                        <div className='flex flex-row-reverse mt-[20px]'>
                            <button onClick={()=>{addCategoryField()}} className='flex items-center justify-center font-[500] text-xs gap-x-[5px] border border-verovian-purple bg-verovian-light-purple bg-opacity-50 text-verovian-purple rounded p-[10px]'>
                                <PlusIcon className={`w-5 h-5`} />
                                Add another field
                            </button>
                        </div>
                    </div>}

                    <div className='my-8 flex flex-row-reverse items-center justify-between pt-8 border-t'>
                        <div className='min-w-[200px]'>
                            <FormButton buttonLabel={`Create Category`} buttonAction={()=>{pushCategory()}} processing={inventorySelector.creatingCategory} />
                        </div>
                    </div>
                </div>
            </InventoryLayout>
        </UserLayout>
    )
}

export default NewItemCategory
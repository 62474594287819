import React from 'react'
import UserLayout from '../../../components/layout/UserLayout'
import InventoryLayout from '../../../components/layout/InventoryLayout'

const InventoryItem = () => {
    return (
        <UserLayout pageTitle={`Inventory`}>
            <InventoryLayout sectionTitle={`New Inventory Items`}>
                InventoryItem
            </InventoryLayout>
        </UserLayout>
    )
}

export default InventoryItem
import axios from "axios"
import { authHeader } from "../../utils"
import { CREATE_ONBOARDING_PACKAGE, CREATING_ONBOARDING_PACKAGE, DELETE_ONBOARDING_PACKAGE, DELETING_ONBOARDING_PACKAGE, GET_ONBOARDING_PACKAGES, GETTING_ONBOARDING_PACKAGES, ONBOARDING_PACKAGES_ERROR, UPDATE_ONBOARDING_PACKAGE, UPDATING_ONBOARDING_PACKAGE } from "../types"


export const createOnboardingPackage = (shiftPayload) => async (dispatch) => {    
    try{
        const headers = authHeader()

        dispatch({
            type: CREATING_ONBOARDING_PACKAGE,
            payload: true
        })
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/onboarding-packages`, shiftPayload, { headers })
        
        dispatch({
            type: CREATE_ONBOARDING_PACKAGE,
            payload: response.data
        })
        
    }
    catch(error){
        dispatch({
            type: ONBOARDING_PACKAGES_ERROR,
            error
        })
    }
}

export const clearCreatedOnboardingPackage = () => async dispatch => {    
    try{
        dispatch( {
            type: CREATE_ONBOARDING_PACKAGE,
            payload: null
        })
        
    }
    catch(error){
        dispatch( {
            type: ONBOARDING_PACKAGES_ERROR,
            error
        })
    }
}

export const fetchOnboardingPackages = (pagination, filters) => async dispatch => {    
    try{
        const headers = authHeader()
        let requestUrl = 'onboarding-packages?expand=createdBy'
        if(filters && filters !== '') {
            requestUrl += `&${filters}`
        }

        let url = `reviews/review-forms`
        if((pagination.page || pagination.page===0) && pagination.page !== ''){
            requestUrl += `${url.includes('?') ? '&' : '?'}page=${pagination.page}`
        }
        if((pagination.perPage || pagination.perPage === 0) && pagination.perPage !== ''){
            requestUrl += `${url.includes('?') ? '&' : '?'}perPage=${pagination.perPage}`
        }

        dispatch( {
            type: GETTING_ONBOARDING_PACKAGES,
            payload: true
        })

        const response = await axios.get(`${process.env.REACT_APP_API_URL}/${requestUrl}`, { headers })

        dispatch( {
            type: GET_ONBOARDING_PACKAGES,
            payload: response.data.data
        })
        
    }
    catch(error){
        dispatch( {
            type: ONBOARDING_PACKAGES_ERROR,
            error
        })
    }
}

export const updateOnboardingPackage = (packageId, payload) => async dispatch => {    
    try{
        const headers = authHeader()
        let requestUrl = `onboarding-packages/${packageId}`

        dispatch( {
            type: UPDATING_ONBOARDING_PACKAGE,
            payload: true
        })

        const response = await axios.patch(`${process.env.REACT_APP_API_URL}/${requestUrl}`, payload, { headers })
        dispatch( {
            type: UPDATE_ONBOARDING_PACKAGE,
            payload: response.data.data
        })
        
    }
    catch(error){
        dispatch( {
            type: ONBOARDING_PACKAGES_ERROR,
            error
        })
    }
}

export const clearUpdatedOnboardingPackage = () => async dispatch => {    
    try{
        dispatch( {
            type: UPDATE_ONBOARDING_PACKAGE,
            payload: null
        })
        
    }
    catch(error){
        dispatch( {
            type: ONBOARDING_PACKAGES_ERROR,
            error
        })
    }
}

export const deleteOnboardingPackage = (packageId) => async dispatch => {    
    try{
        const headers = authHeader()
        let requestUrl = `onboarding-packages/${packageId}`

        dispatch( {
            type: DELETING_ONBOARDING_PACKAGE,
            payload: true
        })

        const response = await axios.delete(`${process.env.REACT_APP_API_URL}/${requestUrl}`, { headers })
        dispatch( {
            type: UPDATE_ONBOARDING_PACKAGE,
            payload: response.data.data
        })
        
    }
    catch(error){
        dispatch( {
            type: ONBOARDING_PACKAGES_ERROR,
            error
        })
    }
}

export const clearDeletedOnboardingPackage = () => async dispatch => {    
    try{
        dispatch( {
            type: DELETE_ONBOARDING_PACKAGE,
            payload: null
        })
    }
    catch(error){
        dispatch( {
            type: ONBOARDING_PACKAGES_ERROR,
            error
        })
    }
}


import React from 'react'
import TrashIcon from '../../elements/icons/TrashIcon'
import moment from 'moment'
import { Link } from 'react-router-dom'
import ArrowIcon from '../../elements/icons/ArrowIcon'

const OnboardingPackageCard = ({onboardingPackage, hideActions, doDelete}) => {
  return (
    <div className='p-[15px] rounded-[8px] bg-white relative'>

        {!hideActions && <button onClick={()=>{doDelete()}} className='flex items-center justify-center absolute top-[10px] right-[10px] mb-2 rounded w-[25px] h-[25px] hover:bg-gray-100 text-gray-400 hover:text-red-600 transition duration-200'>
            <TrashIcon className={`w-5 h-5`} />
        </button>}

        <h3 className='text-[15px] font-[500] mb-[10px] text-verovian-purple'>{onboardingPackage.name}</h3>
        <p className='text-xs text-gray-500 mb-[10px]'>
            {onboardingPackage.description.slice(0, 120)}{onboardingPackage.description.length > 120 && '...'}
        </p>
        <p className='text-xs text-gray-500 mb-[20px]'>
            {onboardingPackage.onboardingDays.length} days total
        </p>

        {!hideActions && <p className='text-xs mt-[10px]'>Created: {moment(onboardingPackage.createdAt).format('MMM D, YYYY')}</p>}

        {!hideActions && <div className='mt-[10px] border-t pt-[10px]'>
            <Link to={`/user/company/onboarding-packages/details/${onboardingPackage._id}`} className='text-xs text-gray-600 flex items-center gap-x-2 hover:text-verovian-purple duration-200 transition mt-3 font-[550]' >See package details <ArrowIcon className={`w-4 h-4 -rotate-90`}/></Link>
        </div>}

    </div>
  )
}

export default OnboardingPackageCard
import React, { useEffect, useState } from 'react'
import TextField from '../../../../components/elements/form/TextField'
import RadioGroup from '../../../../components/elements/form/RadioGroup'
import { useDispatch, useSelector } from 'react-redux'
import { clearCreatedReviewQuestion, createReviewQuestion } from '../../../../store/actions/reviewsActions'
import FormButton from '../../../../components/elements/form/FormButton'
import { ERROR, SET_SUCCESS_MESSAGE } from '../../../../store/types'
import ReviewsManagementLayout from '../../../../components/layout/ReviewsManagementLayout'
import TextareaField from '../../../../components/elements/form/TextareaField'
import PlusIcon from '../../../../components/elements/icons/PlusIcon'
import TrashIcon from '../../../../components/elements/icons/TrashIcon'

const NewReviewQuestion = () => {
  const dispatch = useDispatch()
  const reviewsSelector = useSelector(state => state.reviews)
  const [questionPayload, setQuestionPayload] = useState({});
  const [validationErrors, setValidationErrors] = useState({});
  const [options, setOptions] = useState([{
    option: '',
    sentiment: ''
  }]);
  const requiringOptions = ['radio', 'select', 'checkbox']
  const [display, setDisplay] = useState(true);

  useEffect(() => {
    if(reviewsSelector.createdReviewQuestion !== null) {
      dispatch({
        type: SET_SUCCESS_MESSAGE,
				payload: {
					successMessage: 'Review question created successfully. Please fill the form again to create another question'
				}
      })
      dispatch(clearCreatedReviewQuestion())
      setDisplay(false)
      setQuestionPayload({})
      setOptions([''])
      setTimeout(() => {
        setDisplay(true)
      }, 50);
    }
    
    return () => {
      
    };

  }, [reviewsSelector.createdReviewQuestion, dispatch]);

  const validateForm = () => {
    let errors = {}
    if (!questionPayload.question || questionPayload.question === '') {
      errors.question = true
    }
    
    if (!questionPayload.fieldType || questionPayload.fieldType === '') {
      errors.fieldType = true
    }
    
    if (requiringOptions.includes(questionPayload.fieldType) && (!options || options.length === 0)) {
      errors.profession = true
      dispatch({
        type: ERROR,
        error: {response: {data: {
            message: 'Options are required for field types select, radio and checkbox'
        }}}
    });
    }
    
    if (requiringOptions.includes(questionPayload.fieldType) && options && options.length > 0) {
      options.forEach((option, optionIndex) => {
        if (!option.option || option.option === '') {
          errors[`option-${optionIndex}-option`] = true
        }
        if (!option.sentiment || option.sentiment === '') {
          errors[`option-${optionIndex}-sentiment`] = true
        }
      })
    }

    setValidationErrors(errors)
    return errors
  }

  const pushQuestion = () => {
    if (Object.values(validateForm()).includes(true)) {
      dispatch({
          type: ERROR,
          error: {response: {data: {
              message: 'Please check the highlighted fields'
          }}}
      });
      return
    }

    const payload = questionPayload
    if(options && options.length > 0) {
      payload.options = options
    }

    dispatch(createReviewQuestion(payload))
  }

  const fieldTypes = [
    {
      label: 'Textfield',
      value: 'text',
      description: 'Use this for short, single line responses'
    },
    {
      label: 'Textarea',
      value: 'textarea',
      description: 'For long descriptive text'
    },
    {
      label: 'Single choice (radio group)',
      value: 'radio',
      description: 'For single choice responses out of multiple options'
    },
    {
      label: 'Select field',
      value: 'select',
      description: 'Alternative to radio group, for single choice responses out of multiple options'
    },
    {
      label: 'Multi select (checkbox)',
      value: 'checkbox',
      description: 'To allow multiple choice responses out of multiple options'
    },
  ]

  const addOption = () => {
    let temp = [...options]
    temp.push({option: '', sentiment: ''})
    setOptions(temp)
  }

  const removeOption = (index) => {
    let temp = [...options]
    temp.splice(index, 1)
    setOptions(temp)
  }

  const updateOption = (index, field, value) => {
    let temp = [...options]
    temp[index][field] = value
    setOptions(temp)
  }

  const sentiments = [
    {
      label: "Positive",
      value: 'positive'
    },
    {
      label: "Neutral",
      value: 'neutral'
    },
    {
      label: "Negative",
      value: 'negative'
    },
  ]

  return (
    <ReviewsManagementLayout sectionTitle={`New Review Question`}>
      {display && <div className='w-6/12 mx-auto mt-[50px]'>
        <p className='text-sm'>Please provide details of the review question below</p>
        <div className='w-full'>
          <div className='mb-4 mt-2 w-full'>
              <TextareaField
                  inputLabel="Question" 
                  fieldId="question-name" 
                  inputType="text" 
                  preloadValue={''}
                  hasError={validationErrors.question } 
                  height={`120px`}
                  returnFieldValue={(value)=>{setQuestionPayload({...questionPayload, ...{question: value}})}}
              />
          </div>
          
          <div className='mb-4 mt-2 w-full'>
              <TextareaField
                  inputLabel="Additional Instructions (if any)" 
                  fieldId="question-name" 
                  inputType="text" 
                  preloadValue={''}
                  hasError={false} 
                  height={`120px`}
                  returnFieldValue={(value)=>{setQuestionPayload({...questionPayload, ...{instructions: value}})}}
              />
          </div>

          <div className='my-4 w-full'>
            <RadioGroup
                inputLabel="Field type (What type of field should be presented to the reviewer?)"
                inline={false}
                items={fieldTypes} 
                hasError={validationErrors.fieldType} 
                returnSelected={(value)=>{setQuestionPayload({...questionPayload, ...{fieldType: value.value}})}}
            />
          </div>
          {requiringOptions.includes(questionPayload.fieldType) && <div className='my-4 w-full'>
            <p className='text-sm mt-[20px]'>Response Options</p>
            <p className='text-xs text-gray-500'>Please provide response options for this question</p>

            {options.map((option, optionIndex) => (
              <div key={optionIndex} className='mt-[10px] w-full relative'>
                {optionIndex > 0 && <button onClick={()=>{removeOption(optionIndex)}} className='z-50 absolute right-0 -top-[5px] w-[30px] h-[30px] text-gray-500 hover:text-red-600 flex items-center justify-center bg-transparent hover:bg-gray-200 transition duration-200'>
                  <TrashIcon className={`w-5 h-5`} />                
                </button>}
                
                <div className='mt-4 w-full'>
                  <TextField
                    inputLabel={`Option ${optionIndex + 1}`}
                    fieldId={`option-${optionIndex}-name`} 
                    inputType="text" 
                    preloadValue={option.option || ''}
                    hasError={validationErrors[`option-${optionIndex}-option`] } 
                    height={`120px`}
                    inputPlaceholder={`Type the option here`}
                    returnFieldValue={(value)=>{updateOption(optionIndex, 'option', value)}}
                  />
                </div>

                <div className='mt-4 w-full'>
                  <RadioGroup
                    inputLabel="Sentiment (Select a sentiment for this response)"
                    inline={true}
                    items={sentiments} 
                    fieldId={`option-${optionIndex}-sentiment`}
                    hasError={validationErrors[`option-${optionIndex}-sentiment`]} 
                    returnSelected={(value)=>{updateOption(optionIndex, 'sentiment', value.value)}}
                />
                </div>
              </div>
              
            ))}
            <div className='flex flex-row-reverse mt-[20px]'>
              <button onClick={()=>{addOption()}} className='flex items-center justify-center font-[500] text-xs gap-x-[5px] border border-verovian-purple bg-verovian-light-purple bg-opacity-50 text-verovian-purple rounded p-[10px]'>
                <PlusIcon className={`w-5 h-5`} />
                Add another option
              </button>
            </div>
          </div>}

        </div>
        <div className='flex flex-row-reverse mt-[10px]'>
          <div className='w-max'>
            <FormButton buttonLabel={`Create Question`} processing={reviewsSelector.creatingReviewQuestion} buttonAction={()=>{pushQuestion()}} />
          </div>
        </div>

      </div>}
    </ReviewsManagementLayout>
  )
}

export default NewReviewQuestion
import React from 'react'
import LeavesHolidaysLayout from '../../../../components/layout/LeavesHolidaysLayout'

const Leaves = () => {
  return (
    <LeavesHolidaysLayout>
      
    </LeavesHolidaysLayout>
  )
}

export default Leaves
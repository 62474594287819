import React from 'react'
import LinkedInIcon from '../../../../assets/img/icons/linkedin-black.svg'
import TwitterIcon from '../../../../assets/img/icons/twitter-x.svg'
import FacebookIcon from '../../../../assets/img/icons/facebook-black.svg'
const InstructorProfileCard = ({instructor}) => {
  return (
    <div className='p-5 rounded-[16px] w-full h-full bg-white'>
      <div className='flex items-center gap-x-[10px]'>
        <div className='w-[60px]'>
          <div className='w-[50px] h-[50px] rounded-full bg-gray-100' 
            style={{
              backgroundImage: `url(${instructor.avatar})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat'
            }} 
          />
        </div>
        <div className='w-full'>
          <h3 className='mt-[5px] text-sm font-medium'>{instructor.name}</h3>
          <p className='text-[12px] my-[2px]'>{instructor.email}</p>
         
        </div>
      </div>

      <p className='text-[13px] mt-[20px]'>{instructor.about}</p>

      <div className='flex items-center gap-x-[10px] mt-[10px]'>
          {instructor?.socials?.linkedin && <a href={instructor?.socials?.linkedin} target='_blank' rel="noreferrer">
              <img src={LinkedInIcon} alt=''  className='w-[20px]'/>
            </a>}
            {instructor?.socials?.twitter && <a href={instructor?.socials?.twitter} target='_blank' rel="noreferrer">
              <img src={TwitterIcon} alt='' className='w-[20px]'/>
            </a>}
            {instructor?.socials?.facebook && <a href={instructor?.socials?.facebook} target='_blank' rel="noreferrer">
              <img src={FacebookIcon} alt=''  className='w-[20px]'/>
            </a>}
          </div>
    </div>
  )
}

export default InstructorProfileCard
import React, { useEffect, useState } from 'react'
import UserLayout from '../../../../components/layout/UserLayout'
import InventoryLayout from '../../../../components/layout/InventoryLayout'
import { useDispatch, useSelector } from 'react-redux'
import { getInventoryItemCategories } from '../../../../store/actions/inventoryActions'
import PlusIcon from '../../../../components/elements/icons/PlusIcon'
import { Link } from 'react-router-dom'
import Preloader from '../../../../components/elements/Preloader'
import { userDetails } from '../../../../utils'
import TrashIcon from '../../../../components/elements/icons/TrashIcon'
import ArrowIcon from '../../../../components/elements/icons/ArrowIcon'
import Pagination from '../../../../components/elements/Pagination'
import EmptyState from '../../../../components/elements/icons/EmptyState'

const ItemCategories = () => {
    const inventorySelector = useSelector(state => state.inventory)
    const accountPermissions = userDetails().accountPermissions
    const dispatch = useDispatch()

    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(25);

    useEffect(() => {
        dispatch(getInventoryItemCategories(page, perPage))
        return () => {
            
        };
    }, [dispatch, page, perPage]);


    const deleteCategory = () => {
        if(window.confirm('Are you sure you want to delete this category?')) {
            dispatch()
        }
    }

    return (
        <>
            <UserLayout pageTitle={`Inventory`}>
                <InventoryLayout sectionTitle={`Categories`}>
                    <div className='w-full mx-auto'>
                        <div className='w-full pt-5 flex items-center justify-between mb-4'>
                            <div className='w-9/12'>
                                {/* <Filters filterLabel="Filter Tasks" filterOptions={filters} returnSelected={(filter)=>{captureFilter(filter)}} resetFilters={()=>{setSearchFilter(initialFilters)}} /> */}
                                <h3 className='text-sm font-[500] mb-1'>Inventory Item categories</h3>
                                <p className='text-[13px] text-gray-500'>You can use categories to group inventory items. Create a new category by clicking on "Create new category".</p>
                            </div>
                            <div className='3/12'>
                                <Link to={`new-category`} className='flex gap-x-2 items-center rounded bg-verovian-purple px-3 py-3 text-verovian-light-purple text-sm transition duration-200 hover:bg-gray-700'>
                                    <PlusIcon className={`h-5 w-5`} />
                                    Create new category
                                </Link>
                            </div>
                        </div>

                        {inventorySelector?.loadingCategories && inventorySelector.loadingCategories === true ? 
                            <Preloader preloadingText={'Loading inventory item categories... '} />
                            : 
                            <>
                                {inventorySelector?.categories?.categories?.length > 0 ? <>
                                <div className='w-full grid grid-cols-3 gap-2'>
                                    {inventorySelector?.categories.categories.map((cat, catIndex) => ( 
                                        <div key={catIndex} className='w-full p-6 bg-white bg-opacity-50 relative'>

                                            {(accountPermissions?.includes('*') || accountPermissions?.includes('inventory.*') || accountPermissions?.includes('inventory.delete')) && <button onClick={()=>{deleteCategory()}} className='transition duration-200 hover:text-gray-700 p-1 text-gray-400 rounded absolute top-3 right-4'>
                                                <TrashIcon className={`w-5 h-5`} />
                                            </button>}
                                            <h3 className='text-sm font-[550] text-black mb-2'>{cat.name}</h3>
                                            
                                            <p className="text-xs text-gray-500 mb-2">{cat.description.substr(0, 220)}{cat.description.length > 220 && '...'}</p>
                                            <p className="text-xs">{cat.items || 0} items</p>

                                            <p className="text-xs text-gray-700 font-[500] mb-1 mt-[10px]">Additional fields</p>
                                            {cat.additionalVariantFields && cat.additionalVariantFields.length > 0 ?
                                                <>
                                                    {cat.additionalVariantFields.slice(0, 2).map((field, fieldIndex) => (
                                                        <span key={fieldIndex} className='text-[10px] font-[500] p-[5px] rounded bg-gray-100 mr-[5px] mb-[5px]'>
                                                            {field.label}
                                                        </span>
                                                    ))}
                                                    {cat.additionalVariantFields.length > 2 &&<span className='text-[10px] font-[400] p-[5px] rounded bg-gray-100 mr-[5px] mb-[5px]'>
                                                        +{cat.additionalVariantFields.length - 2} more
                                                    </span>}
                                                </>
                                                :
                                                <p className='text-gray-400 text-xs py-[5px]'>No fields</p>
                                            }


                                            <Link className='text-xs text-gray-600 flex items-center gap-x-2 hover:text-verovian-purple duration-200 transition mt-3' to={`details/${cat._id}`}>See category details <ArrowIcon className={`w-4 h-4 -rotate-90`}/></Link>
                                        </div>
                                    ))}
                                </div> 

                                <Pagination 
                                    pagination={{
                                        currentPage: page,
                                        perPage: perPage,
                                        totalItems: inventorySelector?.categories?.total
                                    }}
                                    changePage={(value)=>{setPage(value)}}
                                    updatePerPage={(value)=>{setPerPage(value)}}
                                />
                                </>
                                :
                                <EmptyState emptyStateText={`No inventory item categories created yet. Click on the "Create new category" button above to create one`} />
                                }
                            </>
                        }
                    </div>
                </InventoryLayout>
            </UserLayout>
        </>
    )
}

export default ItemCategories
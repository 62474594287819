import React, { useState } from 'react'
import TextField from '../../elements/form/TextField'
import RadioGroup from '../../elements/form/RadioGroup'
import TextareaField from '../../elements/form/TextareaField'
import SelectField from '../../elements/form/SelectField'
import Checkbox from '../../elements/form/Checkbox'

const ReviewQuestion = ({responseRequired, hasError, question, returnResponse}) => {
    const [selectedOptions, setSelectedOptions] = useState([]);

    // const toggleOption = (option) => {
    //     if(selectedOptions.includes(option)) {
    //         setSelectedOptions(selectedOptions.filter(opt => opt !== option));
    //     } else {
    //         setSelectedOptions([...selectedOptions, option]);
    //     }
    // }

    const selectCheckboxOption = (option) => {
        const temp = [...selectedOptions];
    
        if (optionSelected(option)) {
            const selectedIndex = temp.findIndex(opt => opt.responseId === option._id);
            temp.splice(selectedIndex, 1)
        } else {
            temp.push({
                responseText: option.option,
                responseId: option._id,
            });
        }
        console.log('new selected list -> ', temp)
        setSelectedOptions(temp);
        returnResponse(temp);
    };
    
    const optionSelected = (option) => {
        const selected = selectedOptions.find(opt => option._id === opt.responseId);
        return !!selected;
    };
    

    return (
        <div className='w-full p-[20px]'>

            <p className='text-sm mb-[10px] text-black'>{question.question}</p>
            {question.instructions && question.instructions !== '' && 
                <p className='text-xs text-gray-500 mb-[20px]'>{question.instructions}</p>
            }
            <div className='relative'>
                {responseRequired && <p className='absolute top-[10px] right-0 text-[10px] text-red-600'>Required</p>}

                {question.fieldType === 'text' && 
                    <TextField
                        inputLabel={`Response`}
                        fieldId={`question-${question.questionCode}-response`} 
                        inputType="text" 
                        preloadValue={''}
                        hasError={hasError} 
                        inputPlaceholder={`Type response here`}
                        required
                        returnFieldValue={(value)=>{returnResponse(value)}}
                    />
                }
                {question.fieldType === 'textarea' && 
                    <TextareaField
                        inputLabel={`Response`}
                        fieldId={`question-${question.questionCode}-response`} 
                        inputType="text" 
                        preloadValue={''}
                        hasError={hasError} 
                        inputPlaceholder={`Type response here`}
                        returnFieldValue={(value)=>{returnResponse(value)}}
                    />
                }
                {question.fieldType === 'radio' && 
                    <RadioGroup
                        inputLabel="Response"
                        items={question.options.map(option => {
                            return {label: option.option, _id: option._id}
                        })} 
                        inline={false}
                        hasError={hasError} 
                        returnSelected={(value)=>{returnResponse({label: value.label, _id: value._id})}}
                    />
                }
                {question.fieldType === 'select' && 
                    <SelectField
                        selectOptions={question.options.map(option => {return {label: option.option, _id: option._id}})}
                        inputLabel="Response"
                        titleField="label"
                        displayImage={false}
                        imageField=""
                        preSelected={''}
                        preSelectedLabel={`label`}
                        fieldId={`response-${question.questionCode}`}
                        hasError={hasError}
                        // returnFieldValue={(value)=>{returnResponse(value.label)}}
                        returnFieldValue={(value)=>{returnResponse({label: value.label, _id: value._id})}}
                    />
                }
                {question.fieldType === 'checkbox' && 
                    <>
                    <p className='text-xs mb-[10px] text-gray-500'>Select all that apply</p>
                    {question.options.map((option, optionIndex) => (
                    <div key={optionIndex} className={`flex items-start gap-x-2 mb-4 cursor-pointer`} onClick={(()=>{selectCheckboxOption(option)})}>
                        <div className='w-[25px] pt-[3x]'>
                        <Checkbox 
                            // isChecked={selectedOptions.includes(option.option)}
                            isChecked={optionSelected(option)}
                            checkboxToggleFunction={()=>{selectCheckboxOption(option)}}
                            checkboxLabel=''
                        />
                        </div>
                        <div className='w-full'>
                            <p className='text-sm text-gray-700 font-medium'>{option.option}</p>
                        </div>
                    </div>
                    ))}
                    </>
                }
            </div>
            

        </div>
    )
}

export default ReviewQuestion
import React, { useState } from 'react'
import ChevronIcon from '../../elements/icons/ChevronIcon'
import ReviewQuestion from './ReviewQuestion';
import { unSlugify } from '../../../utils';
import TrashIcon from '../../elements/icons/TrashIcon';

const ReviewQuestionCard = ({question, doDelete}) => {
  const [previewOpen, setPreviewOpen] = useState(false);

  return (
    <div className='p-[20px] border rounded border-gray-300 max-w-[100%]'>
      <div className='flex items-center justify-between mb-[10px]'>
        <p className='text-sm font-[500]'>{question.questionCode}</p>
        <div className='flex flex-row-reverse'>
          <span className='capitalize rounded border border-gray-400 text-gray-600 px-[7px] py-[5px] text-xs'>{unSlugify(question.fieldType)}</span>
        </div>
      </div>
      <p className='text-sm'>{question.question.length > 140 ? question.question.slice(0, 140) + '...' : question.question}</p>

      <div className='flex items-center justify-between mb-[10px]'>
        <button onClick={()=>{setPreviewOpen(!previewOpen)}} className='mt-[20px] text-xs text-gray-800 hover:text-gray-400 transition duration-200 flex items-center gap-x-[5px]'>
          {previewOpen ? 'Hide' : 'Show'} question preview 
          <ChevronIcon className={`w-4 h-4 ${previewOpen ? '' : '-rotate-90'}`} />
        </button>

        <button onClick={()=>{doDelete()}} className='w-[30px] h-[30px] text-gray-500 hover:text-red-600 flex items-center justify-center bg-transparent hover:bg-gray-200 transition duration-200'>
          <TrashIcon className={`w-5 h-5`} />                
        </button>
      </div>

      {previewOpen && <div className='px-[20px] py-[10px]'>
        <ReviewQuestion 
          question={question}
          returnResponse={()=>{}} 
          responseRequired={question.responseRequired}
        />
      </div>}
    </div>
  )
}

export default ReviewQuestionCard
import React from 'react'
import { Link, useLocation } from 'react-router-dom';

const InventoryLayout = ({children, sectionTitle}) => {
   const location = useLocation();
    const currentRoute = location.pathname;
    return (
        <div className='w-full flex items-start gap-x-8'>
            <div className='min-w-[200px] rounded'>
                <Link to={`/user/inventory/items`}>
                    <button className='w-full flex items-center gap-x-3 px-2 py-1 mb-1'>
                        {/* <HomeIcon className={`w-5 h-5 text-black`} /> */}
                        <p className={`text-sm ${currentRoute.includes('/user/inventory/items') && 'font-bold text-verovian-purple'}`}>Items</p>
                    </button>
                </Link>
                <Link to={`/user/inventory/categories`}>
                    <button className='w-full flex items-center gap-x-3 px-2 py-1 mb-1'>
                        {/* <HomeIcon className={`w-5 h-5 text-black`} /> */}
                        <p className={`text-sm ${currentRoute.includes('/user/inventory/categories') && 'font-bold text-verovian-purple'}`}>Item Categories</p>
                    </button>
                </Link>

            </div>

            <div className='w-full'>
                <h3 className='uppercase font-light text-[15px] tracking-[0.2em]'>{sectionTitle}</h3>
                <main>{children}</main>
            </div>
        </div>
    )
}

export default InventoryLayout
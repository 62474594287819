import React, { useEffect, useState } from 'react'
import TrainingsLayout from '../../../components/layout/TrainingsLayout'
import { authHeader, userDetails } from '../../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { ERROR, SET_SUCCESS_MESSAGE, TRAININGS_ERROR } from '../../../store/types';
import Preloader from '../../../components/elements/Preloader';
import ChevronIcon from '../../../components/elements/icons/ChevronIcon';
import VideoIcon from '../../../components/elements/icons/VideoIcon';
import AudioIcon from '../../../components/elements/icons/AudioIcon';
import PhotoIcon from '../../../components/elements/icons/PhotoIcon';
import TextIcon from '../../../components/elements/icons/TextIcon';
import PlayIcon from '../../../components/elements/icons/PlayIcon';
import PencilSquareIcon from '../../../components/elements/icons/PencilSquareIcon';
import TrashIcon from '../../../components/elements/icons/TrashIcon';
import { clearDeletedTraining, deleteTraining } from '../../../store/actions/trainingsActions';
import InlinePreloader from '../../../components/elements/InlinePreloader';

const Training = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const trainingsSelector = useSelector(state => state.trainings)
    const { trainingId } = useParams()
    const [training, setTraining] = useState(null);
    const [loading, setLoading] = useState(true);
    const [trainingStarted, setTrainingStarted] = useState(false);
    const [trainingCompleted, setTrainingCompleted] = useState(false);
    useEffect(() => {
        const getTraining = async () => {
          try {
            const headers = authHeader()
            
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/training/trainings/${trainingId}?expand=modules.module`, { headers })
            setTraining(response.data.data)
            findTrainingAssignment()
          } catch (error) {
            dispatch({
              type: ERROR,
              error: error
            })
            setLoading(false)
          }
        }

        const findTrainingAssignment = async () => {
          try{
              const headers = authHeader()
      
              let url = `training/employee?training=${trainingId}`
              
              const response = await axios.get(`${process.env.REACT_APP_API_URL}/${url}`, { headers })
              if(response.data.data.employeeTrainings.length > 0) {
                console.log('completed: ', response.data.data.employeeTrainings[0].completed)
                setTrainingStarted(response.data.data.employeeTrainings[0].started || false)
                setTrainingCompleted(response.data.data.employeeTrainings[0].completed || false)
              }
              setLoading(false)

          }
          catch(error){
              dispatch({
                  type: TRAININGS_ERROR,
                  error
              })
          }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }
        
        getTraining()

        if(trainingsSelector.deletedTraining && trainingsSelector.deletedTraining !== null){
          dispatch({
            type: SET_SUCCESS_MESSAGE,
            payload: {
                successMessage: `Training deleted successfully!`
              }
          })
          dispatch(clearDeletedTraining())
          navigate('/user/trainings/trainings')

        }
        
        return () => {
            
        };
    }, [dispatch, navigate, trainingId, trainingsSelector.deletedTraining]);

    const [loadingModuleDetails, setLoadingModuleDetails] = useState(null);
    const [activeModuleDetails, setActiveModuleDetails] = useState(null);

    const getTrainingModule = async (moduleId) => {
      if(moduleId === activeModuleDetails?._id) {
        setActiveModuleDetails(null)
        return;
      }
      setActiveModuleDetails(null)
      try {
        const headers = authHeader()
        
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/training/modules/${moduleId}?expand=materials.material,assessment`, { headers })
        setActiveModuleDetails(response.data.data)
        setLoadingModuleDetails(null)
      } catch (error) {
        dispatch({
          type: ERROR,
          error: error
        })
        setLoadingModuleDetails(false)
      }
    }

    const trainingEstimatedTime = training?.modules?.reduce((accumulator, current) => accumulator+current.module.estimatedTime, 0)

    const pushDeleteTraining = () => {
      if(window.confirm('You are about to delete this training. This action cannot be undone. Do you wish to continue?')) {
        dispatch(deleteTraining(trainingId))
      }
    }

    const [startingTraining, setStartingTraining] = useState(false);
    const startTraining = async() => {
      try {
        const headers = authHeader()
        setStartingTraining(true)
        await axios.get(`${process.env.REACT_APP_API_URL}/training/trainings/${trainingId}/start`, { headers })
        navigate(`/user/trainings/training/${trainingId}/player`)
        setStartingTraining(false)

      } catch (error) {
        dispatch({
          type: ERROR,
          error: error
        })
        setLoadingModuleDetails(false)
      }
    }
 
    return (
        <TrainingsLayout sectionTitle={`Training details`}>
          {loading ? 
            <Preloader />
          :
            <div className='w-10/12 '>

              <div className={`cursor-pointer w-full mb-[50px] bg-white p-[30px]`}>
                <div className='flex items-start gap-x-[40px]'>
                  <div className='rounded flex items-center justify-center bg-gray-100 w-full my-[20px]'>
                    <img src={training.coverImage} alt='' className='w-full' />
                  </div>

                  <div className='w-full py-[20px]'>
                    <p className='text-verovian-purple font-medium'>{training?.title}</p>
                    <div className='mt-1 mb-[20px]'>
                        <p className='text-xs'>{training?.description}</p>
                    </div>

                    {/* <p><span className='text-xl'>0</span> <span className='text-xs'>Employees assigned</span></p>
                    <p><span className='text-xl'>0</span> <span className='text-xs'>Employees currently participating</span></p> */}

                    <div className='flex items-center justify-between gap-x-[10px]'>
                      <button onClick={()=>{startTraining()}} className='mt-[20px] w-full flex gap-x-2 items-center rounded bg-verovian-purple px-3 py-3 text-verovian-light-purple text-sm transition duration-200 hover:bg-gray-600'>
                          <PlayIcon className={`h-5 w-5`} />
                          {!startingTraining ? <>
                            {trainingStarted && !trainingCompleted ? 'Continue training' : trainingStarted && trainingCompleted ? 'Open training' : 'Start training'}
                          </> : <span className='mx-auto'><InlinePreloader /></span>}
                      </button>
                      {(userDetails().accountPermissions?.includes('*') || userDetails().accountPermissions?.includes('trainings.*') || userDetails().accountPermissions?.includes('trainings.update')) &&<Link to={'edit'} className='mt-[20px] w-full flex gap-x-2 items-center rounded bg-gray-100 px-3 py-3 text-gray-600 text-sm transition duration-200 hover:text-gray-800 hover:bg-gray-200'>
                          <PencilSquareIcon className={`h-5 w-5`} />
                          Edit training
                      </Link>}
                      {(userDetails().accountPermissions?.includes('*') || userDetails().accountPermissions?.includes('trainings.*') || userDetails().accountPermissions?.includes('trainings.delete')) && <button onClick={()=>{pushDeleteTraining()}} className='mt-[20px] w-[50px] flex gap-x-2 items-center rounded bg-transparent px-3 py-3 text-red-400 text-sm transition duration-200 hover:bg-red-600 hover:text-white'>
                          <TrashIcon className={`h-5 w-5`} />
                      </button>}
                    </div>

                  </div>
                </div>
                

                <p className='text-gray-500 mt-[20px]'>{training?.modules?.length} modules <span className='text-xs text-gray-500'>{trainingEstimatedTime} minutes total</span></p>
                <p className='text-gray-500 text-xs mb-[20px]'>Click on a module below to see more details and all materials in it</p>

                {training.modules.map((module, moduleIndex) => (
                  <div key={moduleIndex} className='my-[20px]'>
                    <div key={moduleIndex} className='w-full rounded-[8px] border p-[10px]'>
                      <div className={`flex items-start gap-x-2 mb-2 cursor-pointer w-full`}>
                        <div className='w-[80px]'>
                          <div className='rounded flex items-center justify-center bg-gray-100 w-[70px] h-[70px]' style={{
                                backgroundImage: `url(${(module.module?.coverImage)}`,
                                backgroundPosition: 'center center',
                                backgroundSize: 'cover',
                                position: 'relative'
                              }}>
                          </div>
                        </div>
                        <div className='w-full'>
                          <p className='text-sm text-gray-700 font-[550]'>{module.module?.title} <span className='text-xs text-gray-500'>({module.module?.estimatedTime} minutes)</span></p>
                          <div className='my-1 relative'>
                            <p className='text-xs'>{module.module?.description}</p>
                          </div>
                          <button onClick={()=>{getTrainingModule(module.module._id)}} className='text-xs text-gray-700 font-[550] flex items-center gap-x-2 hover:text-verovian-purple duration-200 transition mt-3'>More details <ChevronIcon className={`w-4 h-4 ${activeModuleDetails?._id === module.module._id ? '' : '-rotate-90'}`}/></button>

                          {loadingModuleDetails === module.module._id && 
                            <div><Preloader /></div>
                          }
                          {activeModuleDetails && activeModuleDetails._id === module.module._id && 
                            <div className='w-full mt-[20px]'>
                              {activeModuleDetails.materials.map((material, materialIndex) => (
                                <div key={materialIndex}>
                                  <div className={`flex items-start gap-x-2 mb-[20px] cursor-pointer w-full`}>
                                    <div className='w-[80px]'>
                                      <div className='rounded flex items-center justify-center bg-gray-100 w-[70px] h-[70px]'>
                                        {material.material?.content?.contentType === 'VIDEO' && <VideoIcon className={`w-5 h-5 text-gray-500 mt-[5px]`} />}
                                        {material.material?.content?.contentType === 'AUDIO' && <AudioIcon className={`w-5 h-5 text-gray-500 mt-[5px]`} />}
                                        {material.material?.content?.contentType === 'INFOGRAPHIC' && <PhotoIcon className={`w-5 h-5 text-gray-500 mt-[5px]`} />}
                                        {(material.material?.content?.contentType === 'TEXT' || material.material?.content?.contentType === 'DOCUMENT')  && <TextIcon className={`w-5 h-5 text-gray-500 mt-[5px]`} />}
                                      </div>
                                    </div>
                                    <div className='w-full'>
                                      <p className='text-sm text-gray-700 font-medium'>{material.material?.title} <span className='text-xs text-gray-500'>({material.material?.estimatedTime} minutes)</span></p>
                                      <div className='my-1'>
                                        <p className='text-xs text-gray-500'>{material.material?.description}</p>
                                      </div>
                                      {material.material.instructor.name && <p className='text-xs text-gray-500'>By {material.material?.instructor?.name}</p>}
                                    </div>
                                  </div>
                                </div>
                              ))}

                              {activeModuleDetails.assessment && activeModuleDetails.assessment !== null &&
                                <div>
                                  <h4 className='text-xs tracking-[0.1em] mb-[10px]'>ASSESSMENT</h4>
                                  <div className={`flex items-start gap-x-2 mb-[20px] cursor-pointer w-full`}>
                                    <div className='w-full'>
                                      <p className='text-sm text-gray-700 font-medium mb-1'>{activeModuleDetails.assessment?.title}</p>
                                      <div className='my-1'>
                                        <p className='text-xs text-gray-500'>{activeModuleDetails.assessment?.description}</p>
                                      </div>
                                      <p className='text-xs text-gray-500 mt-1'>{activeModuleDetails.assessment?.questions?.length} questions</p>
                                    </div>
                                  </div>
                                </div>
                              }
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>



            </div>
        }
        </TrainingsLayout>
    )
}

export default Training
import React, { useEffect, useState } from 'react'
import ReviewsManagementLayout from '../../../../components/layout/ReviewsManagementLayout'
import Preloader from '../../../../components/elements/Preloader'
import { useDispatch, useSelector } from 'react-redux'
import PlusIcon from '../../../../components/elements/icons/PlusIcon'
import ReviewQuestionCard from '../../../../components/partials/reviews-management/ReviewQuestionCard'
import Pagination from '../../../../components/elements/Pagination'
import EmptyState from '../../../../components/elements/icons/EmptyState'
import { clearDeletedReviewQuestion, deleteReviewQuestion, getReviewQuestions } from '../../../../store/actions/reviewsActions'
import { Link } from 'react-router-dom'
import { SET_SUCCESS_MESSAGE } from '../../../../store/types'

const ReviewQuestions = () => {
    const reviewsSelector = useSelector(state => state.reviews)
    const dispatch = useDispatch()
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(25);

    useEffect(() => {
        dispatch(getReviewQuestions(page, perPage))
        if(reviewsSelector.deletedReviewQuestion !== null){
            dispatch({
                type: SET_SUCCESS_MESSAGE,
				payload: {
					successMessage: 'Review question deleted'
				}
            })
            dispatch(clearDeletedReviewQuestion())
        }
        return () => {
            
        };
    }, [dispatch, page, perPage, reviewsSelector.deletedReviewQuestion]);

    // const [creatingReviewQuestion, setCreatingReviewQuestion] = useState(false);

    const deleteQuestion = (id) => {
        if(window.confirm('Are you sure you want to delete this review question?')) {
            dispatch(deleteReviewQuestion(id))
        }
    }

    return (
        <ReviewsManagementLayout sectionTitle={`Review Questions`}>
            <div className=''>
                <div className='w-full lg:flex flex-row gap-x-8'>
                    <div className='w-full pb-6 mx-auto px-12'>
                        <div className='flex flex-row-reverse justify-between items-center mt-4 mb-4'>
                            <Link to={`new`} className='flex gap-x-2 items-center rounded bg-verovian-purple px-3 py-3 text-verovian-light-purple text-sm transition duration-200 hover:bg-gray-600'>
                                <PlusIcon className={`h-5 w-5`} />
                                Create review question
                            </Link>
                        </div>

                        {reviewsSelector.loadingReviewQuestions ? 
                            <Preloader preloadingText={`Loading review questions`} /> 
                        :   
                            <>
                                {reviewsSelector.reviewQuestions?.questions?.length > 0 ?
                                    <>                                        
                                        <div className='w-full'>
                                            {reviewsSelector?.reviewQuestions?.questions?.map((question, questionIndex)=>(
                                                <div key={questionIndex} className="bg-white my-[20px]">
                                                    <ReviewQuestionCard 
                                                        question={question} 
                                                        doDelete={()=>{deleteQuestion(question._id)}} 
                                                    />
                                                </div>
                                            ))}
                                        </div>

                                        <Pagination 
                                            pagination={{
                                                currentPage: page,
                                                perPage: perPage,
                                                totalItems: reviewsSelector?.reviewQuestions?.total
                                            }}
                                            changePage={(value)=>{setPage(value)}}
                                            updatePerPage={(value)=>{setPerPage(value)}}
                                        />
                                    </>
                                    :
                                    <EmptyState emptyStateText={`No review questions created yet, use the "create review question" button above to create a new one.`} />
                                }
                            </>
                        }

                    </div>
                </div>
            </div>
        </ReviewsManagementLayout>
    )
}

export default ReviewQuestions
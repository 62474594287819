import { Dialog, Transition } from '@headlessui/react'
import React, {Fragment} from 'react'
import CloseIconCircled from '../elements/icons/CloseIconCircled'

const ModalLayout = ({children, dialogTitle, dialogIntro, isOpen, closeModal, maxWidthClass}) => {
    return (
    <Transition appear show={isOpen} as={Fragment}>
        <Dialog
            as="div"
            className="fixed inset-0 overflow-y-auto"
            onClose={closeModal}
            style={{zIndex: 997}}
        >
            <div className="min-h-screen px-4 text-center">
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-90" />
                </Transition.Child>

                {/* This element is to trick the browser into centering the modal contents. */}
                <span
                    className="inline-block h-screen align-middle"
                    aria-hidden="true"
                >
                &#8203;
                </span>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <div className={`relative inline-block w-full ${maxWidthClass}  my-8 overflow-hidden text-left align-middle transition-all transform bg-transparent shadow-xl rounded-2xl`}>
                        <div className='flex flex-row-reverse items-start gap-x-[10px] justify-between pb-[10px]'>
                            <div className='w-[50px] h-[50px] flex items-center justify-center'>
                                <button className='rounded-full flex items-center justify-center bg-white bg-opacity-10 hover:bg-opacity-5 transition duration-200 active:bg-transparent text-white w-[40px] h-[40px]' onClick={()=>{closeModal()}}>
                                    <CloseIconCircled className="h-8 w-8" />
                                </button>
                            </div>
                            <h3
                                className=" font-medium leading-6 text-white text-left mb-4 pt-2"
                            >
                                {dialogTitle}<br/>
                                {dialogIntro && dialogIntro !== '' && <span className='text-xs inline-block mt-1 font-normal'>{dialogIntro}</span>}
                            </h3>
                        </div>
                        <div className="p-5 bg-white rounded-xl" style={{zIndex: 998}}>
                            {children}
                        </div>

                    </div>
                </Transition.Child>
            </div>
        </Dialog>
    </Transition>
    
    )
}

export default ModalLayout
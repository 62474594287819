import React, { useState } from 'react'
import UserLayout from '../../components/layout/UserLayout'
import { userDetails } from '../../utils'
import TaskCard from '../../components/partials/tasks/TaskCard';
import Donut from '../../components/elements/Donut';
import EmptyState from '../../components/elements/icons/EmptyState';
import PendingReviews from '../../components/partials/home/PendingReviews';

const Home = () => {
  const [performanceTotal, setPerformanceTotal] = useState(45);
  const [monthlyPerformances, setMonthlyPerformances] = useState([12, 12, 18, 40, 11, 2, 6]);

  const tasks = [
    {
      title: "Lorem ipsum dolor sit amet",
      description: "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni;",
      status: 'IN_PROGRESS',
      comments: 3,
      priority: 'high',
      dueDate: "25/11/2024",
      createdAt: new Date()
    },
    {
      title: "Lorem ipsum dolor sit amet",
      description: "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni;",
      status: 'IN_PROGRESS',
      comments: 3,
      priority: 'normal',
      dueDate: "25/11/2024",
      createdAt: new Date()
    },
    {
      title: "Lorem ipsum dolor sit amet",
      description: "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni;",
      status: 'IN_PROGRESS',
      comments: 3,
      priority: 'normal',
      dueDate: "25/11/2024",
      createdAt: new Date()
    },
    {
      title: "Lorem ipsum dolor sit amet",
      description: "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni;",
      status: 'IN_PROGRESS',
      comments: 3,
      priority: 'normal',
      dueDate: "25/11/2024",
      createdAt: new Date()
    },
  ]

  const upcomingTasks = [
    {
      title: "Lorem ipsum dolor sit amet",
      description: "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni;",
      status: 'BACKLOG',
      comments: 3,
      priority: 'high',
      dueDate: "25/11/2024",
      createdAt: new Date()
    },
    {
      title: "Lorem ipsum dolor sit amet",
      description: "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni;",
      status: 'BACKLOG',
      comments: 3,
      priority: 'normal',
      dueDate: "25/11/2024",
      createdAt: new Date()
    }
  ]

  const months = ['Jan 2024', 'Feb 2024', 'Mar 2024', 'Apr 2024', 'May 2024', 'Jun 2024', 'Jul 2024']

  const storeSalesData = [
    {
        label: 'Completed',
        amount: 41,
        colour: '#00D78C'
    },
    {
        label: 'in Progress',
        amount: 17,
        colour: '#6D3ADD'
    },
    {
        label: 'Backlog',
        amount: 15,
        colour: '#e3e3e3'
    },
]

const cleanUpChartData = (data) => {
    const labels = []
    const series = []
    const colours = []

    data.forEach((item) => {
        labels.push(item.label)
        series.push(item.amount)
        colours.push(item.colour)
    });

    return {
        labels, series, colours
    }
}

  return (
    <UserLayout pageTitle={`Home`}>
      <div className='w-full flex items-start gap-x-6 mb-[100px]'>
        <div className='w-8/12'>
          <div className='w-full rounded-[8px] bg-verovian-purple py-[40px] min-h-[150px] h-inherit px-[50px] mt-[32px]'>
            <p className='text-[24px] font-[600] text-white'>Welcome, {userDetails()?.firstName}</p>
            <p className='text-white text-sm mt-[10px] mb-[10px]'>You have <span className='text-yellow-500'>0 new tasks</span> to attend to</p>

            <button className='rounded-[8px] bg-white text-verovian-purple text-sm px-[18px] py-[12px]'>Go to Task Management</button>
          </div>

          <div className='w-full p-[10px] rounded bg-gray-200 mt-[20px] mb-[10px]'>
            <h3 className='text-verovian-purple text-[16px] font-[500]'>Monthly Performance</h3>
          </div>

          <div className='bg-white rounded-[8px] p-[40px]'>
          <EmptyState emptyStateText={`No performance data yet`} />
            {/* <div className="relative w-full h-[300px] flex flex-row-reverse justify-between items-end gap-x-[4px]">
              {monthlyPerformances.map((item, index) => (
                <div
                  key={index}
                  onMouseEnter={() => {}}
                  onMouseLeave={() => {}}
                  className="w-[54px] border-t border-verovian-purple bar bar-gradient transition duration-200"
                  style={{
                    '--final-height': `${(item / performanceTotal) * 100}%`,
                  }}
                ></div>
              ))}
            </div>
            <div className="relative w-full h-[50px] flex justify-between items-end gap-x-[4px]">
              {months.map((month, monthIndex)=>(
                <div key={monthIndex} className="">
                  <p className='text-xs text-gray-500'>{month}</p>
                </div>
              ))}
            </div> */}
          </div>

          <div className='w-full p-[10px] rounded bg-gray-200 mt-[20px] mb-[10px]'>
            <h3 className='text-verovian-purple text-[16px] font-[500]'>Ongoing Tasks</h3>
          </div>

          <div className='bg-white rounded-[8px] p-[40px]'>
            <EmptyState emptyStateText={`No tasks assigned to you yet`} />
            {/* <div className='w-full grid grid-cols-2 gap-[20px]'>
              {tasks.map((task, taskIndex) => (
                <div key={taskIndex} className='w-full'>
                  <TaskCard task={task} />
                </div>
              ))}
            </div> */}
          </div>

          
        </div>

        {/* Right sidebar */}
        <div className='w-4/12'>
          <div className='w-full p-[10px] rounded bg-gray-200 mt-[20px] mb-[10px]'>
            <h3 className='text-verovian-purple text-[16px] font-[500]'>upcoming Tasks</h3>
          </div>
          <div className='w-full bg-white p-[20px]'>
            <EmptyState emptyStateText={`No tasks assigned to you yet`} />

            {/* {upcomingTasks.map((task, taskIndex) => (
              <div key={taskIndex} className='w-full my-[20px]'>
                <TaskCard task={task} />
              </div>
            ))} */}
          </div>
          
          <PendingReviews />

          <div className='w-full p-[10px] rounded bg-gray-200 mt-[20px] mb-[10px]'>
            <h3 className='text-verovian-purple text-[16px] font-[500]'>Activity Progress</h3>
          </div>
          <div className='w-full p-[20px] bg-white'>
          <EmptyState emptyStateText={`No activity data yet`} />

            {/* <Donut showLegend={true} 
                showDataLabels={false} 
                size="55%" 
                series={cleanUpChartData(storeSalesData).series} 
                colours={cleanUpChartData(storeSalesData).colours} 
                labels={cleanUpChartData(storeSalesData).labels} /> */}
          </div>
          <div className='w-full p-[10px] rounded bg-gray-200 mt-[20px] mb-[10px]'>
            <h3 className='text-verovian-purple text-[16px] font-[500]'>Holiday Allowance</h3>
          </div>
          <div className='p-[20px] bg-white'>
            {/* <p className='text-sm mt-4'><span className='text-2xl text-white font-medium p-[10px] bg-verovian-purple'>4</span> days left out of <span className='text-gray-400'>28</span></p> */}
          </div>
        </div>
      </div>
    </UserLayout>
  )
}

export default Home
import React, { useEffect, useState } from 'react'
import UserLayout from '../../../components/layout/UserLayout'
import InventoryLayout from '../../../components/layout/InventoryLayout'
import FormButton from '../../../components/elements/form/FormButton'
import TextField from '../../../components/elements/form/TextField'
import TextareaField from '../../../components/elements/form/TextareaField'
import { useDispatch, useSelector } from 'react-redux'
import { ERROR } from '../../../store/types'
import { createInventoryItem, getInventoryItemCategories } from '../../../store/actions/inventoryActions'
import AutocompleteSelect from '../../../components/elements/form/AutocompleteSelect'
import RadioGroup from '../../../components/elements/form/RadioGroup'
import TrashIcon from '../../../components/elements/icons/TrashIcon'
import NumberField from '../../../components/elements/form/NumberField'
import PlusIcon from '../../../components/elements/icons/PlusIcon'
import DateField from '../../../components/elements/form/DateField'
import Preloader from '../../../components/elements/Preloader'
import FileUpload from '../../../components/elements/form/FileUpload'

const NewInventoryItem = () => {
    const [itemPayload, setItemPayload] = useState({});
    const [validationErrors, setValidationErrors] = useState({});
    const inventorySelector = useSelector(state => state.inventory)

    const variantObject = {
        name: '',
        description: '',
        cost: 0,
        serialNumber: '',
        condition: '',
        age: '',
    }

    const [itemVariants, setItemVariants] = useState([variantObject]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getInventoryItemCategories(0, 0))
        return () => {
            
        };
    }, [dispatch]);

    const validateForm = () => {
        let errors = {}
        
        if(!itemPayload.name || itemPayload.name === ''){
            errors.name = true
        }
        
        if(!itemPayload.description || itemPayload.description === ''){
            errors.description = true
        }

        if(!itemPayload.category || itemPayload.category === ''){
            errors.category = true
        }

        itemVariants.forEach((variant, variantIndex) => {
            if(!variant.name || variant.name === ''){
                errors[`field-${variantIndex}-name`] = true
            }

            if(selectedCategory?.additionalVariantFields && selectedCategory?.additionalVariantFields.length > 0){
                selectedCategory.additionalVariantFields.forEach((field, fieldIndex) => {
                    const indexOfField = variant.additionalFields.findIndex(vfield => vfield.slug === field.slug)
                    if(!field.optional && (indexOfField < 0 || variant.additionalFields[indexOfField].value === '')) {
                        errors[`field-${variantIndex}-${fieldIndex}-${field.slug}`] = true
                    }
                })
            }
        })

        setValidationErrors(errors)
        console.log('form errors: ', errors)
        return errors
    }

    const [file, setFile] = useState();

    const handleUpload = async (file) => {
        console.log(file)
    
        var formData = new FormData()
        formData.append('file', file.file )
        const headers = new Headers();
    
        try {
            const doUpload = await fetch(`${process.env.REACT_APP_API_URL}/files/new`, {
                method: "POST",
                headers,
                body: formData,
            });
            const response = await doUpload.json();
        
            return response
        } catch (error) {
        dispatch({
            type: ERROR,
            error,
        });
        }
    }

    const pushItem = async () => {
        if (Object.values(validateForm()).includes(true)) {
            dispatch({
                type: ERROR,
                error: {response: {data: {
                    message: 'Please check the highlighted fields'
                }}}
            });
            return
        }
        const payload = {
            ...itemPayload,
            variants: itemVariants
        }

        if(file) {
            const uploaded = await handleUpload(file)
            payload.photos = [uploaded.data.file]
        }
        
        dispatch(createInventoryItem(payload))
    }


    const variantConditions = [
        {
            label: 'New',
            value: 'new'
        },
        {
            label: 'Used',
            value: 'used'
        },
        {
            label: 'Refurbished',
            value: 'refurbished'
        },
    ]


    const itemTypes = [
        {
            label: 'Deductible',
            value: 'deductible',
            description: 'Deductible items will have a stock that can be deducted during assignments and re-stocked'
        },
        {
            label: 'Non-deductible',
            value: 'nod-deductible',
            description: 'These items will not have a stock and each variant will only be transferable during assignments'
        }
    ]

    const addVariant = () => {
        let temp = [...itemVariants]
        temp.push(variantObject)
        setItemVariants(temp)
    }
    
    const updateVariant = (index, field, value) => {
        let temp = [...itemVariants]
        if(field === 'additionalFields') {
            let fields = temp[index]?.additionalFields ? temp[index].additionalFields : []
            fields.push(value)
            temp[index].additionalVariantFields = fields
        } else {
            temp[index][field] = value
        }
        setItemVariants(temp)
    }
    
    const deleteVariant = (index) => {
        let temp = [...itemVariants]
        temp.splice(index, 1)
        setItemVariants(temp)
    }

    return (
        <UserLayout pageTitle={`Inventory`}>
            <InventoryLayout sectionTitle={`New Inventory Items`}>
                <div className='w-2/3 p-[20px] mt-[20px] bg-white rounded-[8px]'>
                    <div className='w-full'>
                        <div  className='w-full'>
                            <p className="text-sm font-[500] text-gray-600">
                                Item Details
                            </p>
                            <p className='text-xs text-gray-700 mt-2'>Please provide details of the inventory item below to create a new one.</p>
                        </div>
                        <div className='w-full'>
                            <div className='mb-4 w-full'>
                                <TextField
                                    inputLabel="Item name" 
                                    fieldId="document-name" 
                                    inputType="text" 
                                    preloadValue={itemPayload.name || ''}
                                    hasError={validationErrors.name} 
                                    returnFieldValue={(value)=>{setItemPayload({...itemPayload, ...{name: value}})}}
                                />
                            </div>          
                        
                            <div className='mb-4 w-full'>
                                <TextareaField
                                    inputLabel="Description" 
                                    fieldId="dept-description" 
                                    inputType="text" 
                                    preloadValue={itemPayload.description || ''}
                                    hasError={validationErrors.description} 
                                    returnFieldValue={(value)=>{setItemPayload({...itemPayload, ...{description: value}})}}
                                />
                            </div> 

                            <div className='mb-4 w-full'>
                                <RadioGroup
                                    inputLabel="Item type"
                                    items={itemTypes} 
                                    inline={false}
                                    hasError={validationErrors.itemType} 
                                    returnSelected={(value)=>{setItemPayload({...itemPayload, ...{itemType: value.value}})}}
                                />
                            </div>

                            <div className='mb-4 w-full'>
                                {inventorySelector.loadingCategories && 
                                    <Preloader />
                                }
                                {!inventorySelector.loadingCategories && inventorySelector.categories?.categories?.length > 0 && <AutocompleteSelect
                                    selectOptions={inventorySelector.categories.categories}
                                    inputLabel="Select Item Category"
                                    titleField="name"
                                    displayImage={false}
                                    imageField=""
                                    preSelected=''
                                    fieldId="item-category"
                                    hasError={false}
                                    // return id of accounts of the selected option
                                    returnFieldValue={(value) => {
                                        setItemPayload({...itemPayload, ...{category: value._id}})
                                        setSelectedCategory(value)
                                    }}
                                />}
                                {!inventorySelector.loadingCategories && inventorySelector.categories?.categories?.length === 0 &&
                                    <p className='text-xs text-red-500 p-[10px] bg-red-50 rounded border border-red-600'>No categories created yet. Create some first before creating items</p>
                                }
                            </div>  

                            <div className='w-full pb-4'>
                                <FileUpload
                                    hasError={false}
                                    fieldLabel={`Item Photo`}
                                    returnFileDetails={(details)=>{
                                        setFile(details)
                                    }}
                                    acceptedFormats={['jpeg', 'jpg', 'png']}
                                />
                                <label className='block mt-3 text-xs text-gray-600 leading-[1.4em]'>Please add a good and descriptive image of the item if required</label>
                            </div>

                            <div className='border rounded-md p-5 bg-gray-100 bg-opacity-20 mt-[40px]'>
                                <p className='text-md text-gray-700 font-medium'>Item Variants</p>
                                <p className='text-xs text-gray-500 mt-1'>Variants are every specification/type of the item. Create a single variant for items with one type.</p>

                                {itemVariants.map((variant, variantIndex)=>(
                                    <div key={variantIndex} className='pt-[10px] mt-[10px] border-t mb-4'>
                                        <div className='w-full flex items-center justify-between mt-[20px] mb-[10px]'>
                                            <p className='uppercase tracking-[0.2em] text-[10px] font-[500]'>Variant {variantIndex + 1}</p>
                                            {variantIndex > 0 && <button onClick={()=>{deleteVariant(variantIndex)}} className='w-[30px] h-[30px] text-gray-500 hover:text-red-600 flex items-center justify-center bg-transparent hover:bg-gray-200 rounded transition duration-200'>
                                                <TrashIcon className={`w-5 h-5`} />                
                                            </button>}
                                        </div>
                                        <div className='mb-4 w-full'>
                                            <TextField
                                                inputLabel="Variant name" 
                                                fieldId={`variant-${variantIndex}-name`}
                                                inputType="text" 
                                                preloadValue={variant.name || ''}
                                                hasError={validationErrors[`variant-${variantIndex}-name`]} 
                                                returnFieldValue={(value)=>{updateVariant(variantIndex, 'name', value)}}
                                            />
                                        </div> 
                                        <div className='mb-4 w-full'>
                                            <TextField
                                                inputLabel="Variant description" 
                                                fieldId={`variant-${variantIndex}-description`}
                                                inputType="text" 
                                                preloadValue={variant.name || ''}
                                                hasError={validationErrors[`variant-${variantIndex}-description`]} 
                                                returnFieldValue={(value)=>{updateVariant(variantIndex, 'description', value)}}
                                            />
                                        </div> 
                                        <div className='mb-4 w-full'>
                                            <RadioGroup
                                                inputLabel="Condition"
                                                items={variantConditions} 
                                                inline={true}
                                                coverWidth={true}
                                                hasError={validationErrors[`variant-${variantIndex}-condition`]} 
                                                returnSelected={(value)=>{updateVariant(variantIndex, 'description', value)}}
                                            />
                                        </div>
                                        {variant.condition !== 'new' && <div className='mb-4 w-full'>
                                            <NumberField
                                                inputLabel="Age" 
                                                fieldId={`variant-${variantIndex}-age`}
                                                inputType="text" 
                                                preloadValue={variant.cost || ''}
                                                hasError={validationErrors[`variant-${variantIndex}-age`]} 
                                                returnFieldValue={(value)=>{updateVariant(variantIndex, 'age', value)}}
                                            />
                                        </div>} 
                                        <div className='mb-4 w-full'>
                                            <TextField
                                                inputLabel="Serial Number (if available)" 
                                                fieldId={`variant-${variantIndex}-sn`}
                                                inputType="text" 
                                                preloadValue={variant.name || ''}
                                                hasError={validationErrors[`variant-${variantIndex}-sn`]} 
                                                returnFieldValue={(value)=>{updateVariant(variantIndex, 'serialNumber', value)}}
                                            />
                                        </div> 
                                        <div className='mb-4 w-full'>
                                            <NumberField
                                                inputLabel="Item Cost" 
                                                fieldId={`variant-${variantIndex}-cost`}
                                                inputType="text" 
                                                preloadValue={variant.cost || ''}
                                                hasError={validationErrors[`variant-${variantIndex}-cost`]} 
                                                returnFieldValue={(value)=>{updateVariant(variantIndex, 'cost', value)}}
                                            />
                                        </div> 


                                        {selectedCategory && selectedCategory.additionalVariantFields && selectedCategory.additionalVariantFields.length > 0 && 
                                            selectedCategory.additionalVariantFields.map((field, fieldIndex) => (
                                                <div key={fieldIndex} className='w-full'>
                                                {/* 'date', 'textarea', 'number', 'text' */}
                                                    {field.valueType === 'text' && <div className='w-full mt-4'>
                                                        <TextField
                                                            inputLabel={field.label} 
                                                            fieldId={`variant-${variantIndex}-${field.slug}`}
                                                            inputType="text" 
                                                            preloadValue={variant.name || ''}
                                                            hasError={validationErrors[`variant-${variantIndex}-${fieldIndex}-${field.slug}`]} 
                                                            returnFieldValue={(value) => {
                                                                updateVariant(variantIndex, 'additionalFields', {
                                                                    slug: field.slug,
                                                                    value: value
                                                                })}
                                                            }
                                                        />
                                                    </div>}
                                                    {field.valueType === 'textarea' && <div className='w-full mt-4'>
                                                        <TextareaField
                                                            inputLabel={field.label} 
                                                            fieldId={`variant-${variantIndex}-${field.slug}`}
                                                            inputType="text" 
                                                            preloadValue={variant.name || ''}
                                                            hasError={validationErrors[`variant-${variantIndex}-${fieldIndex}-${field.slug}`]} 
                                                            returnFieldValue={(value) => {
                                                                updateVariant(variantIndex, 'additionalFields', {
                                                                    slug: field.slug,
                                                                    value: value
                                                                })}
                                                            }
                                                        />
                                                    </div>}
                                                    {field.valueType === 'number' && <div className='w-full mt-4'>
                                                        <NumberField
                                                            inputLabel={field.label} 
                                                            fieldId={`variant-${variantIndex}-${field.slug}`}
                                                            inputType="text" 
                                                            preloadValue={variant.cost || ''}
                                                            hasError={validationErrors[`variant-${variantIndex}-${fieldIndex}-${field.slug}`]} 
                                                            returnFieldValue={(value) => {
                                                                updateVariant(variantIndex, 'additionalFields', {
                                                                    slug: field.slug,
                                                                    value: value
                                                                })}
                                                            }
                                                        />
                                                    </div>}
                                                    {field.valueType === 'date' && <div className='w-full mt-4'>
                                                        <DateField
                                                            inputLabel={field.label} 
                                                            inputPlaceholder="" 
                                                            fieldId={`variant-${fieldIndex}-${field.slug}`} 
                                                            inputType="date" 
                                                            hasError={validationErrors[`variant-${variantIndex}-${fieldIndex}-${field.slug}`]} 
                                                            returnFieldValue={(value) => {
                                                                updateVariant(variantIndex, 'additionalFields', {
                                                                    slug: field.slug,
                                                                    value: new Date(value)
                                                                })}
                                                            }
                                                        />
                                                    </div>}
                                                    {field.description && field.description !== '' && <label className='block mt-1 text-xs text-gray-500'>
                                                        {field.description}
                                                    </label>}
                                                </div>
                                            ))
                                        }

                                        <div className='flex flex-row-reverse mt-[20px]'>
                                            <button onClick={()=>{addVariant()}} className='flex items-center justify-center font-[500] text-xs gap-x-[5px] border border-verovian-purple bg-verovian-light-purple bg-opacity-50 text-verovian-purple rounded p-[10px]'>
                                                <PlusIcon className={`w-5 h-5`} />
                                                Add another variant
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className='my-8 flex flex-row-reverse items-center justify-between pt-8 border-t'>
                        <div className='min-w-[200px]'>
                            <FormButton buttonLabel={`Create Category`} buttonAction={()=>{pushItem()}} processing={inventorySelector.creatingItem} />
                        </div>
                    </div>
                </div>
            </InventoryLayout>
        </UserLayout>
    )
}

export default NewInventoryItem
import React, { useEffect, useState } from 'react'
import ReviewsManagementLayout from '../../../../components/layout/ReviewsManagementLayout'
import { useDispatch, useSelector } from 'react-redux'
import { getReviews } from '../../../../store/actions/reviewsActions'
import Preloader from '../../../../components/elements/Preloader'
import EmptyState from '../../../../components/elements/icons/EmptyState'
import Pagination from '../../../../components/elements/Pagination'
import EmployeeReviewFormCard from '../../../../components/partials/employees/EmployeeReviewFormCard'
import FilterSlidesIcon from '../../../../components/elements/icons/FilterSlidesIcon'
import ChevronIcon from '../../../../components/elements/icons/ChevronIcon'

const Reviews = () => {
  const reviewsSelector = useSelector(state => state.reviews)
  const dispatch = useDispatch()
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(25);

  useEffect(() => {
      dispatch(getReviews(page, perPage))

      return () => {
          
      };
  }, [dispatch, page, perPage, reviewsSelector.deletedReviewForm]);

  const [activeOpenFilter, setActiveOpenFilter] = useState('');

  const applyFilter = () => {

  }

  const searchForEmployee = (term) => {

  }

  const searchForForm = (term) => {
    
  }

  
  return (
    <ReviewsManagementLayout sectionTitle={`Reviews`}>
         <div className=''>
        <div className='w-full lg:flex flex-row gap-x-8'>
          <div className='w-full pb-6 mx-auto'>
            <div className='flex flex-row-reverse justify-between items-center mt-4 mb-4'>
              {/* <Link to={`new`} className='flex gap-x-2 items-center rounded bg-verovian-purple px-3 py-3 text-verovian-light-purple text-sm transition duration-200 hover:bg-gray-600'>
                <PlusIcon className={`h-5 w-5`} />
                Create review form
              </Link> */}
            </div>

            {reviewsSelector.loadingReviews ? 
              <Preloader preloadingText={`Loading reviews`} /> 
            :   
              <>
                {reviewsSelector.reviews?.reviews?.length > 0 ?
                    <> 
                      <div className='flex items-center gap-x-[5px] mb-[20px]'>
                        <label className='text-sm text-gray-500 mb-[10px] flex items-center gap-x-[5px] mr-[10px]'><FilterSlidesIcon className={`w-5 h-5`} /> Filters</label>
                        <div className='relative'>
                          <button className='text-xs bg-gray-100 transition duration-200 hover:bg-gray-200 text-gray-600 rounded p-[10px] -mt-[10px] font-[500] flex items-center justify-center gap-x-[5px]'>Employee <ChevronIcon className={`w-3 h-3`} stroke={3} /></button>
                        </div>
                        <div className='relative'>
                          <button className='text-xs bg-gray-100 transition duration-200 hover:bg-gray-200 text-gray-600 rounded p-[10px] -mt-[10px] font-[500] flex items-center justify-center gap-x-[5px]'>Reviewer <ChevronIcon className={`w-3 h-3`} stroke={3} /></button>
                        </div>
                        <div className='relative'>
                          <button className='text-xs bg-gray-100 transition duration-200 hover:bg-gray-200 text-gray-600 rounded p-[10px] -mt-[10px] font-[500] flex items-center justify-center gap-x-[5px]'>Review Form <ChevronIcon className={`w-3 h-3`} stroke={3} /></button>
                        </div>
                        <div className='relative'>
                          <button className='text-xs bg-gray-100 transition duration-200 hover:bg-gray-200 text-gray-600 rounded p-[10px] -mt-[10px] font-[500] flex items-center justify-center gap-x-[5px]'>Date Submitted <ChevronIcon className={`w-3 h-3`} stroke={3} /></button>
                        </div>
                      </div>                                       
                      <div className='w-full grid grid-cols-2 xl:grid-cols-3 gap-2'>
                        {reviewsSelector?.reviews?.reviews?.map((review, reviewIndex)=>(
                          <div key={reviewIndex} className="bg-white">
                              <EmployeeReviewFormCard 
                                form={review.form} 
                                review={review} 
                                // progress={reviewProgresses[reviewIndex]} 
                              />
                          </div>
                        ))}
                      </div>

                      <Pagination
                        pagination={{
                          currentPage: page,
                          perPage: perPage,
                          totalItems: reviewsSelector?.reviews?.total
                        }}
                        changePage={(value)=>{setPage(value)}}
                        updatePerPage={(value)=>{setPerPage(value)}}
                      />
                  </>
                  :
                  <EmptyState emptyStateText={`No reviews created yet.`} />
                }
              </>
            }
          </div>
        </div>
      </div>
    </ReviewsManagementLayout>
  )
}

export default Reviews

import { CREATE_ONBOARDING_PACKAGE, CREATING_ONBOARDING_PACKAGE, DELETE_ONBOARDING_PACKAGE, DELETING_ONBOARDING_PACKAGE, GET_ONBOARDING_PACKAGES, GETTING_ONBOARDING_PACKAGES, ONBOARDING_PACKAGES_ERROR, UPDATE_ONBOARDING_PACKAGE, UPDATING_ONBOARDING_PACKAGE } from "../types"

const initialState = {
    onboardingPackages: [],
    loadingOnboardingPackages: true,
    onboardingPackagesError: null,
    creatingOnboardingPackage: false,
    createdOnboardingPackage: null,
    updatingOnboardingPackage: false,
    updatedOnboardingPackage: null,
    deletingOnboardingPackage: false,
    deletedOnboardingPackage: null
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action){

    switch(action.type){
        case GETTING_ONBOARDING_PACKAGES:
        return {
            ...state,
            loadingOnboardingPackages: action.payload,
            // fetchingMembers:false
        }
        case GET_ONBOARDING_PACKAGES:
        return{
            ...state,
            loadingOnboardingPackages:false,
            onboardingPackagesError: false,
            onboardingPackages: action.payload,
        }
        case CREATING_ONBOARDING_PACKAGE:
        return {
            ...state,
            creatingOnboardingPackage: action.payload,
        }
        case CREATE_ONBOARDING_PACKAGE:
        return{
            ...state,
            creatingOnboardingPackage: false,
            onboardingPackagesError: false,
            createdOnboardingPackage: action.payload,
        }
        case UPDATING_ONBOARDING_PACKAGE:
        return {
            ...state,
            updatingOnboardingPackage: action.payload,
        }
        case UPDATE_ONBOARDING_PACKAGE:
        return{
            ...state,
            updatingOnboardingPackage: false,
            onboardingPackagesError: false,
            updatedOnboardingPackage: action.payload,
        }
        case DELETING_ONBOARDING_PACKAGE:
        return {
            ...state,
            deletingOnboardingPackage: action.payload,
        }
        case DELETE_ONBOARDING_PACKAGE:
        return{
            ...state,
            deletingOnboardingPackage: false,
            onboardingPackagesError: false,
            deletedOnboardingPackage: action.payload,
        }
        case ONBOARDING_PACKAGES_ERROR:
        return{
            ...state,
            loadingOnboardingPackages:false,
            creatingOnboardingPackage:false,
            onboardingPackagesError: action.payload 
        }
        default: return state
    }

}
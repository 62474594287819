import React, { useEffect, useState } from 'react'
import UserLayout from '../../../components/layout/UserLayout'
import TextField from '../../../components/elements/form/TextField';
import { useDispatch, useSelector } from 'react-redux';
import { fetchShifts } from '../../../store/actions/shiftActions';
import { fetchDepartments } from '../../../store/actions/departmentActions';
import { fetchDesignations } from '../../../store/actions/designationActions';
import { fetchGroups } from '../../../store/actions/groupActions';
import RadioGroup from '../../../components/elements/form/RadioGroup';
import { Switch } from '@headlessui/react';
import SelectField from '../../../components/elements/form/SelectField';
import FormButton from '../../../components/elements/form/FormButton';
import { ERROR, SET_SUCCESS_MESSAGE } from '../../../store/types';
import { clearInvitedEmployee, fetchEmployees, inviteEmployee } from '../../../store/actions/employeeActions';
import { sanitizePayload, slugify } from '../../../utils';
import TrashIcon from '../../../components/elements/icons/TrashIcon';
import { fetchDocuments } from '../../../store/actions/documentActions';
import { useNavigate } from 'react-router-dom';
import DateField from '../../../components/elements/form/DateField';
import Preloader from '../../../components/elements/Preloader';
// import { getReviewForms } from '../../../store/actions/reviewsActions';
import { fetchTasks } from '../../../store/actions/tasksActions';
import { fetchPermissions } from '../../../store/actions/rolesPermissionsActions';
import ModalLayout from '../../../components/layout/ModalLayout';
import OnboardingPackageSelector from '../../../components/partials/onboarding-packages/OnboardingPackageSelector';
import { fetchOnboardingPackages } from '../../../store/actions/onboardingPackagesActions';
import PlusIcon from '../../../components/elements/icons/PlusIcon';
import OnboardingPackageCard from '../../../components/partials/onboarding-packages/OnboardingPackageCard';
// import ArrowIcon from '../../../components/elements/icons/ArrowIcon';
import ArrowPathIcon from '../../../components/elements/icons/ArrowPathIcon';
import AutocompleteSelect from '../../../components/elements/form/AutocompleteSelect';
// import NumberField from '../../../components/elements/form/NumberField';

const NewEmployee = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  // const shiftsState = useSelector(state => state.shifts)
  const employeesState = useSelector(state => state.employees)
  const designationsState = useSelector(state => state.designations)
  const departmentsState = useSelector(state => state.departments)
  // const documentsState = useSelector(state => state.documents)
  // const reviewsSelector = useSelector(state => state.reviews)
  // const tasksSelector = useSelector(state => state.tasks)
  const rolesSelector = useSelector(state => state.rolesPermissions)
  const onboardingPackagesSelector = useSelector(state => state.onboardingPackages)

  const requiredDocumentUploadSchema = 
  {
    documentName: "",
    slug: "",
    description: ""
  }

  // const emptyTask = 
  // {
  //   task: "",
  //   numberOfDays: "",
  // }

  const employeeSchema = {
    firstName: "",
    middleName: "",
    employeeId: "",
    lastName: "",
    phone: "",
    email: "",
    createUserProfile: false,
    userType: "USER",
    department: "",
    designation: "",
    gender: "",
    address: {
      address: "",
      city: "",
      state: ""
    },
    reviews: {
      // reviewers: [''],
      // reviewForms: ['']
    },
    // tasks: [
    //   emptyTask
    // ],
    requiredDocumentUploads: [requiredDocumentUploadSchema],
    requiredDocumentSignings: [""]
  }



  // const [permissions, setPermissions] = useState(accountPermissionsList);

  const [validationErrors, setValidationErrors] = useState({});
  const [employeePayload, setEmployeePayload] = useState(employeeSchema);
  const [selectedPermissions, setSelectedPermissions] = useState([]);

  // const [accountPermissionsList, setAccountPermissionsList] = useState(initialState);

  const togglePermissionSelection = (sectionIndex, permissionIndex) => {
    let tempPermissions = [...selectedPermissions]

    const selectedPermission = rolesSelector.permissions.sections[sectionIndex].permissions[permissionIndex].value
    if(permissionIndex === 0 && !tempPermissions.includes(selectedPermission)) {
      rolesSelector.permissions.sections[sectionIndex].permissions.forEach((option) => {
        tempPermissions.push(option.value)
      })
    } else if (permissionIndex === 0 && tempPermissions.includes(selectedPermission)) {
      rolesSelector.permissions.sections[sectionIndex].permissions.forEach((option, index) => {
        tempPermissions.splice(index, 1)
      })
    } else {
      // tempPermissions.sections[sectionIndex].permissions[permissionIndex].selected = !tempPermissions.sections[sectionIndex].permissions[permissionIndex].selected
      if(tempPermissions.includes(selectedPermission)) {
        const indexOfPermission = tempPermissions.findIndex(perm => perm === selectedPermission)
        tempPermissions.splice(indexOfPermission, 1)
      } else {
        tempPermissions.push(selectedPermission)
      }
    }

    setSelectedPermissions(tempPermissions)
  }

  useEffect(() => {
    dispatch(fetchShifts())
    dispatch(fetchDepartments(0, 0))
    dispatch(fetchDesignations())
    dispatch(fetchGroups())
    dispatch(fetchDocuments())
    dispatch(fetchEmployees(0, 0))
    // dispatch(getReviewForms(0, 0))
    dispatch(fetchTasks(0, 0))
    dispatch(fetchPermissions())
    dispatch(fetchOnboardingPackages(0, 0))

    if(employeesState.invitedEmployee !== null) {
      dispatch({
        type: SET_SUCCESS_MESSAGE,
        payload: {successMessage: "Employee created successfully. An email has been sent to the user."}
      })
      dispatch(clearInvitedEmployee())
      navigate('/user/employees')
    }
    
    return () => {
      
    };
  }, [dispatch, employeesState.invitedEmployee, navigate]);

  const updateAddress = (field, value) => {
    let temp = {...employeePayload}
    temp.address[field] = value
    setEmployeePayload(temp)
    
  }

  const updateRequiredDocuments = (index, field, value) => {
    let temp = {...employeePayload}
    temp.requiredDocumentUploads[index][field] = value
    if (field === 'documentName') {
      temp.requiredDocumentUploads[index].slug = slugify(value)
    }

    setEmployeePayload(temp)
  }

  const addRequiredDocumentUpload = (index, field, value) => {
    let temp = {...employeePayload}
    temp.requiredDocumentUploads.push(requiredDocumentUploadSchema)

    setEmployeePayload(temp)
  }

  const removeRequiredDocument = (index) => {
    let temp = {...employeePayload}
    temp.requiredDocumentUploads.splice(index, 1)
    setEmployeePayload(temp)
  }

  // const updateRequiredDocumentSigning = (index, value) => {
  //   let temp = {...employeePayload}
  //   temp.requiredDocumentSignings[index] = value

  //   setEmployeePayload(temp)
  // }

  // const addRequiredDocumentSigning = () => {
  //   let temp = {...employeePayload}
  //   temp.requiredDocumentSignings.push("")

  //   setEmployeePayload(temp)
  // }

  // const removeRequiredDocumentSigning = (index, field, value) => {
  //   let temp = {...employeePayload}
  //   temp.requiredDocumentSignings.splice(index, 1)
  //   setEmployeePayload(temp)
  // }

  const validateForm = () => {
    let errors = {}
    if (!employeePayload.firstName || employeePayload.firstName === '') {
        errors.firstName = true
    }
    if (!employeePayload.lastName || employeePayload.lastName === '') {
        errors.lastName = true
    }
    if (!employeePayload.gender || employeePayload.gender === '') {
        errors.gender = true
    }
    if (!employeePayload.email || employeePayload.email === '') {
        errors.email = true
    }
    if (!employeePayload.phone || employeePayload.phone === '') {
        errors.phone = true
    }
    if (!employeePayload.address.address || employeePayload.address.address === '') {
        errors.address = true
    }
    if (!employeePayload.address.city || employeePayload.address.city === '') {
        errors.city = true
    }
    if (!employeePayload.address.state || employeePayload.address.state === '') {
        errors.state = true
    }
    if (!employeePayload.department || employeePayload.department === '') {
      errors.department = true
    }
    if (!employeePayload.designation || employeePayload.designation === '') {
      errors.designation = true
    }
    if (!employeePayload.startDate || employeePayload.startDate === '') {
      errors.startDate = true
    }
    // employeePayload.reviews.reviewers.forEach((reviewer, reviewerIndex) => {
    //   if (!reviewer || reviewer === '') {
    //     errors[`reviewer-${reviewerIndex}`] = true
    //   }
    // })
    if (!employeePayload.onboardingPackage || employeePayload.onboardingPackage === '') {
      errors.onboardingPackage = true
    }
    if(employeePayload.requiredDocumentUploads && employeePayload.requiredDocumentUploads.length > 0){
      employeePayload.requiredDocumentUploads.forEach((doc, docIndex) => {
        if (!doc.documentName || doc.documentName === '') {
          errors[`doc-${docIndex}-name`] = true
        }
        if (!doc.description || doc.description === '') {
          errors[`doc-${docIndex}-desc`] = true
        }
      })
    }

    console.log(errors)
    setValidationErrors(errors)
    return errors
  }

  const triggerCreateEmployee = () => {
    // validateForm
    if (Object.values(validateForm()).includes(true)) {
      dispatch({
          type: ERROR,
          error: {response: {data: {
              message: 'Please check the highlighted fields'
          }}}
      });
      return
    }
    
    dispatch(inviteEmployee(sanitizePayload(employeePayload)))
  }

  const addReviewer = () => {
    let temp = {...employeePayload}
    if(!temp.reviews.reviewers) {
      temp.reviews.reviewers = ['']
    } else {
      temp.reviews.reviewers.push('')
    }
    setEmployeePayload(temp)
  }

  const setReviewerAtIndex = (index, value) => {
    let temp = {...employeePayload}
    temp.reviews.reviewers[index] = value
    setEmployeePayload(temp)
  }

  const removeReviewer = (index) => {
    let temp = {...employeePayload}
    if(temp.reviews.reviewers.length > 1) {
      temp.reviews.reviewers.splice(index, 1)
    } else {
      temp.reviews = {}
    }
    setEmployeePayload(temp)
  }

  // const addReviewForm = () => {
  //   let temp = {...employeePayload}
  //   temp.reviews.reviewForms.push('')
  //   setEmployeePayload(temp)
  // }

  // const setReviewFormAtIndex = (index, value) => {
  //   let temp = {...employeePayload}
  //   temp.reviews.reviewForms[index] = value
  //   setEmployeePayload(temp)
  // }

  // const removeReviewForm = (index) => {
  //   let temp = {...employeePayload}
  //   temp.reviews.reviewForms.splice(index, 1)
  //   setEmployeePayload(temp)
  // }

  // const addTask = () => {
  //   let temp = {...employeePayload}
  //   temp.tasks.push(emptyTask)
  //   setEmployeePayload(temp)
  // }

  // const setTaskAtIndex = (index, field, value) => {
  //   let temp = {...employeePayload}
  //   temp.tasks[index][field] = value

  //   setEmployeePayload(temp)
  // }

  // const removeTask = (index) => {
  //   let temp = {...employeePayload}
  //   temp.tasks.splice(index, 1)
  //   setEmployeePayload(temp)
  // }

  const [selectingPackage, setSelectingPackage] = useState(false);

  return (
    <UserLayout pageTitle={`New Employee`}>
      <>
        <div className='w-10/12 xl:w-7/12 mx-auto mt-12 p-8 bg-white'>
          <h3 className='font-medium'>Employee Details</h3>
          <p className='text-sm mb-3'>Please provide employee details below</p>

          {/* <div className='w-full my-4'> */}
              {/* <label className="block text-xs text-gray-400 my-2">Email Address </label> */}
          <div className='my-4 w-full'>
            <TextField
              inputLabel="Employee ID number" 
              fieldId="first-name" 
              inputType="text" 
              preloadValue={''}
              hasError={false} 
              returnFieldValue={(value)=>{setEmployeePayload({...employeePayload, ...{employeeId: value}})}}
            />
          </div>
          <div className='my-4 w-full'>
            <TextField
              inputLabel="First name" 
              fieldId="first-name" 
              inputType="text" 
              preloadValue={''}
              hasError={validationErrors.firstName} 
              returnFieldValue={(value)=>{setEmployeePayload({...employeePayload, ...{firstName: value}})}}
            />
          </div>
          <div className='my-4 w-full'>
            <TextField
              inputLabel="Middle name" 
              fieldId="middle-name" 
              inputType="text" 
              preloadValue={''}
              hasError={false} 
              returnFieldValue={(value)=>{setEmployeePayload({...employeePayload, ...{middleName: value}})}}
            />
          </div>
          <div className='my-4 w-full'>
            <TextField
              inputLabel="Last name" 
              fieldId="last-name" 
              inputType="text" 
              preloadValue={''}
              hasError={validationErrors.lastName} 
              returnFieldValue={(value)=>{setEmployeePayload({...employeePayload, ...{lastName: value}})}}
            />
          </div>

          <div className='my-4 w-full'>
              <RadioGroup
                  inputLabel="Gender"
                  inline={true}
                  items={[
                      {label: 'Female', value: 'FEMALE'},
                      {label: 'Male', value: 'MALE'},
                  ]} 
                  hasError={validationErrors.gender} 
                  returnSelected={(value)=>{setEmployeePayload({...employeePayload, ...{gender: value.value}})}}
              />
          </div>

          <h3 className='font-medium mt-12'>Contact details</h3>
          <p className='text-sm mb-3'>How can this employee be reached
          </p>
          <div className='my-4 w-full'>
            <TextField
              inputLabel="Employee email address" 
              fieldId="employee-email" 
              inputType="text" 
              preloadValue={''}
              hasError={validationErrors.email} 
              returnFieldValue={(value)=>{setEmployeePayload({...employeePayload, ...{email: value}})}}
            />
          </div>
          <div className='my-4 w-full'>
            <TextField
              inputLabel="Employee phone number" 
              fieldId="employee-phone" 
              inputType="text" 
              preloadValue={''}
              hasError={validationErrors.phone} 
              returnFieldValue={(value)=>{setEmployeePayload({...employeePayload, ...{phone: value}})}}
            />
          </div>
          <div className='my-4 w-full'>
            <TextField
              inputLabel="Address" 
              fieldId="employee-address" 
              inputType="text" 
              preloadValue={''}
              hasError={validationErrors.address} 
              returnFieldValue={(value)=>{updateAddress('address', value)}}
            />
          </div>
          <div className='my-4 w-full'>
            <TextField
              inputLabel="City" 
              fieldId="employee-address-city" 
              inputType="text" 
              preloadValue={''}
              hasError={validationErrors.city} 
              returnFieldValue={(value)=>{updateAddress('city', value)}}
            />
          </div>
          <div className='my-4 w-full'>
            <TextField
              inputLabel="State" 
              fieldId="employee-address-state" 
              inputType="text" 
              preloadValue={''}
              hasError={validationErrors.state} 
              returnFieldValue={(value)=>{updateAddress('state', value)}}
            />
          </div>

          <h3 className='font-medium mt-12'>Company placement</h3>
          <p className='text-sm mb-3'>Please designate employees placement in the company
          </p>

          {departmentsState.loadingDepartments ? 
            <Preloader preloadingText={`fetching departments...`} />
            :
            <div className='my-4 w-full'>
              <AutocompleteSelect
                selectOptions={departmentsState.departments.departments}
                inputLabel="Select Department"
                titleField="name"
                displayImage={false}
                imageField=""
                preSelected=''
                fieldId="employee-department"
                hasError={validationErrors.department}
                // return id of accounts of the selected option
                returnFieldValue={(value) => {setEmployeePayload({...employeePayload, ...{department: value._id}})}}
              />
            <p className='text-xs mt-2 text-gray-400'>Select a department above to assign this user to a department</p>
          </div>}

          {designationsState.loadingDesignations ? 
            <Preloader preloadingText={`fetching designations...`} />
            :
            <div className='my-4 w-full'>
              <SelectField
                selectOptions={designationsState.designations}
                inputLabel="Select Designation"
                titleField="name"
                displayImage={false}
                imageField=""
                preSelected=''
                fieldId="employee-designation"
                hasError={validationErrors.designation}
                // return id of accounts of the selected option
                returnFieldValue={(value) => {setEmployeePayload({...employeePayload, ...{designation: value._id}})}}
              />
              <p className='text-xs mt-2 text-gray-400'>Assign the user a designation by selecting one above</p>
            </div>
          }

          <div className='my-4 w-full'>
            <DateField
              inputLabel="Start date" 
              fieldId="employee-start-date" 
              inputType="text" 
              preloadValue={''}
              hasError={validationErrors.startDate} 
              returnFieldValue={(value)=>{setEmployeePayload({...employeePayload, ...{startDate: value}})}}
            />
            <p className='text-xs mt-2 text-gray-400'>Choose the date when the employee should resume work</p>
          </div>

          {/* <h3 className='font-medium mt-12'>Employee Tasks</h3>
          <p className='text-sm mb-3'>Optionally select tasks for this employee to perform after onboarding.
          </p> */}
          {/* {tasksSelector.loadingTasks ? 
            <Preloader preloadingText={`fetching tasks...`} />
            :
            employeePayload.tasks.map((task, taskIndex) =>(<div key={taskIndex} className='my-4 w-full'>
              {taskIndex > 0 && <div className='pt-2 flex flex-row-reverse'>
                  <button onClick={()=>{removeTask(taskIndex)}} className='flex items-center gap-x-2 text-red-600 hover:text-reg-800 duration-200 transition text-xs'>
                    <TrashIcon className={`w-5 h-5`} />
                    Remove task
                  </button>
                </div>}
                {tasksSelector.tasks?.tasks?.length > 0 ? <>
                  <SelectField
                    selectOptions={tasksSelector.tasks?.tasks}
                    inputLabel="Select Task"
                    titleField="title"
                    displayImage={false}
                    imageField=""
                    preSelected=''
                    fieldId={`employee-task-${taskIndex}`}
                    hasError={false}
                    returnFieldValue={(value) => {setTaskAtIndex(taskIndex, 'task', value._id)}}
                  />

                  <div className='my-4 w-full'>
                    <NumberField
                      inputLabel="Maximum days" 
                      fieldId={`task-${taskIndex}-start-date`}
                      inputType="text" 
                      preloadValue={''}
                      hasError={false} 
                      returnFieldValue={(value)=>{setTaskAtIndex(taskIndex, 'numberOfDays', value)}}
                    />
                    <p className='text-xs mt-2 text-gray-400'>Choose the number of days after onboarding within which the employee needs to work on this task.</p>
                  </div>
                </> :
                  <label className='text-xs block p-[10px] rounded bg-red-50 border border-red-500 text-red-500'>No tasks created yet. If you would like to assign some tasks, please navigate to "task management" on the sidebar and create some first.</label>
                }
            </div>))
          }
          <button onClick={()=>{addTask()}} className='w-max p-3 mt-[10px] text-sm bg-black text-white rounded'>Add another task</button> */}

          <h3 className='font-medium mt-12'>Employee Reviewer/Supervisor</h3>
          <p className='text-sm mb-3'>Select employees who will supervise and review the new hire.
          </p>
          {employeesState.loadingEmployees ? 
            <Preloader preloadingText={`fetching other employees...`} />
            :
            employeePayload.reviews?.reviewers?.map((reviewer, reviewerIndex) =>(<div key={reviewerIndex} className='my-4 w-full'>
              {<div className='pt-2 flex flex-row-reverse'>
                  <button onClick={()=>{removeReviewer(reviewerIndex)}} className='flex items-center gap-x-2 text-red-600 hover:text-reg-800 duration-200 transition text-xs'>
                    <TrashIcon className={`w-5 h-5`} />
                    Remove Reviewer
                  </button>
                </div>}
              <SelectField
                selectOptions={employeesState?.employees?.employees}
                inputLabel="Select Employee"
                titleField="fullName"
                displayImage={false}
                imageField=""
                preSelected=''
                fieldId={`reviewer-${reviewerIndex}`}
                hasError={validationErrors[`reviewers-${reviewerIndex}`]}
                // return id of accounts of the selected option
                returnFieldValue={(value) => {setReviewerAtIndex(reviewerIndex, value._id)}}
              />
            </div>))
          }
          <p className='text-xs mt-2 text-gray-400'>Assign a reviewer/supervisor by selecting an employee above. you can add reviewers by clicking the "add a reviewer" button below.</p>
          <button onClick={()=>{addReviewer()}} className='w-max p-3 mt-[10px] text-sm bg-black text-white rounded'>Add a reviewer</button>

          {/* {reviewsSelector.loadingReviewForms ? 
            <Preloader preloadingText={`fetching review forms...`} />
            :
            employeePayload.reviews.reviewForms.map((form, formIndex) =>(<div key={formIndex} className='my-4 w-full'>
              {formIndex > 0 && <div className='pt-2 flex flex-row-reverse'>
                  <button onClick={()=>{removeReviewForm(formIndex)}} className='flex items-center gap-x-2 text-red-600 hover:text-reg-800 duration-200 transition text-xs'>
                    <TrashIcon className={`w-5 h-5`} />
                    Remove Review Form
                  </button>
                </div>}
              <SelectField
                selectOptions={reviewsSelector?.reviewForms?.forms}
                inputLabel="Select Form"
                titleField="name"
                displayImage={false}
                imageField=""
                preSelected=''
                fieldId={`employee-reviewer-${formIndex}`}
                hasError={false}
                // return id of accounts of the selected option
                returnFieldValue={(value) => {setReviewFormAtIndex(formIndex, value._id)}}
              />
            </div>))
          } */}
          {/* <p className='text-xs mt-2 text-gray-400'>Assign the a reviewer by selecting an employee above. you can add more review forms by clicking the "add another form" button below.</p>
          <button onClick={()=>{addReviewForm()}} className='w-max p-3 mt-[10px] text-sm bg-black text-white rounded'>Add another form</button> */}

          <h3 className='font-medium mt-12'>User Onboarding</h3>
          <p className='text-sm mb-3'>What is required of the user while onboarding and in their early periods?
          </p>

          <p className='text-sm mb-1 font-[550]'>Onboarding package</p>
          <p className='text-sm mb-3 font-[400]'>Select an onboarding package by clicking below </p>

          {onboardingPackagesSelector.loadingOnboardingPackages ? 
            <Preloader preloadingText={`fetching other employees...`} />
            :
            <div className={`p-3 border rounded-[8px] mt-4 mb-6 max-w-[100%] ${validationErrors[`onboardingPackage`] ? 'border-red-500' : 'border-gray-200'}`}>
            {employeePayload.onboardingPackage && employeePayload.onboardingPackage !== '' ? 
              <>
                <OnboardingPackageCard hideActions={true} onboardingPackage={onboardingPackagesSelector.onboardingPackages?.packages.find(op => op._id === employeePayload.onboardingPackage)} />
                <button onClick={()=>{setSelectingPackage(true)}} className='text-xs text-gray-600 flex items-center gap-x-1 hover:text-verovian-purple duration-200 transition mt-3 font-[550]'><ArrowPathIcon className={`w-4 h-4 -rotate-90`}/> Choose a different package</button>
              </>
              :
              <button onClick={()=>{setSelectingPackage(true)}} className='rounded-[8px] p-3 border border-dashed border-gray-300 w-full text-xs text-gray-400 text-center hover:border-gray-600 hover:text-gray-600 transition duration-200'>
                <span className='flex items-center justify-center gap-x-[5px] uppercase tracking-[0.2em] '>
                  <PlusIcon className={`w-5 h-5`} />
                  add onboarding package
                </span>
              </button>
            }
          </div>}

          <p className='text-sm mb-1 font-[550]'>Additional documents</p>
          <p className='text-sm mb-3'>Add any additional documents that this employee will be required to upload as part of their onboarding process</p>

          <div className='w-full p-8 rounded bg-gray-100 bg-opacity-60 mb-6 border border-gray-200'>
            {employeePayload.requiredDocumentUploads.map((requiredUpload, requiredUploadIndex)=>(
              <div key={requiredUploadIndex} className='w-full'>
                {requiredUploadIndex > 0 && <div className='pt-2 flex flex-row-reverse'>
                  <button onClick={()=>{removeRequiredDocument()}} className='flex items-center gap-x-2 text-red-600 hover:text-reg-800 duration-200 transition text-xs'>
                    <TrashIcon className={`w-5 h-5`} />
                    Remove document
                  </button>
                </div>}
                <div className='my-1 w-full'>
                  <TextField
                    inputLabel="Document name" 
                    fieldId="document-name" 
                    inputType="text" 
                    preloadValue={requiredUpload.documentName || ''}
                    hasError={validationErrors[`doc-${requiredUploadIndex}-name}`]} 
                    returnFieldValue={(value)=>{updateRequiredDocuments(requiredUploadIndex, 'documentName', value)}}
                  />
                </div>
                <div className='my-1 w-full'>
                  <TextField
                    inputLabel="Document description" 
                    fieldId="last-name" 
                    inputType="text" 
                    preloadValue={''}
                    hasError={validationErrors[`doc-${requiredUploadIndex}-desc}`]} 
                    returnFieldValue={(value)=>{updateRequiredDocuments(requiredUploadIndex, 'description', value)}}
                  />
                </div>

              </div>
            ))}

            <button onClick={()=>{addRequiredDocumentUpload()}} className='w-max p-3 text-sm bg-black text-white rounded'>Add another document</button>
          </div>

          {/* <p className='text-sm mt-6 mb-3'>Select documents that this employee will be required to sign as part of their onboarding</p>
          <div className='w-full px-8 py-4 rounded bg-gray-100 bg-opacity-60 mb-6 border border-gray-200'>
            {employeePayload.requiredDocumentSignings.map((requiredSigning, requiredSigningIndex)=>(
              <div key={requiredSigningIndex} className='w-full mt-3'>
                {requiredSigningIndex > 0 && <div className='flex flex-row-reverse pt-2'>
                  <button onClick={()=>{removeRequiredDocumentSigning()}} className='flex items-center gap-x-2 text-red-600 hover:text-reg-800 duration-200 transition text-xs'>
                    <TrashIcon className={`w-5 h-5`} />
                    Remove document 
                  </button>
                </div>}
                {documentsState.loadingDocuments ? 
                <Preloader preloadingText={`fetching documents...`} />
                :
                <div className='my-4 w-full'>
                  <SelectField
                    selectOptions={documentsState.documents}
                    inputLabel="Select Document"
                    titleField="name"
                    displayImage={false}
                    imageField=""
                    preSelected={requiredSigning}
                    fieldId={`required-document-signing-${requiredSigningIndex}`}
                    hasError={false}
                    returnFieldValue={(value) => {updateRequiredDocumentSigning(requiredSigningIndex, value._id)}}
                  />
                </div>
                }

              </div>
            ))}

            <button onClick={()=>{addRequiredDocumentSigning()}} className='w-max p-3 text-sm bg-black text-white rounded'>Add another document</button>
          </div> */}

          <h3 className='font-medium mt-12'>User Access</h3>
          <p className='text-sm mb-3'>Designate user's access to this platform</p>

          <div className='w-full my-4 flex gap-x-4 items-center justify-between'>
              <div  className='w-full'>
                  <p className="text-sm text-gray-600">
                      Create a user profile?
                  </p>
                  <p className='text-xs text-gray-400'>Creating a user profile means the user will have access to this platform.</p>
              </div>
              <div className='w-24 flex flex-row-reverse'>
                  <Switch
                      checked={employeePayload.createUserProfile}
                      onChange={()=>{setEmployeePayload({...employeePayload, ...{createUserProfile: !employeePayload.createUserProfile}})}}
                      className={`${
                        employeePayload.createUserProfile ? 'bg-verovian-purple' : 'bg-gray-200'
                      } relative inline-flex items-center h-5 rounded-full w-10`}
                      >
                      {/* <span className="sr-only">Display stock levels</span> */}
                      <span
                          className={`transform transition ease-in-out duration-200 ${
                            employeePayload.createUserProfile ? 'translate-x-6' : 'translate-x-1'
                          } inline-block w-3 h-3 transform bg-white rounded-full`}
                      />
                  </Switch>
              </div>
          </div>

          {employeePayload.createUserProfile && <>
            <div className='my-4 w-full'>
                <RadioGroup
                    inputLabel="User type"
                    inline={true}
                    items={[
                        {label: 'Normal user', value: 'USER'},
                        {label: 'Administrator', value: 'ADMIN'},
                    ]} 
                    hasError={validationErrors.gender} 
                    returnSelected={(value)=>{setEmployeePayload({...employeePayload, ...{userType: value.value}})}}
                />
            </div>

            {employeePayload.userType && employeePayload.userType === 'ADMIN' && <div className='my-4 w-full'>
              <h3 className='font-medium mt-6 mb-2'>User Account permissions</h3>
              <p className='text-sm mb-12'>Use the toggles below to grant the user's account permissions to different modules of the system</p>

              {rolesSelector.loadingPermissions ? 
                <Preloader preloadingText={`fetching permissions...`} />
              :
              <>
                {rolesSelector?.permissions?.sections.map((section, sectionIndex)=>(<div className='w-full mb-8' key={sectionIndex}>
                    <h3 className="font-medium text-sm">{section.title}</h3>
                    {section.permissions.map((permission, permissionIndex)=>(<div key={permissionIndex} className='w-full my-4 flex gap-x-4 items-center justify-between'>
                      <div className='w-full'>
                        <p className="text-sm text-gray-600">
                            {permission.label}
                        </p>
                        {/* <p className='text-xs text-gray-400'>Creating a user profile means the user will have access to this platform.</p> */}
                      </div>
                      <div className='w-24'>
                        <Switch
                            checked={selectedPermissions.includes(permission.value)}
                            onChange={()=>{togglePermissionSelection(sectionIndex, permissionIndex)}}
                            className={`${
                              selectedPermissions.includes(permission.value) ? 'bg-verovian-purple' : 'bg-gray-200'
                            } relative inline-flex items-center h-5 rounded-full w-10`}
                            >
                            {/* <span className="sr-only">Display stock levels</span> */}
                            <span
                                className={`transform transition ease-in-out duration-200 ${
                                  selectedPermissions.includes(permission.value) ? 'translate-x-6' : 'translate-x-1'
                              } inline-block w-3 h-3 transform bg-white rounded-full`}
                            />
                        </Switch>
                      </div>
                    </div>))}
                  </div>
                  
                ))}
              </>}

            </div>}
          </>}

          <div className='my-8 flex flex-row-reverse items-center justify-between'>
            <div className='w-full'>
              <FormButton 
                  buttonLabel={`Create Employee`} 
                  buttonAction={()=>{triggerCreateEmployee()}} 
                  processing={employeesState.invitingEmployee}
              />
            </div>
          </div>

        </div>

        <ModalLayout
          isOpen={selectingPackage} 
          closeModal={()=>{setSelectingPackage(false)}} 
          actionFunction={()=>{}} 
          actionFunctionLabel='' 
          dialogTitle='Add Onboarding Package'
          dialogIntro={`Select the package required for this employee`}
          maxWidthClass='max-w-xl'
        >
          <OnboardingPackageSelector
            packages={onboardingPackagesSelector.onboardingPackages?.packages}
            selectedPackageId={employeePayload.onboardingPackage}
            saveSelection={(selected)=>{setEmployeePayload({...employeePayload, ...{onboardingPackage: selected}})}}
            closeModal={()=>{setSelectingPackage(false)}}
          />
        </ModalLayout>
      </>
    </UserLayout>
  )
;}

export default NewEmployee
import axios from "axios"
import { authHeader } from "../../utils"
import { CREATE_INVENTORY_ITEM, CREATE_INVENTORY_ITEM_CATEGORY, CREATE_INVENTORY_ITEM_VARIANT, CREATING_INVENTORY_ITEM, CREATING_INVENTORY_ITEM_CATEGORY, GET_INVENTORY_ITEM_CATEGORIES, GETTING_INVENTORY_ITEM_CATEGORIES, GETTING_INVENTORY_ITEMS, INVENTORY_ERROR, UPDATE_INVENTORY_ITEM, UPDATE_INVENTORY_ITEM_CATEGORY, UPDATE_INVENTORY_ITEM_VARIANT, UPDATING_INVENTORY_ITEM, UPDATING_INVENTORY_ITEM_CATEGORY, UPDATING_INVENTORY_ITEM_VARIANT } from "../types"

export const createInventoryItemCategory = (payload) => async (dispatch) => {    
    try{
        const headers = authHeader()

        dispatch({
            type: CREATING_INVENTORY_ITEM_CATEGORY,
            payload: true
        })
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/inventory/categories`, payload, { headers })
        
        dispatch({
            type: CREATE_INVENTORY_ITEM_CATEGORY,
            payload: response.data.data
        })
        
    }
    catch(error){
        dispatch({
            type: INVENTORY_ERROR,
            error
        })
    }
}

export const clearCreatedInventoryItemCategory = () => async (dispatch) => {    
    dispatch({
        type: CREATE_INVENTORY_ITEM_CATEGORY,
        payload: null
    })
}

export const updateInventoryItemCategory = (categoryId, payload) => async (dispatch) => {    
    try{
        const headers = authHeader()

        dispatch({
            type: UPDATING_INVENTORY_ITEM_CATEGORY,
            payload: true
        })
        const response = await axios.patch(`${process.env.REACT_APP_API_URL}/inventory/categories/${categoryId}`, payload, { headers })
        
        dispatch({
            type: UPDATE_INVENTORY_ITEM_CATEGORY,
            payload: response.data.data
        })
        
    }
    catch(error){
        dispatch({
            type: INVENTORY_ERROR,
            error
        })
    }
}

export const clearUpdatedInventoryItemCategory = () => async (dispatch) => {    
    dispatch({
        type: UPDATE_INVENTORY_ITEM_CATEGORY,
        payload: null
    })
}

export const getInventoryItemCategories = (page, perPage) => async (dispatch) => {    
    try{
        const headers = authHeader()

        let url = `inventory/categories`
        if((page || page===0) && page !== ''){
            url += `${url.includes('?') ? '&' : '?'}page=${page}`
        }
        if((perPage || perPage === 0) && perPage !== ''){
            url += `${url.includes('?') ? '&' : '?'}perPage=${perPage}`
        }

        dispatch({
            type: GETTING_INVENTORY_ITEM_CATEGORIES,
            payload: true
        })
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/${url}`, { headers })

        dispatch({
            type: GET_INVENTORY_ITEM_CATEGORIES,
            payload: response.data.data
        })
        
    }
    catch(error){
        dispatch({
            type: INVENTORY_ERROR,
            error
        })
    }
}

export const createInventoryItem = (payload) => async (dispatch) => {    
    try{
        const headers = authHeader()

        dispatch({
            type: CREATING_INVENTORY_ITEM,
            payload: true
        })
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/inventory/items`, payload, { headers })
        
        dispatch({
            type: CREATE_INVENTORY_ITEM,
            payload: response.data.data
        })
        
    }
    catch(error){
        dispatch({
            type: INVENTORY_ERROR,
            error
        })
    }
}

export const clearCreatedInventoryItem = () => async (dispatch) => {    
    dispatch({
        type: CREATE_INVENTORY_ITEM,
        payload: null
    })
}

export const updateInventoryItem = (itemId, payload) => async (dispatch) => {    
    try{
        const headers = authHeader()

        dispatch({
            type: UPDATING_INVENTORY_ITEM,
            payload: true
        })
        const response = await axios.patch(`${process.env.REACT_APP_API_URL}/inventory/items/${itemId}`, payload, { headers })
        
        dispatch({
            type: UPDATE_INVENTORY_ITEM,
            payload: response.data.data
        })
        
    }
    catch(error){
        dispatch({
            type: INVENTORY_ERROR,
            error
        })
    }
}

export const clearUpdatedInventoryItem= () => async (dispatch) => {    
    dispatch({
        type: UPDATE_INVENTORY_ITEM,
        payload: null
    })
}

export const getInventoryItems = (page, perPage, filter) => async (dispatch) => {    
    try{
        const headers = authHeader()

        let url = `inventory/items`
        if((page || page===0) && page !== ''){
            url += `${url.includes('?') ? '&' : '?'}page=${page}`
        }
        
        if((perPage || perPage === 0) && perPage !== ''){
            url += `${url.includes('?') ? '&' : '?'}perPage=${perPage}`
        }

        if(filter && filter !== ''){
            url += `${url.includes('?') ? '&' : '?'}${filter}`
        }

        dispatch({
            type: GETTING_INVENTORY_ITEM_CATEGORIES,
            payload: true
        })

        const response = await axios.get(`${process.env.REACT_APP_API_URL}/${url}`, { headers })

        dispatch({
            type: GET_INVENTORY_ITEM_CATEGORIES,
            payload: response.data.data
        })
    }
    catch(error){
        dispatch({
            type: INVENTORY_ERROR,
            error
        })
    }
}

export const createInventoryItemVariant = (payload) => async (dispatch) => {    
    try{
        const headers = authHeader()

        dispatch({
            type: CREATING_INVENTORY_ITEM,
            payload: true
        })
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/inventory/item-variants`, payload, { headers })
        
        dispatch({
            type: CREATE_INVENTORY_ITEM,
            payload: response.data.data
        })
        
    }
    catch(error){
        dispatch({
            type: INVENTORY_ERROR,
            error
        })
    }
}

export const clearCreatedInventoryItemVariant = () => async (dispatch) => {    
    dispatch({
        type: CREATE_INVENTORY_ITEM_VARIANT,
        payload: null
    })
}

export const updateInventoryItemVariant = (variantId, payload) => async (dispatch) => {    
    try{
        const headers = authHeader()

        dispatch({
            type: UPDATING_INVENTORY_ITEM_VARIANT,
            payload: true
        })
        const response = await axios.patch(`${process.env.REACT_APP_API_URL}/inventory/item-variants/${variantId}`, payload, { headers })
        
        dispatch({
            type: UPDATE_INVENTORY_ITEM_VARIANT,
            payload: response.data.data
        })
        
    }
    catch(error){
        dispatch({
            type: INVENTORY_ERROR,
            error
        })
    }
}

export const clearUpdatedInventoryItemVariant = () => async (dispatch) => {    
    dispatch({
        type: UPDATE_INVENTORY_ITEM_VARIANT,
        payload: null
    })
}

export const getInventoryItemVariants = (page, perPage, filter) => async (dispatch) => {    
    try{
        const headers = authHeader()

        let url = `inventory/item-variants`
        if((page || page===0) && page !== ''){
            url += `${url.includes('?') ? '&' : '?'}page=${page}`
        }
        
        if((perPage || perPage === 0) && perPage !== ''){
            url += `${url.includes('?') ? '&' : '?'}perPage=${perPage}`
        }

        if(filter && filter !== ''){
            url += `${url.includes('?') ? '&' : '?'}${filter}`
        }

        dispatch({
            type: GETTING_INVENTORY_ITEMS,
            payload: true
        })

        const response = await axios.get(`${process.env.REACT_APP_API_URL}/${url}`, { headers })

        dispatch({
            type: GETTING_INVENTORY_ITEMS,
            payload: response.data.data
        })
    }
    catch(error){
        dispatch({
            type: INVENTORY_ERROR,
            error
        })
    }
}


import React, { useEffect, useState } from 'react'
import ReviewsManagementLayout from '../../../components/layout/ReviewsManagementLayout'
import { useDispatch, useSelector } from 'react-redux'
import { getReviewSchedules } from '../../../store/actions/reviewsActions'
import Preloader from '../../../components/elements/Preloader'
import EmptyState from '../../../components/elements/icons/EmptyState'
import DataTable from '../../../components/elements/DataTable'
import { tableHeadersFields, transactionTimeStamp, userDetails } from '../../../utils'
// import { useNavigate } from 'react-router-dom'
import EmployeeSnippet from '../../../components/partials/employees/EmployeeSnippet'
import Status from '../../../components/elements/Status'

const ReviewSchedules = () => {
  const reviewsSelector = useSelector(state => state.reviews)
  const dispatch = useDispatch()
  // const navigate = useNavigate()
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(25);

  useEffect(() => {
      dispatch(getReviewSchedules(page, perPage))
      // if(reviewsSelector.deletedReviewForm !== null){
      //     dispatch({
      //         type: SET_SUCCESS_MESSAGE,
      // payload: {
      //   successMessage: 'Review form deleted'
      // }
      //     })
      //     dispatch(clearDeletedReviewForm())
      // }
      return () => {
          
      };
  }, [dispatch, page, perPage, reviewsSelector.deletedReviewSchedule]);


  const tableOptions = {
    selectable: false,
    expandable: false,
    clickableRows: true,
    rowAction: (value)=>{
      console.log(value)
      // navigate(`profile/${employeesState.employees[value]._id}`)
    }
  }

  const columnWidths = {
    employee: "w-full lg:w-4/12",
    reviewer: "w-full lg:w-4/12",
    review: "w-full lg:w-3/12",
    status: "w-full lg:w-1/12",
  }


  
  const cleanupData = (dataSet) => {
    const data = []

    dataSet.forEach((item, itemIndex) => {
      data.push(
        {
          employee: <EmployeeSnippet showIcon={true} name={`${item.employee.fullName}`} email={item.employee.email} userId={item.employee.userProfile} />,
          reviewer: <EmployeeSnippet showIcon={true} name={`${item.reviewer.fullName}`} email={item.reviewer.email} userId={item.reviewer.userProfile} />,
          review: <div>
                    <p className='text-sm font-[500] mb-[5px]'>{item?.form?.name}</p>
                    <p className='text-xs'>Open from {transactionTimeStamp(item?.dueDateRange?.from).date} to {transactionTimeStamp(item?.dueDateRange?.to).date}</p>
                  </div>,
          status: <Status status={item.submitted ? 'reviewed' : 'pending'} />, //<OrderPaymentStatus status={item.paymentStatus} />,
        },
      )
    })

    return data
  }

  return (
    <ReviewsManagementLayout sectionTitle={`Review Schedules`}>
        <div className=''>
        <div className='w-full lg:flex flex-row gap-x-8'>
          <div className='w-full pb-6 mx-auto'>
            <div className='flex flex-row justify-between items-center mt-4 mb-4'>
              <p className='text-sm'>These reviews are system generated and they are created based on the the employees' start date and required reviews.</p>
              
              {/* <Link to={`new`} className='flex gap-x-2 items-center rounded bg-verovian-purple px-3 py-3 text-verovian-light-purple text-sm transition duration-200 hover:bg-gray-600'>
                <PlusIcon className={`h-5 w-5`} />
                Create review form
              </Link> */}
            </div>
            {userDetails().userType === 'USER' && <p className='text-sm mt-[15px]'>Below are reviews scheduled for you.</p>}

            {reviewsSelector.loadingReviewSchedules ? 
              <Preloader preloadingText={`Loading review schedules`} /> 
            :   
              <>
                {reviewsSelector.reviewSchedules?.schedules?.length > 0 ?
                  <>
                    <DataTable                                
                        tableHeaders={tableHeadersFields(cleanupData(reviewsSelector.reviewSchedules.schedules)[0])?.headers} 
                        tableData={cleanupData(reviewsSelector.reviewSchedules.schedules)} 
                        columnWidths={columnWidths}
                        columnDataStyles={{}}
                        allFields={tableHeadersFields(cleanupData(reviewsSelector.reviewSchedules.schedules)[0]).fields}
                        onSelectItems={()=>{}}
                        tableOptions={tableOptions}
                        pagination={{
                            perPage, 
                            currentPage: page,
                            totalItems: reviewsSelector.reviewSchedules.total,
                        }}
                        changePage={(value)=>{setPage(value)}}
                        updatePerPage={(value)=>{setPerPage(value)}}
                    />
                </>
                  :
                  <EmptyState emptyStateText={`No review schedules created yet. Review schedules will be displayed here when the system has scheduled some.`} />
                }
              </>
            }
          </div>
        </div>
      </div>
    </ReviewsManagementLayout>
  )
}

export default ReviewSchedules
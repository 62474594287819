import { CREATE_INVENTORY_ITEM_CATEGORY, CREATING_INVENTORY_ITEM_CATEGORY, GET_INVENTORY_ITEM_CATEGORIES, GET_INVENTORY_ITEMS, GETTING_INVENTORY_ITEM_CATEGORIES, GETTING_INVENTORY_ITEMS, INVENTORY_ERROR } from "../types"


const initialState = {
    assignments: [],
    loadingAssignments: false,
    categories: [],
    loadingCategories: false,
    items: [],
    loadingItems: false,
    variants: [],
    loadingVariants: false,
    inventoryError: null,
    creatingItem: false,
    createdVariant: null,
    creatingAssignment: false,
    createdAssignment: null,
    creatingCategory: false,
    createdCategory: null,
    updatingItem: false,
    updatedVariant: null,
    updatingAssignment: false,
    updatedAssignment: null,
    updatingCategory: false,
    updatedCategory: null,
    // updatingInstructor: false,
    // updatedInstructor: null,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action){

    switch(action.type){
        case CREATING_INVENTORY_ITEM_CATEGORY:
        return {
            ...state,
            creatingCategory: action.payload,
            // fetchingMembers:false
        }
        case CREATE_INVENTORY_ITEM_CATEGORY:
        return{
            ...state,
            creatingCategory: false,
            inventoryError: false,
            createdCategory: action.payload,
        }
        case GETTING_INVENTORY_ITEM_CATEGORIES:
        return {
            ...state,
            loadingCategories: action.payload,
            // fetchingMembers:false
        }
        case GET_INVENTORY_ITEM_CATEGORIES:
        return{
            ...state,
            loadingCategories: false,
            inventoryError: false,
            categories: action.payload,
        }
        case GETTING_INVENTORY_ITEMS:
        return {
            ...state,
            loadingItems: action.payload,
            // fetchingMembers:false
        }
        case GET_INVENTORY_ITEMS:
        return{
            ...state,
            loadingItems: false,
            inventoryError: false,
            items: action.payload,
        }
        // case CREATING_INSTRUCTOR:
        // return {
        //     ...state,
        //     creatingInstructor: action.payload,
        //     // fetchingMembers:false
        // }
        // case CREATE_INSTRUCTOR:
        // return{
        //     ...state,
        //     creatingInstructor:false,
        //     instructorsError: false,
        //     createdInstructor: action.payload,
        // }
        // case UPDATING_INSTRUCTOR:
        // return {
        //     ...state,
        //     updatingInstructor: action.payload,
        //     // fetchingMembers:false
        // }
        // case UPDATE_INSTRUCTOR:
        // return{
        //     ...state,
        //     updatingInstructors:false,
        //     instructorsError: false,
        //     updatedInstructors: action.payload,
        // }
        case INVENTORY_ERROR:
        return{
            ...state,
            loadingCategories:false,
            creatingCategory:false,
            updatingCategory:false,
            inventoryError: action.payload 
        }
        default: return state
    }

}
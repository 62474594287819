import React, { useEffect, useState } from 'react'
import UserLayout from '../../../components/layout/UserLayout'
import PlusIcon from '../../../components/elements/icons/PlusIcon'
import { Link, useNavigate } from 'react-router-dom'
import DataTable from '../../../components/elements/DataTable'
import EmployeeSnippet from '../../../components/partials/employees/EmployeeSnippet'
import { tableHeadersFields, transactionTimeStamp } from '../../../utils'
import Status from '../../../components/elements/Status'
import { useDispatch, useSelector } from 'react-redux'
import { fetchEmployees } from '../../../store/actions/employeeActions'
import Preloader from '../../../components/elements/Preloader'
import EmptyState from '../../../components/elements/icons/EmptyState'
import FilterSlidesIcon from '../../../components/elements/icons/FilterSlidesIcon'
import ChevronIcon from '../../../components/elements/icons/ChevronIcon'
import SquaresGrid from '../../../components/elements/icons/SquaresGrid'
import QueueListIcon from '../../../components/elements/icons/QueueListIcon'
import EmployeePreviewCard from '../../../components/partials/employees/EmployeePreviewCard'
import Pagination from '../../../components/elements/Pagination'

const Employees = () => {
    const dispatch = useDispatch()
    const employeesState = useSelector(state => state.employees)
    const navigate = useNavigate()

    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(25);
    useEffect(() => {
        dispatch(fetchEmployees())
        return () => {
        
        };
    }, [dispatch]);

    const tableOptions = {
        selectable: false,
        expandable: false,
        clickableRows: true,
        rowAction: (value)=>{
            console.log(value)
            navigate(`profile/${employeesState?.employees?.employees[value]._id}`)
        }
    }

    const columnWidths = {
        id: "w-full lg:w-1/12",
        employee: "w-full lg:w-4/12",
        designation: "w-full lg:w-2/12",
        department: "w-full lg:w-2/12",
        dateJoined: "w-full lg:w-2/12",
        systemStatus: "w-full lg:w-1/12",
    }

    const cleanupData = (dataSet) => {
        const data = []

        dataSet.forEach((item, itemIndex) => {
        data.push(
            {
                id: item.employeeId || '',
                employee: <EmployeeSnippet showIcon={true} avatar={item.avatar} name={`${item.firstName} ${item.lastName}`} phone={item.phone} email={item.email} />,
                designation: item.designation.name,
                department: item.department.name,
                dateJoined: item?.userProfile ? transactionTimeStamp(item?.userProfile?.createdAt).date : '',
                systemStatus: <Status status={item.userProfile ? 'profiled' : 'not-profiled'} />, //<OrderPaymentStatus status={item.paymentStatus} />,
            },
        )
        })

        return data
    }

    const [view, setView] = useState('grid'); // list
    
    return (
        <UserLayout pageTitle={`Employee Management`}>
            <div className=''>
                <div className='w-full lg:flex flex-row gap-x-8'>
                    <div className='w-full pb-6 px-12'>
                        <div className='flex justify-between items-center mt-4 mb-4'>
                            <div className='w-full'>
                                <h3 className='font-medium text-lg text-gray-400'>Verovian Employees</h3>
                                <div className='flex items-center gap-x-[5px] pt-[20px]'>
                                    <label className='text-sm text-gray-500 mb-[10px] flex items-center gap-x-[5px] mr-[10px]'><FilterSlidesIcon className={`w-5 h-5`} /> Filters</label>
                                    <div className='relative'>
                                        <button className='text-xs bg-gray-100 transition duration-200 hover:bg-gray-200 text-gray-600 rounded p-[10px] -mt-[10px] font-[500] flex items-center justify-center gap-x-[5px]'>Department <ChevronIcon className={`w-3 h-3`} stroke={3} /></button>
                                    </div>
                                    <div className='relative'>
                                        <button className='text-xs bg-gray-100 transition duration-200 hover:bg-gray-200 text-gray-600 rounded p-[10px] -mt-[10px] font-[500] flex items-center justify-center gap-x-[5px]'>Designation <ChevronIcon className={`w-3 h-3`} stroke={3} /></button>
                                    </div>
                                    <div className='relative'>
                                        <button className='text-xs bg-gray-100 transition duration-200 hover:bg-gray-200 text-gray-600 rounded p-[10px] -mt-[10px] font-[500] flex items-center justify-center gap-x-[5px]'>Start Date <ChevronIcon className={`w-3 h-3`} stroke={3} /></button>
                                    </div>
                                </div>
                            </div>
                            <div className='flex items-center gap-x-[10px] flex-row-reverse w-4/12'>
                                <Link to={`new-employee`}>
                                    <button className='flex gap-x-2 items-center rounded bg-verovian-purple px-3 py-3 text-white text-[13px] transition duration-200 hover:bg-blue-800'>
                                        <PlusIcon className={`h-5 w-5`} />
                                        Onboard an employee
                                    </button>
                                </Link>
                                <div className='flex items-center'>
                                    <button onClick={()=>{setView('grid')}} className={`flex items-center justify-center p-[12px] rounded-l ${view === 'grid' ? 'bg-verovian-light-purple text-verovian-purple' : 'bg-gray-100 text-gray-600'}`}>
                                        <SquaresGrid className={`w-5 h-5`} />
                                    </button>
                                    <button onClick={()=>{setView('list')}} className={`flex items-center justify-center p-[12px]  rounded-r ${view === 'list' ? 'bg-verovian-light-purple text-verovian-purple' : 'bg-gray-100 text-gray-600'}`}>
                                        <QueueListIcon className={`w-5 h-5`} />
                                    </button>
                                </div>
                            </div>
                        </div>

                        {/* <div className='w-full pt-5'>
                            <Filters filterLabel="Filter Orders" filterOptions={filters} returnSelected={(filter)=>{captureFilter(filter)}} resetFilters={()=>{setSearchFilter(initialFilters)}} />
                        </div> */}

                        <div className='w-full'>
                        {employeesState?.loadingEmployees && employeesState.loadingEmployees === true ? 
                            <Preloader preloadingText={'Loading employees... '} />
                            : 
                            <>
                                {employeesState?.employees?.employees?.length > 0 ? 
                                    <> 
                                        {view === 'list' && <DataTable                                
                                            tableHeaders={tableHeadersFields(cleanupData(employeesState?.employees?.employees)[0])?.headers} 
                                            tableData={cleanupData(employeesState?.employees?.employees)} 
                                            columnWidths={columnWidths}
                                            columnDataStyles={{}}
                                            allFields={tableHeadersFields(cleanupData(employeesState?.employees?.employees)[0]).fields}
                                            onSelectItems={()=>{}}
                                            tableOptions={tableOptions}
                                            pagination={{
                                                perPage, 
                                                currentPage: page,
                                                totalItems: employeesState.employees.total,
                                            }}
                                            changePage={(value)=>{setPage(value)}}
                                            updatePerPage={(value)=>{setPerPage(value)}}
                                        />}

                                        {view === 'grid' && 
                                        <>
                                            <div className='w-full grid grid-cols-2 xl:grid-cols-3 gap-2 mb-[40px]'>
                                                {employeesState?.employees?.employees?.map((employee, employeeIndex)=>(<div key={employeeIndex} className='w-full'>
                                                    <EmployeePreviewCard employee={employee} />
                                                </div>))}
                                            </div>
                                            <Pagination 
                                                pagination={{
                                                    currentPage: page,
                                                    perPage: perPage,
                                                    totalItems: employeesState?.employees?.total
                                                }}
                                                changePage={(value)=>{setPage(value)}}
                                                updatePerPage={(value)=>{setPerPage(value)}}
                                            />
                                        </>}
                                    </>
                                    :
                                    <EmptyState emptyStateText={`No employees created yet. Click on the "Onboard employee" button above to create one`} />
                                }
                            </>
                        }
                        </div>

                    </div>
                </div>

            </div>
        </UserLayout>
    )
}

export default Employees
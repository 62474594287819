import React, { useEffect, useState } from 'react'
import UserLayout from '../../../../components/layout/UserLayout'
import CompanyPageLayout from '../../../../components/layout/CompanyPageLayout'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { authHeader, tableHeadersFields, transactionTimeStamp, userDetails } from '../../../../utils';
import axios from 'axios';
import { ERROR, SET_SUCCESS_MESSAGE } from '../../../../store/types';
import { fetchEmployees } from '../../../../store/actions/employeeActions';
import Status from '../../../../components/elements/Status';
import EmployeeSnippet from '../../../../components/partials/employees/EmployeeSnippet';
import Preloader from '../../../../components/elements/Preloader';
import DataTable from '../../../../components/elements/DataTable';
import EmptyState from '../../../../components/elements/icons/EmptyState';
import LeavePolicyManagement from '../../../../components/partials/departments/LeavePolicyManagement';
import ModalLayout from '../../../../components/layout/ModalLayout';
import EditDepartment from '../../../../components/partials/departments/EditDepartment';
import { clearCreatedDepartment, fetchDepartments } from '../../../../store/actions/departmentActions';
import DepartmentHodSelector from '../../../../components/partials/departments/DepartmentHodSelector';

const DepartmentDetails = () => {
  const [department, setDepartment] = useState(null);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch()
  const {departmentId} = useParams()
  const employeesState = useSelector(state => state.employees)
  const departmentsState = useSelector(state => state.departments)
  const [departmentLeavePolicies, setDepartmentLeavePolicies] = useState(null);
  useEffect(() => {
    const fetchDepartmentDetails = async () => {    
      try{
        const headers = authHeader()
        let requestUrl = `departments/${departmentId}?expand=createdBy,hod`
        setLoading(true)
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/${requestUrl}`, { headers })

        setDepartment(response.data.data)
        fetchDepartmentLeavePolicies()
      }
      catch(error){
        dispatch( {
            type: ERROR,
            error
        })
      }
    }

    const fetchDepartmentLeavePolicies = async () => {    
      try{
        const headers = authHeader()
        let requestUrl = `leave-policies/${departmentId}`
        setLoading(true)
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/${requestUrl}`, { headers })

        setDepartmentLeavePolicies(response.data.data.data)
        setLoading(false)
      }
      catch(error){
        dispatch( {
            type: ERROR,
            error
        })
      }
    }

    dispatch(fetchEmployees(0, 0, `department=${departmentId}`))
    fetchDepartmentDetails()
    if(departmentsState.createdDepartment && departmentsState.createdDepartment !== null) {
      dispatch({
          type: SET_SUCCESS_MESSAGE,
          payload: {
              successMessage: `Department updated successfully!`
            }
      })
      dispatch(fetchDepartments())
      dispatch(clearCreatedDepartment())
      setChoosingHod(false)
    }

    return () => {
      
    };
  }, [dispatch, departmentId, departmentsState.createdDepartment]);
  
  const navigate = useNavigate()
  const employeeTableOptions = {
    selectable: false,
    expandable: false,
    clickableRows: true,
    rowAction: (value)=>{navigate(`/user/employees/profile/${employeesState.employees.employees[value]._id}`)}
  }

  const employeeColumnWidths = {
      id: "w-full lg:w-1/12",
      employee: "w-full lg:w-4/12",
      designation: "w-full lg:w-3/12",
      dateJoined: "w-full lg:w-2/12",
      systemStatus: "w-full lg:w-2/12",
  }

  const cleanupData = (dataSet) => {
      const data = []

      dataSet.forEach((item, itemIndex) => {
      data.push(
          {
              id: item.employeeId || '',
              employee: <EmployeeSnippet showIcon={true} name={`${item.firstName} ${item.lastName}`} phone={item.phone} email={item.email} />,
              designation: item.designation?.name,
              dateJoined: item?.userProfile ? transactionTimeStamp(item?.userProfile?.createdAt).date : '',
              systemStatus: <Status status={item.userProfile ? 'profiled' : 'not-profiled'} />, //<OrderPaymentStatus status={item.paymentStatus} />,
          },
      )
      })

      return data
  }

  const [editingDepartment, setEditingDepartment] = useState(false);
  const [activeTab, setActiveTab] = useState('details');
  const tabs = [
    {
      label: "Department Details",
      value: 'details'
    },
    {
      label: "Leave Policies",
      value: 'leave-policies'
    },
    {
      label: "Hierarchy",
      value: 'hierarchy'
    },
    {
      label: "Inventory",
      value: 'inventory'
    }
  ]

  const [choosingHod, setChoosingHod] = useState(false);
  
  return (
    <UserLayout pageTitle="Company">
      <CompanyPageLayout sectionTitle="Department details">

        {!loading ? 
          <div className='mt-[30px]'>
            {tabs.map((tab, tabIndex)=>(
              <button key={tabIndex} onClick={()=>{setActiveTab(tab.value)}} className={`${tab.value === activeTab ? 'bg-white font-[500]' : ''} px-[16px] py-[8px] border-b text-sm`}>
                {tab.label}
              </button>
            ))}
            {activeTab === 'details' && 
              <div className="w-full px-8 py-12 bg-white mt-[5px] relative">
              {(userDetails().accountPermissions?.includes('*') || userDetails().accountPermissions?.includes('departments.*') || userDetails().accountPermissions?.includes('departments.update')) && <button onClick={()=>{setEditingDepartment(true)}} className='absolute top-5 right-5 bg-gray-300 text-black p-3 rounded-md border border-gray-600 text-xs font-medium'>Edit Department Details</button>}
                <div className='w-1/2'>
                  <div className='w-full my-5'>
                    <label className='text-xs tracking-[0.2em]'>DEPARTMENT NAME</label>
                    <p className='mt-1'>{department?.name}</p>
                  </div>
                  <div className='w-full my-5'>
                    <label className='text-xs tracking-[0.2em]'>DESCRIPTION</label>
                    <p className='mt-1 text-sm'>{department.description}</p>
                  </div>
                </div>
                <div className='w-full'>
                  <label className='text-xs tracking-[0.2em]'>HEAD OF DEPARTMENT/SUPERVISOR</label>

                  {department.hod && department.hod !== '' ? 
                    <div className='w-full relative my-[15px]'>
                      {/* <button onClick={()=>{setEditingDepartment(true)}} className='absolute top-5 right-5 bg-gray-300 text-black p-3 rounded-md border border-gray-600 text-xs font-medium'>Change HOD</button> */}
                      <EmployeeSnippet showIcon={true} name={`${department.hod.firstName} ${department.hod.lastName}`} phone={department.hod.phone} email={department.hod.email} designation={''} avatar={department.hod.avatar} />
                    </div>
                    :
                    <div className='bg-gray-100 rounded p-[20px] my-[15px]'>
                      <p className='text-xs font-[500]'>No HOD/Supervisor selected for this department yet.</p>
                      <button onClick={()=>{setChoosingHod(true)}} className='mt-[15px] bg-black text-white p-3 rounded-md border border-gray-600 text-xs font-medium'>Select HOD/Supervisor</button>
                    </div>
                  }
                </div>
                <div className='w-full'>
                  <label className='text-xs tracking-[0.2em]'>EMPLOYEES</label>
                  <p className='text-sm mb-5 mt-1'>Employees in this department</p>
                  {employeesState?.loadingEmployees && employeesState.loadingEmployees === true ? 
                      <Preloader preloadingText={'Loading department employees... '} />
                    : 
                    <>
                      {employeesState?.employees?.employees?.length > 0 ? 
                        <>
                          <DataTable                                
                              tableHeaders={tableHeadersFields(cleanupData(employeesState.employees.employees)[0])?.headers} 
                              tableData={cleanupData(employeesState.employees.employees)} 
                              columnWidths={employeeColumnWidths}
                              columnDataStyles={{}}
                              allFields={tableHeadersFields(cleanupData(employeesState.employees.employees)[0]).fields}
                              onSelectItems={()=>{}}
                              tableOptions={employeeTableOptions}
                              // pagination={{
                              //     perPage: 25, 
                              //     currentPage: 1,
                              //     totalItems: 476,
                              // }}
                              changePage={()=>{}}
                              updatePerPage={()=>{}}
                              // expandedIndex={rowOpen || ''}
                              // expansion={<OrderExpansion orders={orders} rowOpen={rowOpen} />}
                          />
                        </>
                        :
                        <EmptyState emptyStateText={`No employees in this department.`} />
                      }
                    </>
                  }
                  </div>
                  
              </div>
            }
            {activeTab === 'leave-policies' && 
            <div className='w-full bg-white p-[30px]'>
              <div className='mr-auto w-1/2'>
                <div className=''>
                  <label className='text-xs tracking-[0.2em]'>LEAVE POLICIES</label>
                  <p className='mt-1 text-sm'>Expand this section to see and edit leave policies for this department</p>

                </div>
                <div className='w-full my-5'>
                  
                  <div className=''>
                    <LeavePolicyManagement departmentId={departmentId} currentPolicies={departmentLeavePolicies?.policies || null} />
                  </div>
                </div>
              </div>
            </div>

            }
          </div>
            :
            <EmptyState emptyStateText={`Department not found`} />
        }
      </CompanyPageLayout>

      {department&& <ModalLayout
          isOpen={editingDepartment} 
          closeModal={()=>{setEditingDepartment(false)}} 
          actionFunction={()=>{}} 
          actionFunctionLabel='Edit department' 
          dialogTitle={`Edit Department - ${department.name}`}
          maxWidthClass='max-w-xl'
      >
          <EditDepartment department={department} />
      </ModalLayout>}

      <ModalLayout
          isOpen={choosingHod} 
          closeModal={()=>{setChoosingHod(false)}} 
          actionFunction={()=>{}} 
          actionFunctionLabel='Select Department HOD' 
          dialogTitle={`Select HOD/Supervisor for ${department?.name}`}
          maxWidthClass='max-w-xl'
      >
          {/* <EditDepartment department={department} /> */}
          <DepartmentHodSelector department={department} />
      </ModalLayout>
    </UserLayout>
  )
}

export default DepartmentDetails
import React from 'react'
import UserIcon from '../../elements/icons/UserIcon'
import Status from '../../../components/elements/Status'
import EmptyState from '../../elements/icons/EmptyState'
import moment from 'moment'

const EmployeeProfile = ({employeeDetails}) => {

    // "data": {
    //     "_id": "658ec89e055e8d4276f70ada",
    //     "designationHistory": [],
    //     "departmentHistory": [],
    //     "groups": [],
    //     "employeeId": "IO1245",
    //     "email": "immanuel.o.onum@gmail.com",
    //     "firstName": "Immanuel",
    //     "middleName": "O",
    //     "lastName": "Onum",
    //     "designation": {
    //         "_id": "650ed24bda026b3a887161e6",
    //         "active": false,
    //         "deleted": false,
    //         "name": "Senior Manager",
    //         "description": "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    //         "slug": "senior-manager",
    //         "createdBy": "650ea5cb940d6a1963e47def",
    //         "createdAt": "2023-09-23T11:55:55.017Z",
    //         "updatedAt": "2023-09-23T11:55:55.017Z",
    //         "__v": 0
    //     },
    //     "department": {
    //         "_id": "650eb1db83584f21aed0e918",
    //         "active": false,
    //         "deleted": false,
    //         "name": "Information & Communications Technology",
    //         "description": "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    //         "slug": "information-communications-technology",
    //         "createdBy": "650ea5cb940d6a1963e47def",
    //         "createdAt": "2023-09-23T09:37:31.237Z",
    //         "updatedAt": "2023-09-23T09:37:31.237Z",
    //         "__v": 0
    //     },
    //     "phone": "08062465529",
    //     "gender": "Male",
    //     "startDate": "2024-01-02T00:00:00.000Z",
    //     "invitation": "658ec89a055e8d4276f70ad7",
    //     "documents": [
    //         {
    //             "_id": "658ecc86c11ee84e3ca6fc39",
    //             "documentSlug": "resume",
    //             "url": "https://res.cloudinary.com/dsdjt8qsv/image/upload/v1703857285/verovian/rkwpjxkyw9mlre2ysxqb.png"
    //         }
    //     ],
    //     "education": [
    //         {
    //             "_id": "658ecc86c11ee84e3ca6fc3a",
    //             "type": "Bachelors Degree",
    //             "institution": "Test Institution",
    //             "startDate": "2018-10-29T00:00:00.000Z",
    //             "endDate": "2023-11-29T00:00:00.000Z"
    //         }
    //     ],
    //     "createdAt": "2023-12-29T13:24:46.050Z",
    //     "updatedAt": "2024-09-15T11:42:59.973Z",
    //     "__v": 0,
    //     "userProfile": "658ecc85c11ee84e3ca6fc37",
    //     "fullName": "Immanuel O Onum"
    // }

  return (
    <div className='w-full'>
        <div className='w-full flex items-start gap-x-[30px]'>
          <div className="w-[300px]">
            <div className='w-full h-[250px] bg-gray-100 flex items-center justify-center rounded-[8px]'>
                {(!employeeDetails.avatar || employeeDetails.avatar === '') && <UserIcon className={`w-8 h-8 text-gray-300`} />}
            </div>
            <div className="mt-3 w-max">
              <Status status={employeeDetails.userProfile ? 'profiled' : 'not-profiled'} />
            </div>
          </div>
          <div className="w-full">
            <p className="text-sm mb-5">EMPLOYEE ID: <span className="font-[500]">{employeeDetails.employeeId}</span></p>
            <div className="w-full grid grid-cols-2 gap-[20px]">
              <div className="w-full">
                <label className='block text-gray-500 text-xs uppercase tracking-[0.2em] mb-2'>first name</label>
                <p className="text-sm">{employeeDetails.firstName}</p>
              </div>
              <div className="w-full">
                <label className='block text-gray-500 text-xs uppercase tracking-[0.2em] mb-2'>middle name</label>
                <p className="text-sm">{employeeDetails.middleName}</p>
              </div>
              <div className="w-full">
                <label className='block text-gray-500 text-xs uppercase tracking-[0.2em] mb-2'>last name</label>
                <p className="text-sm">{employeeDetails.lastName}</p>
              </div>
              <div className="w-full">
                <label className='block text-gray-500 text-xs uppercase tracking-[0.2em] mb-2'>gender</label>
                <p className="text-sm">{employeeDetails.gender}</p>
              </div>
              <div className="w-full">
                <label className='block text-gray-500 text-xs uppercase tracking-[0.2em] mb-2'>email</label>
                <p className="text-sm">{employeeDetails.email}</p>
              </div>
              <div className="w-full">
                <label className='block text-gray-500 text-xs uppercase tracking-[0.2em] mb-2'>phone number</label>
                <p className="text-sm">{employeeDetails.phone}</p>
              </div>
            </div>

            <div className='w-full mt-6'>
              <label className='block text-gray-500 text-xs uppercase tracking-[0.2em] mb-2'>Address</label>
              <p className='text-sm'>123, verovian avenue, off some other street</p>
              <p className='text-sm'>Zone 2, Wuse - Abuja</p>
            </div>

            <div className='w-full mt-6'>
              <label className='block text-gray-500 text-xs uppercase tracking-[0.2em] mb-2'>education</label>
              {employeeDetails?.education?.length > 0 ? <div className='grid grid-cols-2'>
                {employeeDetails.education.map((education, educationIndex)=>(
                  <div key={educationIndex} className="w-full bg-white">
                    <p className="text-sm font-[500]">{education.institution}</p>
                    <p className="text-xs font-[400] mt-1">{education.type}</p>
                    <p className='text-xs text-gray-500 mt-1'>{moment(education.startDate).format('MMM YYYY')} - {moment(education.endDate).format('MMM YYYY')}</p>
                  </div>
                ))}
              </div> :
                <EmptyState emptyStateText={`No education data provided for this employee yet`} />
              }

            </div>
          </div>
        </div>
    </div>
  )
}

export default EmployeeProfile
import React, { useEffect, useState } from 'react'
import UserLayout from '../../../../components/layout/UserLayout'
import CompanyPageLayout from '../../../../components/layout/CompanyPageLayout'
import PlusIcon from '../../../../components/elements/icons/PlusIcon'
import { useDispatch, useSelector } from 'react-redux'
import { userDetails } from '../../../../utils'
import { clearDeletedOnboardingPackage, deleteOnboardingPackage, fetchOnboardingPackages } from '../../../../store/actions/onboardingPackagesActions'
import Preloader from '../../../../components/elements/Preloader'
import Pagination from '../../../../components/elements/Pagination'
import EmptyState from '../../../../components/elements/icons/EmptyState'
import { Link } from 'react-router-dom'
import OnboardingPackageCard from '../../../../components/partials/onboarding-packages/OnboardingPackageCard'
import { SET_SUCCESS_MESSAGE } from '../../../../store/types'

const OnboardingPackages = () => {
    const dispatch = useDispatch()
    const onboardingPackagesSelector = useSelector(state => state.onboardingPackages)
    const accountPermissions = userDetails().accountPermissions
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(25);
    useEffect(() => {
        dispatch(fetchOnboardingPackages({page, perPage}))

        if(onboardingPackagesSelector.deletedOnboardingPackage !== null){
            dispatch(fetchOnboardingPackages({page, perPage}))
            dispatch({
                type: SET_SUCCESS_MESSAGE,
                payload: {
                    successMessage: `Package deleted successfully!`
                }
            })
            dispatch(clearDeletedOnboardingPackage())
        }

        return () => {
            
        };
    }, [dispatch, onboardingPackagesSelector.deletedOnboardingPackage, page, perPage]);

    const deletePackage = (id) => {
        if(window.confirm('Are you sure you want to delete this onboarding package?')){
            dispatch(deleteOnboardingPackage(id))
        }
    }

    return (
        <UserLayout pageTitle={`Company`}>
            <CompanyPageLayout sectionTitle={`Onboarding Packages`}>
                <div className=''>
                    <div className='w-full lg:flex flex-row gap-x-8'>
                        <div className='w-full  mx-auto pb-6 px-0'>
                            <div className='flex justify-between items-start mt-4 mb-4'>
                                <div className='w-1/2'>
                                    <h3 className='font-medium text-lg text-gray-400'>Onboarding packages</h3>
                                    <p className='text-sm mt-[10px]'>
                                        Onboarding packages are designed to help new employees get started. They are a set of timed actionable items that must be performed by each new employee.
                                    </p>
                                </div>
                                {(accountPermissions.includes('*') || accountPermissions.includes('onboarding-packages.*') || accountPermissions.includes('onboarding-packages.create'))  && <Link to={`new-onboarding-package`} className='flex gap-x-2 items-center rounded bg-verovian-purple px-3 py-3 text-verovian-light-purple text-sm transition duration-200 hover:bg-gray-600'>
                                    <PlusIcon className={`h-5 w-5`} />
                                    Create a onboarding package
                                </Link>}
                            </div>
                        </div>
                    </div>

                    {onboardingPackagesSelector.loadingOnboardingPackages ? 
                        <Preloader preloadingText={`Loading onboarding packages`} /> 
                    :   
                        <>
                            {onboardingPackagesSelector.onboardingPackages?.packages?.length > 0 ?
                                <>                                        
                                <div className='w-full grid grid-cols-2 2xl:grid-cols-3 gap-[20px]'>
                                    {onboardingPackagesSelector?.onboardingPackages?.packages?.map((onboardingPackage, packageIndex)=>(
                                        <div key={packageIndex} className="bg-white my-[20px]">
                                            <OnboardingPackageCard onboardingPackage={onboardingPackage} doDelete={()=>{deletePackage(onboardingPackage._id)}} />
                                        </div>
                                    ))}
                                </div>

                                <Pagination
                                    pagination={{
                                    currentPage: page,
                                    perPage: perPage,
                                    totalItems: onboardingPackagesSelector?.onboardingPackages?.total
                                    }}
                                    changePage={(value)=>{setPage(value)}}
                                    updatePerPage={(value)=>{setPerPage(value)}}
                                />
                            </>
                            :
                            <EmptyState emptyStateText={`No packages created yet. You can create a new onboarding package by clicking on "Create new onboarding package" above`} />
                            }
                        </>
                    }
                </div>
            </CompanyPageLayout>
        </UserLayout>
    )
}

export default OnboardingPackages
import React from 'react'
import OrderStatus from '../../elements/OrderStatus'
import ArrowIcon from '../../elements/icons/ArrowIcon'
import UserIcon from '../../elements/icons/UserIcon'
import { formatDate } from '../../../utils'

const TaskCard = ({task, openTask}) => {
  return (
    <div className='w-[360px] p-[20px] bg-white relative border border-verovian-light-purple rounded-[8px] shadow-lg'>
        <div className='flex flex-row-reverse items-start justify-between'>
          {/* <div className='flex flex-row-reverse gap-x-[5px]'>
            <span className={`${task.priority === 'urgent' && 'bg-red-400 text-white text-opacity-90'} ${task.priority === 'high' && 'bg-red-400 bg-opacity-10 text-red-500'} ${task.priority === 'medium' && 'bg-gray-400 bg-opacity-10 text-gray-500'} ${task.priority === 'low' && 'bg-blue-400 bg-opacity-10 text-blue-500'} inline-block  uppercase text-[10px] py-1 px-2 font-medium`}>{task.priority} priority</span>
            <OrderStatus status={task.status} />
          </div> */}

          {/* {task.dueDate && <p className='text-xs font-400'>Due on: {formatDate(new Date(task.dueDate))}</p>} */}
          
        </div>

        <p className='text-sm font-550 mt-[15px] text-black font-[500]'>{task.title}</p>
        <p className='text-xs mt-[10px] font400 truncate'>{task.description}</p>
        <div className='flex items-center justify-between mt-[20px]'>
            <div className='flex items-center gap-x-2'>
              {/* <div className='w-[40px] h-[40px] rounded-full border border-gray-400 bg-gray-100 flex items-center justify-center'>
                <UserIcon className={`w-5 h-5 rounded-sm text-gray-400 `} />
              </div> */}
              <div>
                  <p className='text-gray-600 text-xs'>0 Assignees</p>
                  <p className='text-gray-6400 text-xs mt-1 font-thin'>0 Employees currently performing this task</p>
              </div>
            </div>
        </div>
        <button onClick={()=>{openTask()}} className='mt-[20px] text-xs text-gray-600 flex items-center gap-x-2 hover:text-verovian-purple duration-200 transition'>View Task <ArrowIcon className={`w-4 h-4 -rotate-90`}/></button> 
    </div>
  )
}

export default TaskCard